import React from "react";
import { VMSimages } from "assets";

const ManageYourImpact = () => {
  return (
    <div className="px-2 md:px-5 xl:px-9 py-14 max-w-[100%] grid grid-cols-1 gap-3 lg:grid-cols-2 bg-white mb-2">
      <div className="flex flex-col xxs:-mt-10 md:mt-0 justify-between items-center">
        <img
          src={VMSimages.manage_your_impact}
          className="object-cover lg:max-h-[450px] md:max-h-[400px] lg:max-w-full md:max-w-full w-full"
          alt="usa_hands"
        />
      </div>
      <div className="flex flex-col items-center xxs:items-start self-center lg:items-end">
        <h1
          className="font-['Spectral_Bold'] max-w-[650px] text-right xxs:text-left lg:text-right 
          text-[24px] lg:text-[35px] xl:text-[40px] text-[#004B9D] font-bold leading-[40px] lg:leading-[65px] xl:leading-[65px]"
        >
          Manage Your Impact
        </h1>

        <p
          className="mb-12 mt-5 font-['Lato'] text-[16px] lg:text-[18px] xl:text-[18px] text-[#666666] leading-[30px] lg:leading-[35px]
          text-right xxs:text-left lg:text-right max-w-[1000px]"
        >
          Our volunteer management system allows you to track the impact of your volunteer work. You
          can view your contributions and hours worked, as well as see the outcomes of the projects
          you have contributed to. You can also receive recognition for your efforts and share your
          accomplishments with others. With our system, you can manage just how well your impact is
          making a difference
        </p>
      </div>
    </div>
  );
};

export default ManageYourImpact;
