import React from "react";
import { Link } from "react-router-dom";
import { VMSimages } from "assets";

function Hero() {
  return (
    <div className="max-w-[100%] flex bg-white mb-[5rem]">
      <div className="flex items-center h-fit">
        <img
          src={VMSimages.home_hands}
          className="object-cover w-full min-h-fit md:max-w-[100%] lg:max-w-[100%]"
          alt="home_hands"
        />
      </div>
    </div>
  );
}

export default Hero;
