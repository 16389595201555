const VMSPause = ({ size, color, className, ...props }) => {
  return (
    <div>
      <svg
        fill="none"
        width={size}
        height={size}
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        id="memory-pause"
        className="font-bold md:block hidden "
        {...props}
      >
        <path d="M6 4H9V18H6V4M13 18V4H16V18H13Z" fill="#004B9D" />
      </svg>
    </div>
  );
};
VMSPause.defaultProps = {
  size: 17,
  color: "currentColor"
};

export default VMSPause;
