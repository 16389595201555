import React, { useState, useEffect } from "react";
import { BreadCrumbs, Button, FormInput, Loader, VMSDelete } from "../../../../../components";
import { VMSAdd, VMSOrganise } from "../../../../../components/icons";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Interests",
    direction: false
  }
];

const breadCrumbsLinks2 = [
  {
    id: 1,
    link: "Interests",
    direction: false
  },
  {
    id: 2,
    link: "Add Interests",
    direction: true
  }
];

const AddInterest = ({ title, loading = false }) => {
  const [AddInterest, setAddInterest] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const storedTasks = localStorage.getItem("tasks");
    if (storedTasks) {
      setTasks(JSON.parse(storedTasks));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("tasks", JSON.stringify(tasks));
  }, [tasks]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const deleteTask = (taskId) => {
    const updatedTasks = tasks.filter((task) => task.id !== taskId);
    setTasks(updatedTasks);
  };

  const addTask = (event) => {
    event.preventDefault();
    if (inputValue.trim() !== "") {
      const newTask = {
        id: new Date().getTime(),
        text: inputValue,
        completed: false
      };
      setTasks([...tasks, newTask]);
      setInputValue("");
    }
  };

  const completeTask = (taskId) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
  };

  return (
    <div>
      {AddInterest ? (
        <div>
          <BreadCrumbs
            breadCrumbsLinks={breadCrumbsLinks2}
            customStyles="px-[23px] min-h-[23px] mb-6"
          />
          <div className="flex flex-col space-y-10 mb-[100px] px-[23px]">
            <h4 className="font-['Spectral'] text-[#000] text-[20px]">Add Interests</h4>
            <div className="w-full h-[18.5rem] overflow-auto">
              <form className="max-w-[40rem] m-auto space-y-6" onSubmit={addTask}>
                <div className=" items-center">
                  <FormInput
                    size="md"
                    name="interests"
                    id="interests"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="w-full"
                    label="Interests"
                    placeholder="Reading, Working, Making Impacts"
                  />
                  <div>
                    <ul>
                      {tasks.map((task) => (
                        <li
                          key={task.id}
                          className="my-4 flex justify-between items-center rounded-[4px] p-2 border-2 border-grayTwo"
                        >
                          <div className="">{task.text}</div>

                          <VMSDelete
                            onClick={() => deleteTask(task.id)}
                            className="h-5 w-5 font-extrabold text-usafRed cursor-pointer"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <Button type="submit" variant="full" size="sm" className="text-[12px]">
                    {loading ? <Loader /> : "Save Details"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} customStyles="px-[23px] min-h-[23px]" />
          <div className="flex flex-col space-y-10 mb-[100px]">
            <div className="flex space-x-5 my-6 px-4 items-center justify-end">
              <VMSOrganise
                size={20}
                onClick={() => {
                  // setOpenModal(null);
                }}
                className="cursor-pointer"
              />
              <VMSAdd
                size={20}
                onClick={() => {
                  setAddInterest(true);
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-center justify-center w-full bg-[#99B7D8] m-auto max-w-[36rem] py-14">
              <h6 className=" font-LatoRegular text-[16px] text-[#001E3F]">
                You have not added any {title}
              </h6>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddInterest;
