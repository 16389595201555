import React from "react";
import AccordionDropdown from "./AccordionDropdown";
import items from "utils/accordionDropdownItems.json";

export const AccordionDp = () => {
  return (
    <div className="sidebar grid-row relative xxs:block md:hidden ">
      {items.map((item, index) => (
        <AccordionDropdown key={index} item={item} />
      ))}
    </div>
  );
};

export default AccordionDp;
