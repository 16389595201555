const SvgVMSHeadset = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 44 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M22.0001 0C10.1792 0 0.507568 9.67164 0.507568 21.4925V26.8657C0.507568 31.8806 4.44787 35.8209 9.46279 35.8209C10.5374 35.8209 11.2538 35.1045 11.2538 34.0299V19.7015C11.2538 18.6269 10.5374 17.9104 9.46279 17.9104C7.49264 17.9104 5.7016 18.6269 4.26876 19.7015C5.16428 10.7463 12.8658 3.58209 22.0001 3.58209C31.1344 3.58209 38.8359 10.5672 39.7314 19.7015C38.2986 18.6269 36.5076 17.9104 34.5374 17.9104C33.4628 17.9104 32.7464 18.6269 32.7464 19.7015V34.0299C32.7464 35.1045 33.4628 35.8209 34.5374 35.8209C35.612 35.8209 36.8658 35.6418 37.7613 35.1045C35.7911 38.6866 32.7464 41.5522 28.9852 43.1642C28.627 41.0149 26.8359 39.2239 24.5076 39.2239C22.1792 39.2239 20.2091 41.194 20.2091 43.5224C20.2091 44.7761 20.7464 46.0299 21.821 46.9254C22.7165 47.6418 23.612 48 24.6867 48C24.8658 48 25.224 48 25.4031 48C35.612 46.5672 43.4926 37.4328 43.4926 26.8657V21.4925C43.4926 9.67164 33.821 0 22.0001 0Z"
        fill="#004B9D"
      />
    </svg>
  );
};
SvgVMSHeadset.defaultProps = {
  size: 48,
  color: "currentColor"
};
export default SvgVMSHeadset;
