import React from "react";
import { Hero, Heading, Content } from "./JobRolesCompScreen";

const JobRolesView = () => {
  return (
    <div>
      <Hero />
      <Heading />
      <Content />
    </div>
  );
};

export default JobRolesView;
