import { Routes, Route } from "react-router-dom";

import {
  ErrorPage,
  Home,
  PerfectVolunteer,
  SaiKaiTasks,
  ConnectWithSaiKai,
  JobRoles,
  JobDescription,
  AboutYourSelf,
  WorkExperience,
  Qualification,
  Login,
  Signup,
  AccoutSuccessfullyCreated,
  ForgotPassword,
  AboutMe,
  Application,
  Settings,
  LogOut,
  ResetPassword,
  HelpCenter,
  CustomerSupport,
  ReviewApplication,
  ApplicationSuccessful
} from "pages";
import {
  FORGOT_PASSWORD,
  HOME,
  PERFECT_VOLUNTEER,
  SAI_KAI_TASKS,
  CONNECT_WITH_SAI_KAI_,
  JOB_ROLES,
  JOB_DESCRIPTION,
  ABOUT_YOURSELF,
  WORK_EXPERIENCE,
  QUALIFICATION,
  LOGIN,
  SETTINGS,
  SIGNUP,
  LOGOUT,
  SIGNUP_SUCCESS,
  ABOUT_ME,
  APPLICATION,
  RESET_PASSWORD,
  HELP_CENTER,
  CUSTOMER_SUPPORT,
  REVIEW_APPLICATION,
  APPLICATION_SUCCESSFUL,
  CONFIRM_ACCOUNT
} from "./CONSTANTS";

import { ProtectedRoute, PublicRoute } from "../components";
import { AccountSuccessfullyConfirmed } from "pages/Authentication/AccountSuccessfullyConfirmed/AccountSuccessfullyConfirmed";

const RouterConfig = () => {
  return (
    <div>
      <Routes>
        {/* Public routes should be placed in here */}
        <Route path={HOME} element={<Home />} />
        <Route path={PERFECT_VOLUNTEER} element={<PerfectVolunteer />} />
        <Route path={SAI_KAI_TASKS} element={<SaiKaiTasks />} />
        <Route path={CONNECT_WITH_SAI_KAI_} element={<ConnectWithSaiKai />} />
        <Route path={JOB_ROLES} element={<JobRoles />} />
        <Route path={JOB_DESCRIPTION} element={<JobDescription />} />

        <Route path={ABOUT_YOURSELF} element={<AboutYourSelf />} />
        <Route path={WORK_EXPERIENCE} element={<WorkExperience />} />
        <Route path={QUALIFICATION} element={<Qualification />} />
        <Route path={REVIEW_APPLICATION} element={<ReviewApplication />} />
        <Route path={APPLICATION_SUCCESSFUL} element={<ApplicationSuccessful />} />

        <Route path={HELP_CENTER} element={<HelpCenter />} />
        <Route path={CUSTOMER_SUPPORT} element={<CustomerSupport />} />
        <Route path="/" element={<PublicRoute />}>
          {/* Auth pages */}
          <Route path={LOGIN} element={<Login />} />
          <Route path={SIGNUP} element={<Signup />} />
          <Route path={SIGNUP_SUCCESS} element={<AccoutSuccessfullyCreated />} />
          <Route path={CONFIRM_ACCOUNT} element={<AccountSuccessfullyConfirmed />} />
          <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={RESET_PASSWORD} element={<ResetPassword />} />
        </Route>
        {/* dashboard routes should be placed in here */}
        <Route>
          <Route path={APPLICATION} element={<Application />} />
          <Route path={SETTINGS} element={<Settings />} />
          <Route path={LOGOUT} element={<LogOut />} />
        </Route>

        <Route path="/" element={<ProtectedRoute navigate={SIGNUP} />}>
          {/* Protected routes should be placed in here */}
          <Route path={ABOUT_ME} element={<AboutMe />} />
        </Route>

        {/* 404 page */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default RouterConfig;
