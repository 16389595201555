import JobRolesView from "./JobRolesView";
import { Landing } from "components";

export const JobRolesContainer = () => {
  return (
    <Landing>
      <JobRolesView />
    </Landing>
  );
};
