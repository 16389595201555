import React, { useState } from "react";

const MobileReview = () => {
  const [switchFeature, setSwitchFeature] = useState(false);
  const [switchFeature1, setSwitchFeature1] = useState(false);
  const [switchFeature2, setSwitchFeature2] = useState(false);
  const [switchFeature3, setSwitchFeature3] = useState(false);

  const review1 = [
    {
      id: "1",
      title: "Automated Review",
      description:
        "Your application has been received by our automated system and will decide if you fit the role you applied for",
      time: "3 day(s)"
    }
  ];
  const review2 = [
    {
      id: "2",
      title: "First Phase HR Review",
      description: "HR has received application and has begun first phase of application review.",

      time: "3 day(s)"
    }
  ];
  const review3 = [
    {
      id: "3",
      title: "Virtual Interview Review",
      description:
        "HR has completed first phase of review and has begun reviewing your Virtual Interview.",
      time: "3 day(s)"
    }
  ];
  const review4 = [
    {
      id: "4",
      title: "Virtual Interview Review",
      description:
        "HR has completed the review of your virtual interview and  has begun the last phase of application review",
      time: "3 day(s)"
    }
  ];
  return (
    <div className="grid grid-cols-1  my-10">
      <div className="relative ">
        <div
          className={` ${
            switchFeature
              ? "border-2 border-usafPrimary rounded-lg"
              : "border-2 border-grayTwo  rounded-lg"
          }`}
          onClick={() => setSwitchFeature(true)}
        >
          {review1.map((item, index) => {
            return (
              <div key={index} className="relative z-100 cursor-pointer">
                <p
                  className={` ${
                    switchFeature
                      ? "text-center border-2 text-usafPrimary border-usafPrimary absolute rounded-full justify-center text-[14px] w-8 h-8 flex mx-auto top-[-17px] bg-usafWhite right-0 items-center"
                      : "text-center border-2 text-grayTwo border-grayTwo absolute rounded-full justify-center text-[14px] w-8 h-8 flex mx-auto top-[-17px] bg-usafWhite right-0 items-center"
                  }`}
                >
                  {item.id}
                </p>

                <p
                  className={`${
                    switchFeature
                      ? "text-center font-SpectralRegular text-usafWhite text-[18px] py-6 bg-usafPrimary"
                      : "text-center font-SpectralRegular text-usafWhite text-[18px] py-6 bg-grayTwo"
                  }`}
                >
                  {item.title}
                </p>
                <div className="space-y-[50px]">
                  <p
                    className={`${
                      switchFeature
                        ? "text-usafBlack font-LatoRegular text-[15px]"
                        : "text-usafBlack2 font-LatoRegular text-[15px]"
                    }`}
                  >
                    {item.description}
                  </p>

                  <div className="mt-2"></div>
                  <p
                    className={`${
                      switchFeature
                        ? "text-[15px] font-LatoBold text-usafBlack font-bold"
                        : "text-[15px] font-LatoBold text-usafBlack font-bold"
                    }`}
                  >
                    Expected time: {item.time}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={` place-items-center flex ${
            switchFeature
              ? "border   border-r-2 w-2 h-6  border-usafPrimary bg-usafPrimary flex justify-center mx-auto  "
              : "border w-2 h-6  bg-grayTwo border-grayTwo   border-r-2  flex justify-center mx-auto "
          }`}
        ></div>
      </div>

      <div className="relative">
        <div
          className={` ${
            switchFeature1
              ? "border-2 border-usafPrimary rounded-lg"
              : "border-2 border-grayTwo  rounded-lg"
          }`}
          onClick={() => setSwitchFeature1(true)}
        >
          <div>
            {review2.map((item, index) => {
              return (
                <div key={index} className="relative z-100 cursor-pointer">
                  <p
                    className={` ${
                      switchFeature1
                        ? "text-center border-2 text-usafPrimary border-usafPrimary absolute rounded-full justify-center text-[14px] w-8 h-8 flex mx-auto top-[-17px] bg-usafWhite right-0 items-center"
                        : "text-center border-2 text-grayTwo border-grayTwo absolute rounded-full justify-center text-[14px] w-8 h-8 flex mx-auto top-[-17px] bg-usafWhite right-0 items-center"
                    }`}
                  >
                    {item.id}
                  </p>

                  <p
                    className={`${
                      switchFeature1
                        ? "text-center font-SpectralRegular text-usafWhite text-[18px] py-6 bg-usafPrimary"
                        : "text-center font-SpectralRegular text-usafWhite text-[18px] py-6 bg-grayTwo"
                    }`}
                  >
                    First Phase HR Review
                  </p>
                  <div className="space-y-16">
                    <p
                      className={`${
                        switchFeature1
                          ? "text-usafBlack font-LatoRegular text-[15px]"
                          : "text-usafBlack2 font-LatoRegular text-[15px]"
                      }`}
                    >
                      {item.description}
                    </p>

                    <div className=""></div>
                    <p
                      className={`${
                        switchFeature1
                          ? "text-[15px] font-LatoBold text-usafBlack font-bold"
                          : "text-[15px] font-LatoBold text-usafBlack font-bold"
                      }`}
                    >
                      Expected time: {item.time}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={` place-items-center flex ${
            switchFeature1
              ? "border   border-r-2 w-2 h-6  border-usafPrimary bg-usafPrimary flex justify-center mx-auto  "
              : "border w-2 h-6  bg-grayTwo border-grayTwo   border-r-2  flex justify-center mx-auto "
          }`}
        ></div>
      </div>
      <div className="relative">
        <div
          className={` ${
            switchFeature2
              ? "border-2 border-usafPrimary rounded-lg"
              : "border-2 border-grayTwo  rounded-lg"
          }`}
          onClick={() => setSwitchFeature2(true)}
        >
          <div>
            {review3.map((item, index) => {
              return (
                <div key={index} className="relative z-100 cursor-pointer">
                  <p
                    className={` ${
                      switchFeature2
                        ? "text-center border-2 text-usafPrimary border-usafPrimary absolute rounded-full justify-center text-[14px] w-8 h-8 flex mx-auto top-[-17px] bg-usafWhite right-0 items-center"
                        : "text-center border-2 text-grayTwo border-grayTwo absolute rounded-full justify-center text-[14px] w-8 h-8 flex mx-auto top-[-17px] bg-usafWhite right-0 items-center"
                    }`}
                  >
                    {item.id}
                  </p>

                  <p
                    className={`${
                      switchFeature2
                        ? "text-center font-SpectralRegular text-usafWhite text-[18px] py-6 bg-usafPrimary"
                        : "text-center font-SpectralRegular text-usafWhite text-[18px] py-6 bg-grayTwo"
                    }`}
                  >
                    Virtual Interview Review
                  </p>
                  <div className="space-y-16">
                    <p
                      className={`${
                        switchFeature2
                          ? "text-usafBlack font-LatoRegular text-[15px]"
                          : "text-usafBlack2 font-LatoRegular text-[15px]"
                      }`}
                    >
                      {item.description}
                    </p>

                    <p
                      className={`${
                        switchFeature2
                          ? "text-[15px] font-LatoBold text-usafBlack font-bold"
                          : "text-[15px] font-LatoBold text-usafBlack font-bold"
                      }`}
                    >
                      Expected time: {item.time}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={` place-items-center flex ${
            switchFeature2
              ? "border   border-r-2 w-2 h-6  border-usafPrimary bg-usafPrimary flex justify-center mx-auto  "
              : "border w-2 h-6  bg-grayTwo border-grayTwo   border-r-2  flex justify-center mx-auto "
          }`}
        ></div>
      </div>
      <div className="relative">
        <div
          className={` ${
            switchFeature3
              ? "border-2 border-usafPrimary rounded-lg"
              : "border-2 border-grayTwo  rounded-lg"
          }`}
          onClick={() => setSwitchFeature3(true)}
        >
          <div>
            {review4.map((item, index) => {
              return (
                <div key={index} className="relative z-100 cursor-pointer">
                  <p
                    className={` ${
                      switchFeature3
                        ? "text-center border-2 text-usafPrimary border-usafPrimary absolute rounded-full justify-center text-[14px] w-8 h-8 flex mx-auto top-[-17px] bg-usafWhite right-0 items-center"
                        : "text-center border-2 text-grayTwo border-grayTwo absolute rounded-full justify-center text-[14px] w-8 h-8 flex mx-auto top-[-17px] bg-usafWhite right-0 items-center"
                    }`}
                  >
                    {item.id}
                  </p>

                  <p
                    className={`${
                      switchFeature3
                        ? "text-center font-SpectralRegular text-usafWhite text-[18px] py-6 bg-usafPrimary"
                        : "text-center font-SpectralRegular text-usafWhite  text-[18px] py-6 bg-grayTwo"
                    }`}
                  >
                    Final Review
                  </p>
                  <div className=" space-y-9">
                    <p
                      className={`${
                        switchFeature3
                          ? "text-usafBlack font-LatoRegular text-[15px]"
                          : "text-usafBlack2 font-LatoRegular text-[15px]"
                      }`}
                    >
                      {item.description}
                    </p>

                    <div className=""></div>
                    <p
                      className={` ${
                        switchFeature3
                          ? "text-[15px] font-LatoBold text-usafBlack font-bold"
                          : "text-[15px] font-LatoBold text-usafBlack font-bold"
                      }`}
                    >
                      Expected time: {item.time}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div
                        className={`${switchFeature3
                            ? "border-[16px] absolute  border-t-0  border-usafPrimary flex justify-center mx-auto left-[-85px] "
                            : "border-[16px] absolute  border-t-0  flex left-[-85px] justify-center mx-auto "
                            }`}
                    ></div> */}
        </div>
      </div>
    </div>
  );
};

export default MobileReview;
