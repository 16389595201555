const SvgVMSCase = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 10C2.89544 10 2 10.8954 2 12V26C2 27.0086 2.75108 27.8594 3.75194 27.9846L19.7519 29.9846C19.8342 29.9948 19.9171 30 20 30H28C28.083 30 28.1658 29.9948 28.248 29.9846L44.248 27.9846C45.249 27.8594 46 27.0086 46 26V12C46 10.8954 45.1046 10 44 10H4ZM20 21C18.8954 21 18 21.8954 18 23V33C18 34.1046 18.8954 35 20 35H28C29.1046 35 30 34.1046 30 33V23C30 21.8954 29.1046 21 28 21H20Z"
        fill="#004B9D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 9C16 6.23858 18.2386 4 21 4H27C29.7614 4 32 6.23858 32 9V12H28V9C28 8.44772 27.5522 8 27 8H21C20.4478 8 20 8.44772 20 9V12H16V9Z"
        fill="#004B9D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 42V27H8V40H40V27H44V42C44 43.1046 43.1046 44 42 44H6C4.89544 44 4 43.1046 4 42Z"
        fill="#004B9D"
      />
    </svg>
  );
};
SvgVMSCase.defaultProps = {
  size: 48,
  color: "currentColor"
};
export default SvgVMSCase;
