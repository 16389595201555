import * as Yup from "yup";
import { Auth } from "../../../components";
import { useFormik } from "formik";
import SignupView from "./SignupView";
import { SIGNUP_SUCCESS } from "../../../routes/CONSTANTS";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { useState } from "react";
import { clearMessage } from "redux/slices/message";
import { register } from "redux/slices/auth";
import { GOOGLE_END_POINT, LINKED_IN_END_POINT } from "services/constants";

export const SignupContainer = () => {
  const navigate = useNavigate();

  const { isLoggedIn, isLoading } = useAppSelector((state) => state.auth);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { message } = useAppSelector((state) => state.message);
  console.log(message);
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleFormRegister = (formData) => {
    const { firstName, lastName, email, password } = formData;
    dispatch(register({ firstName, email, lastName, password }))
      .unwrap()
      .then(() => {
        setSuccess(true);
        navigate(SIGNUP_SUCCESS);
      })
      .catch(() => {
        setSuccess(false);
      });
  };
  const closeAlert = () => {
    dispatch(clearMessage());
    setSuccess(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Please enter your first name."),
      lastName: Yup.string().required("Please enter your Last name."),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          "Weak Password. Password must have at least: 1 upper case, 1 digit, 1 special character, Minimum eight in length"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please confirm your password")
    }),
    onSubmit: (details) => {
      handleFormRegister(details);
    }
  });

  return (
    <Auth
      message="Join our Volunteer Team and Make a Difference in Your Community"
      google_message="Join With Google"
      linkedIn_message="Join With Linkedin"
    >
      <SignupView formik={formik} loading={isLoading} />
    </Auth>
  );
};
