const SvgVMSQuestionMark = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M44.826 3.2C42.1463 0.520325 38.452 0 33.7171 0H14.2049C9.54797 0 5.82764 0.546341 3.17398 3.2C0.494309 5.87967 0 9.54797 0 14.2309V33.7171C0 38.4781 0.494309 42.1463 3.14797 44.8C5.85366 47.4797 9.54797 48 14.2829 48H33.7171C38.452 48 42.1463 47.4797 44.826 44.8C47.5057 42.1203 48 38.4781 48 33.7171V14.2829C48 9.54797 47.5057 5.85366 44.826 3.2ZM23.4146 29.4244C21.7496 29.4244 20.813 28.6179 20.813 27.0829V26.7967C20.813 24.3252 22.2439 22.9463 24.1431 21.5935C26.4325 20.0065 27.5772 19.174 27.5772 17.4569C27.5772 15.6358 26.1203 14.361 23.935 14.361C22.322 14.361 21.0472 15.1154 20.1106 16.5984L19.7203 17.1707C19.174 17.8732 18.4976 18.2894 17.5089 18.2894C16.3902 18.2894 15.3496 17.4829 15.3496 16.1821C15.3496 15.6618 15.4797 15.2195 15.6358 14.7512C16.4943 12.2276 19.6163 10.1203 24.2732 10.1203C29.0862 10.1203 33.1447 12.6699 33.1447 17.1967C33.1447 20.3447 31.4016 21.9057 28.722 23.6228C26.9789 24.7675 26.0163 25.7041 25.9382 27.1089C25.9382 27.213 25.9382 27.3171 25.9382 27.4211C25.8602 28.5398 24.8976 29.4244 23.4146 29.4244ZM23.3626 37.6976C21.6195 37.6976 20.1626 36.4228 20.1626 34.7317C20.1626 33.0406 21.6195 31.7398 23.3626 31.7398C25.1317 31.7398 26.5366 33.0146 26.5366 34.7317C26.5366 36.4488 25.1057 37.6976 23.3626 37.6976Z"
        fill="#004B9D"
      />
    </svg>
  );
};
SvgVMSQuestionMark.defaultProps = {
  size: 48,
  color: "currentColor"
};
export default SvgVMSQuestionMark;
