import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import AuthService from "services/auth.service";
import { VMS_USER_DATA } from "services/constants";
import formatErrorResponse from "../../utils/formatResponse";
import { toast } from "react-toastify";

const user = JSON.parse(localStorage.getItem(VMS_USER_DATA));

export const register = createAsyncThunk(
  "auth/register",
  async ({ firstName, lastName, email, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(firstName, lastName, email, password);
      thunkAPI.dispatch(setMessage(response.data.MESSAGE));
      return response.data;
    } catch (error) {
      const message = formatErrorResponse(error);
      alert(message);
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk("auth/login", async ({ username, password }, thunkAPI) => {
  try {
    const { DATA } = await AuthService.login(username, password);
    return { userId: DATA.id, email: DATA.email };
  } catch (error) {
    console.log(error);
    const message = formatErrorResponse(error);
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const verify_code = createAsyncThunk("auth/verify_code", async ({ id, code }, thunkAPI) => {
  try {
    const { DATA } = await AuthService.verifyAuthCode(id, code);
    return { user: DATA };
  } catch (error) {
    console.log(error);
    const message = formatErrorResponse(error);
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const loginSuccess = createAsyncThunk("auth/loginSuccess", async (data, thunkAPI) => {
  try {
    const { DATA } = await AuthService.loginSuccess();
    return { user: DATA };
  } catch (error) {
    console.log(error);
    const message = formatErrorResponse(error);
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
  // window.location.reload();
});

const initialState = user
  ? { isLoggedIn: true, user, isLoading: false }
  : { isLoggedIn: false, user: null, isLoading: false };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.isLoading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.isLoading = false;
      });

    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
        state.isLoading = false;
      });

    builder
      .addCase(loginSuccess.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loginSuccess.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload.user;
        state.isLoading = false;
      })
      .addCase(loginSuccess.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
        state.isLoading = false;
      });
  }
});

const { reducer } = authSlice;
export default reducer;
