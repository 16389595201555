import React from "react";
import { VMSimages } from "assets";
import { Button } from "components/widgets";
import { Link } from "react-router-dom";
import { JOB_ROLES } from "routes/CONSTANTS";

const SignUpToday = () => {
  return (
    <div className="px-2 md:px-5 xl:px-9 py-14 grid grid-cols-1 gap-3 lg:grid-cols-2 bg-white mb-2">
      <div className="flex flex-col items-start xxs:items-center sm:items-center md:items-center lg:items-start lg:order-1 xxs:order-2">
        <h1
          className="font-['Spectral_Bold'] max-w-[650px] text-left xxs:text-center md:text-center lg:text-left 
          text-[30px] lg:text-[35px] xl:text-[60px] text-[#004B9D] font-bold leading-[40px] lg:leading-[60px] xl:leading-[100px]"
        >
          Sign up today and start making a difference!
        </h1>

        <p
          className="mb-9 mt-2 text-[16px] lg:text-[18px] xl:text-[18px] text-[#666666] leading-[30px] lg:leading-[35px] 
          max-w-[1000px] text-left xxs:text-center md:text-center lg:text-left"
        >
          Join us today and connect with thousands of volunteers around the world, looking for that
          next big opportunity to bring positive change to the world.
        </p>

        <Link to={JOB_ROLES}>
          <Button variant="full" size="lg" className="text-[14px] font-bold lg:mt-12 md:mt-0">
            Find My Next Job
          </Button>
        </Link>
      </div>
      <div
        className="flex flex-col xxs:-mt-10 md:mt-0 justify-between items-end md:items-center lg:items-end xxs:items-center 
        xxs:justify-center lg:order-2 xxs:order-1"
      >
        <img
          src={VMSimages.sign_up}
          className="object-cover lg:max-h-[450px] md:max-h-[400px] lg:max-w-full md:max-w-full w-full"
          alt="usa_hands"
        />
      </div>
    </div>
  );
};

export default SignUpToday;
