import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { VMSimages } from "../../../assets";
import { VMSCalendar } from "../../icons";
// import CalenderIcon from "../../svgs/CalenderIcon";
// import "./AppDatePicker.scss";

const AppDatePicker = ({
  onChange,
  placeholder,
  value,
  maxDate,
  dateFormat,
  className,
  hasIcon,
  showTimeSelect,
  minDate,
  id,
  size,
  label,
  optional,
  required
}) => {
  const getSize = (size) => {
    switch (size) {
      case "sm":
        return "px-2 h-10";
      case "md":
        return "px-4 h-12";
      case "lg":
        return "px-8 h-16";

      default:
        return "p-1 h-10";
    }
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const CustomInput = ({ value, onClick }) => (
    <div className="relative w-full">
      {/* <img
        className={`${
          size === "sm" && "max-w-[3px] top-[8px] left-[15px]"
        } object-contain absolute top-3 left-6`}
        src={VMSimages.input_bar}
        alt=""
      /> */}
      <input
        type="text"
        className={`${getSize(size)} ${className} ${
          size === "sm" && "pl-[20px]"
        } text-base bg-white placeholder:text-[#8692A6] border-[1.5px] border-[#333333] rounded-[6px] focus:border-[#004B9D] outline-none`}
        value={value}
        onClick={onClick}
        placeholder="Select date"
      />
      <div className="absolute inset-y-0 right-3 pl-2 flex items-center pointer-events-none">
        <VMSCalendar size={14} />
      </div>
    </div>
  );
  return (
    // <DatePicker
    //   onChange={onChange}
    //   placeholderText={placeholder}
    //   selected={value}
    //   dateFormat={dateFormat || "dd/MM/yyyy"}
    //   maxDate={new Date(maxDate)}
    //   minDate={new Date(minDate)}
    //   showYearDropdown
    //   showMonthDropdown
    //   showTimeSelect={showTimeSelect}
    // />
    <div className="space-y-1 w-full">
      <div className="flex items-center justify-between">
        {label !== undefined && (
          <label
            htmlFor={id}
            className={`block ${
              size === "base" ? "text-[12px]" : "text-lg"
            } text-[#000000] font-['Spectral'] mb-[6px]`}
          >
            {label}
          </label>
        )}
        {optional && <small className="text-[#009D49] text-[10px]">Optional</small>}
        {required && <small className="text-red-700 text-[10px]">Required</small>}
      </div>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        dateFormat={dateFormat || "dd/MM/yyyy"}
        maxDate={new Date(maxDate)}
        minDate={new Date(minDate)}
        showYearDropdown
        showMonthDropdown
        showTimeSelect={showTimeSelect}
        customInput={<CustomInput />}
      />
    </div>
  );
};
export default AppDatePicker;
