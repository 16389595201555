const VMSArrowDownIcon = ({ size, color, className, onClick, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16">
      <g
        id="vuesax_linear_arrow-down"
        data-name="vuesax/linear/arrow-down"
        transform="translate(-236 -252)"
      >
        <g id="arrow-down" transform="translate(236 252)">
          <path
            id="Vector"
            d="M10.56,0,6.213,4.347a1.324,1.324,0,0,1-1.867,0L0,0"
            transform="translate(2.72 5.967)"
            fill="none"
            stroke="#004B9D"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H16V16H0Z"
            transform="translate(16 16) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
VMSArrowDownIcon.defaultProps = {
  size: 24,
  color: "currentColor",
  className: "font-bold"
};
export default VMSArrowDownIcon;
