const IMG_PATH = "/img";
const BACKGROUND = `${IMG_PATH}/backgrounds`;
const SVG_PATH = "/svg";

export const usa_hands = `${BACKGROUND}/volunteer_main.png`;
export const usa_hands_2 = `${BACKGROUND}/volunteer_main_2.png`;
export const home_hands = `${BACKGROUND}/home_hands.png`;
export const find_apply = `${BACKGROUND}/find_apply.png`;
export const manage_schedule = `${BACKGROUND}/manage_schedule.png`;
export const connect = `${BACKGROUND}/connect.png`;
export const fall_img = `${BACKGROUND}/fall_img.png`;
export const sign_up = `${BACKGROUND}/hands_signup.png`;
export const card_img_1 = `${BACKGROUND}/card_img_1.png`;
export const card_img_2 = `${BACKGROUND}/card_img_2.png`;
export const card_img_3 = `${BACKGROUND}/card_img_3.png`;
export const profile_image = `${BACKGROUND}/profilePic.png`;
export const saiKaiVms = `${BACKGROUND}/Sa-Kai-VMS.png`;
export const close = `${BACKGROUND}/closeIcon.png`;
export const smallCircle = `${BACKGROUND}/smallCircle.png`;
export const db_personalized = `${BACKGROUND}/db_personalized.png`;
export const track_opportunities = `${BACKGROUND}/track_opportunities.png`;
export const connect_right = `${BACKGROUND}/connect_right.png`;
export const volunteer_schedule = `${BACKGROUND}/volunteer_schedule.png`;
export const volunteer_contributions = `${BACKGROUND}/volunteer_contributions.png`;
export const streamline_vms = `${BACKGROUND}/streamline_vms.png`;
export const connect_with_others = `${BACKGROUND}/connect_with_others.png`;
export const control_work = `${BACKGROUND}/control_work.png`;
export const manage_your_impact = `${BACKGROUND}/manage_your_impact.png`;
export const menuIcon = `${BACKGROUND}/menuIcon.png`;
export const arrow_left = `${BACKGROUND}/left-arrow.png`;
export const arrowLeft = `${BACKGROUND}/arrowLeft.png`;
export const arrowRight = `${BACKGROUND}/arrowRight.png`;

export const vms_logo = `${SVG_PATH}/logo.svg`;
export const usaf_logo = `${SVG_PATH}/usaf_logo.svg`;
export const twitter_icon = `${SVG_PATH}/twitter_icon.svg`;
export const facebook_icon = `${SVG_PATH}/facebook_icon.svg`;
export const instagram_icon = `${SVG_PATH}/instagram_icon.svg`;
export const vectorArrow = `${SVG_PATH}/vectorOrg.svg`;
export const plus = `${SVG_PATH}/plus_vector.svg`;
export const minus = `${SVG_PATH}/icon_minus.png`;
export const ellipse = `${SVG_PATH}/ellipse.png`;
export const card_vector_front = `${SVG_PATH}/card_vector_front.svg`;
export const card_vector_back = `${SVG_PATH}/card_vector_back.svg`;
export const google_logo = `${SVG_PATH}/google-logo.svg`;
export const linkedInLogo = `${SVG_PATH}/linkedIn_logo.svg`;
export const linkedInIcon = `${SVG_PATH}/linkedIn_icon.svg`;
export const input_bar = `${SVG_PATH}/input_bar.svg`;
export const icon_eye_open = `${SVG_PATH}/Icon_eye_open.svg;`;
export const check_mark = `${SVG_PATH}/check_mark.svg`;
export const profileIcon = `${SVG_PATH}/profileIcon.svg`;
export const arrow_right = `${SVG_PATH}/arrowDir.svg`;
export const active_toggler = `${SVG_PATH}/activeToggler.svg`;
export const default_toggler = `${SVG_PATH}/defaultToggler.svg`;
export const edit_pen = `${SVG_PATH}/editPen.svg`;
export const profile_pic = `${SVG_PATH}/profile_pic.svg`;
export const profiles_Pic = `${SVG_PATH}/profiles_Pic.png`;
export const search_icon = `${SVG_PATH}/search_icon.svg`;
export const search_icon_blue = `${SVG_PATH}/searchIconBlue.svg`;
export const select_icon = `${SVG_PATH}/select_icon.svg`;
export const ArrowUpIcon = `${SVG_PATH}/arrowUp.svg`;
export const ArrowDownIcon = `${SVG_PATH}/ArrowDownIcon.svg`;
