import React from "react";
import { ABOUT_ME, HOME } from "routes/CONSTANTS";
import { VMSimages } from "../../../assets";
import { CircularProgressBar, Dashboard } from "../../../components";
import HomeContainer from "pages/Home";
import { useAppSelector } from "hooks";
import { useLocation, useNavigate } from "react-router";
import {
  Basics,
  Education,
  Interests,
  Skills,
  WorkExperience,
  VolunteerExperience
} from "./AboutMeDetailsComp/index";
import { useEffect } from "react";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Home",
    to: HOME,
    direction: false
  },
  {
    id: 2,
    link: "Profile",
    to: ABOUT_ME,
    direction: true
  },
  {
    id: 3,
    link: "About Me",
    to: ABOUT_ME,
    direction: true
  }
];

const aboutMeDetails = [
  {
    id: 1,
    type: "basics"
  },
  {
    id: 2,
    type: "work_experience"
  },
  {
    id: 3,
    type: "volunteer_experience"
  },
  {
    id: 4,
    type: "skills"
  },
  {
    id: 5,
    type: "education"
  },
  {
    id: 6,
    type: "interests"
  }
];

export const AboutMe = () => {
  const { user, isLoggedIn } = useAppSelector((state) => state.auth);

  return (
    <Dashboard breadCrumbsLinks={breadCrumbsLinks}>
      <div className="flex items-center justify-between w-full mb-11">
        <div className="flex items-start space-x-5">
          <img
            src={user.profilePhoto}
            className="object-contain max-w-[65px] md:max-w-[95px]"
            alt="Upload Photo"
          />
          <div className="pt-1">
            <span className="flex items-center space-x-4">
              <h4 className="font-['Spectral'] md:text-[30px] text-[#000000] font-bold">
                {user.user.name.firstName} {user.user.name.lastName}
              </h4>
              <img
                src={VMSimages.edit_pen}
                className="object-contain max-w-[15px] md:max-w-[20px]"
                alt=""
              />
            </span>
            <h6 className="font-['Lato'] text-textxs text-[#666666] pb-1">Product Designer</h6>
            <h6 className="font-['Lato'] text-textxs text-[#666666] pb-1">26</h6>
            <h6 className="font-['Lato'] text-textxs text-[#666666] pb-1">
              Google Certified Product Designer
            </h6>
          </div>
        </div>
        <div>
          <CircularProgressBar value={50} />
        </div>
      </div>
      {aboutMeDetails?.map((data) => {
        return (
          <div key={data.id}>
            {Object.entries(data)?.map(([key, value]) => {
              if (value === "basics") {
                return <Basics key={data.id} />;
              }
              if (value === "work_experience") {
                return <WorkExperience key={data.id} />;
              }
              if (value === "volunteer_experience") {
                return <VolunteerExperience key={data.id} />;
              }
              if (value === "skills") {
                return <Skills key={data.id} />;
              }
              if (value === "education") {
                return <Education key={data.id} />;
              }
              if (value === "interests") {
                return <Interests key={data.id} />;
              }

              return <></>;
            })}
          </div>
        );
      })}
    </Dashboard>
  );
};
