import React from "react";
import { Link } from "react-router-dom";
import { VMSimages } from "assets";
import { NavigationComp } from "components";
import { PERFECT_VOLUNTEER } from "routes/CONSTANTS";

const RightOpportunities = () => {
  return (
    <div className="px-2 md:px-5 xl:px-9 py-14 max-w-[100%] grid grid-cols-1 gap-3 lg:grid-cols-2 bg-white mb-2">
      <div className="flex flex-col xxs:-mt-10 md:mt-0 justify-between items-center">
        <img
          src={VMSimages.find_apply}
          className="object-contain w-[100%] md:max-w-[85%] lg:max-w-[100%]"
          alt="usa_hands"
        />
      </div>
      <div className="flex flex-col items-center xxs:items-start self-center lg:items-end">
        <h1
          className="font-['Spectral_Bold'] max-w-[650px] text-right xxs:text-left lg:text-right 
          text-[24px] lg:text-[35px] xl:text-[40px] text-[#004B9D] font-bold leading-[40px] lg:leading-[65px] xl:leading-[65px]"
        >
          Find and Apply To The Right Opportunities
        </h1>

        <p
          className="mb-12 mt-5 font-['Lato'] text-[16px] lg:text-[18px] xl:text-[18px] text-[#666666] leading-[30px] lg:leading-[35px]
          text-right xxs:text-left lg:text-right max-w-[1000px]"
        >
          Discover new volunteer opportunities that match your interests, skills, location, activity
          type and time commitment. Our personalized search filter experience helps volunteers save
          time and ensures they are matched to a volunteer opportunity that’s most suitable for
          them.
        </p>

        <Link to={PERFECT_VOLUNTEER}>
          <NavigationComp description="Learn more about our application process" />
        </Link>
      </div>
    </div>
  );
};

export default RightOpportunities;
