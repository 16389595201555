import React, { useState } from "react";
import { Dashboard, VMSPause } from "../../../components";
import SecondaryButton from "../../../components/common/Buttons";
import Button from "../../../components/widgets/button/Button";
import VMSDelete from "../../../components/icons/VMSDelete";
import { NavLink } from "react-router-dom";
import { JOB_ROLES, HOME } from "routes/CONSTANTS";
import { HiChevronDown, HiChevronUp, HiChevronRight } from "react-icons/hi";
import { ToggleComp } from "../../../components";
import Toggle from "components/common/Toggle/Toggle";
import { logout } from "redux/slices/auth";
import { useAppDispatch } from "hooks";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Home",
    direction: false
  },
  {
    id: 2,
    link: "Profile",
    direction: true
  },
  {
    id: 3,
    link: "Application",
    direction: true
  }
];

export const LogOut = ({ size }) => {
  const dispatch = useAppDispatch();
  const handleLogout = dispatch(logout());
  const [toggleObj, setToggleObj] = useState({
    pause: false
  });

  const handleToggle = (key) => {
    setToggleObj((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  return (
    <Dashboard breadCrumbsLinks={breadCrumbsLinks}>
      <div className="">
        <div className="md:bg-white  md:p-7 my-4 rounded-md">
          <div>
            <div className="mb-6">
              <p className="md:text-textH5 text-textH6 text-usafBlack font-semibold font-SpectralRegular ">
                Delete Acount
              </p>
              <p className="text-usafBlack2 text-textxs font-LatoRegular">
                Choose how you leave our database
              </p>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between bg-white  md:bg-usafWhiteBg p-2 md:p-4 md:rounded border border-[#CCCCCC] items-center">
                <p className="font-LatoRegular text-[12px] md:text-base text-usafBlack">
                  Delete Account and Data
                </p>
                <SecondaryButton className=" md:border-2 items-center md:px-8 md:py-2 flex md:gap-8 md:border-usafRed md:rounded-[4px] text-usafRed font-MontserratBold text-textsm ">
                  <p className="text-textsm text-usafRed font-MontserratSemiBold">Delete</p>
                  <VMSDelete className="hidden md:block" />
                  <HiChevronRight className="md:hidden  " size={25} />
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
        <div className="md:bg-white md:p-7 rounded-md ">
          <div>
            <div className="mb-6">
              <p className="md:text-textH5 text-textH6 text-usafBlack font-semibold font-SpectralRegular ">
                Pause Account
              </p>
              <p className="text-usafBlack2 text-textxs font-LatoRegular">
                Use option if you would like to take a break
              </p>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between bg-white  md:bg-usafWhiteBg p-2 md:p-4 md:rounded border border-[#CCCCCC] items-center">
                <p className="font-LatoRegular text-[12px] md:text-base text-usafBlack">
                  Pause Account till I return
                </p>
                <SecondaryButton className="md:border-2 items-center md:px-8 md:py-2 flex md:gap-8 md:border-usafPrimary md:rounded-[4px] text-usafPrimary font-MontserratBold text-textsm">
                  <p className="text-textsm hidden md:block text-usafPrimary font-MontserratSemiBold">
                    Pause
                  </p>
                  <VMSPause className="hidden md:block" />
                  {/* <ToggleComp
                    id="toggle-email"
                  
                    isChecked={toggleObj.pause}
                    handleToggle={() => handleToggle("pause")}
                    className="md:hidden"
                  /> */}
                  <Toggle className="md:hidden block" />
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between my-14  ">
          <div></div>
          <NavLink to={HOME} onClick={handleLogout}>
            <Button variant="full" size="lg" className="text-[20px] font-MontserratBold">
              Log Out
            </Button>
          </NavLink>
        </div>
      </div>
    </Dashboard>
  );
};
