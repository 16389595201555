import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { VMSimages } from "../../../assets";
import { Button, Navbar } from "../../../components";
import { HOME } from "../../../routes/CONSTANTS";
import formatErrorResponse from "utils/formatResponse";
import authService from "services/auth.service";

export const AccountSuccessfullyConfirmed = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const handleConfirmAccount = useCallback(() => {
    if (token && !success) {
      setLoading(true);
      authService
        .verifyAccount(token)
        .then((result) => {
          setLoading(false);
          if (result?.DATA) {
            setSuccess(true);
          } else {
            console.log("Account verification Error", result);
            setSuccess(false);
            setLoading(false);
            const message = formatErrorResponse(result) || "Account verification Error";
            navigate(`/auth/login?success=false&error=${encodeURIComponent(message)}`);
          }
        })
        .catch((error) => {
          console.log("Account verification Error", error);
          setLoading(false);
          setSuccess(false);
          const message = formatErrorResponse(error) || "Account verification Error";
          navigate(`/auth/login?redirect=false&error=${encodeURIComponent(message)}`);
        });
    }
  }, [token, success]);

  useEffect(() => {
    handleConfirmAccount();
  }, [handleConfirmAccount]);

  if (loading && !success) return null;
  return (
    <div>
      <Navbar />
      <div className="min-h-[85vh] flex flex-col items-center justify-center">
        <img className="object-contain max-w-[120px] pb-[70px]" src={VMSimages.check_mark} alt="" />
        <h4 className="text-center font-['Spectral'] text-[#004B9D] text-[30px] font-semibold">
          Your Account Successfully Confirmed
        </h4>
        <p className="text-center font-['Lato'] text-[#666666] text-[14px]">
          Kindly Login to continue.
        </p>
        <div className="flex items-center justify-center pt-[74px]">
          <Link to={HOME}>
            <Button variant="full" size="lg" className="text-[12px] h-[52px]">
              Go Home
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
