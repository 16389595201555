import React, { useState } from "react";
import { VMSimages } from "../../../assets";
import { GOOGLE_END_POINT, LINKED_IN_END_POINT } from "services/constants";

const AuthComp = ({ message, google_message, linkedIn_message }) => {
  const [loading, setLoading] = useState(false);

  const onGoogleLogin = () => {
    window.open(GOOGLE_END_POINT, "_self");
    setLoading(true);
  };

  const onLinkedInLogin = () => {
    window.open(LINKED_IN_END_POINT, "_self");
    setLoading(true);
  };
  return (
    <div>
      <h4 className="text-center font-['Spectral'] text-[28px] lg:text-[56px] text-[#004B9D] font-[600] max-w-[1300px] m-auto mb-16">
        {message}
      </h4>
      <div className="flex items-center bg-white py-10 rounded-[14px] shadow-md">
        <div className="w-full flex items-center justify-center flex-wrap gap-9">
          <div
            className="flex text-white gap-6 items-center justify-center bg-[#007AFF] py-5 px-3 md:px-20 text-[13px] rounded-[4px] cursor-pointer"
            onClick={onGoogleLogin}
          >
            <img src={VMSimages.google_logo} alt="google_logo" width={20} />
            <span className="font-['Montserrat'] font-semibold">{google_message}</span>
          </div>
          <div
            className="flex text-white gap-6 items-center justify-center bg-[#017BBE] py-5 px-3 md:px-20 text-[13px] rounded-[4px] cursor-pointer"
            onClick={onLinkedInLogin}
          >
            <img src={VMSimages.linkedInLogo} alt="linkedIn_icon" width={20} />
            <span className="font-['Montserrat'] font-semibold">{linkedIn_message}</span>
          </div>
        </div>
      </div>
      <p className="font-['Spectral'] text-center m-auto py-5">Or</p>
    </div>
  );
};

export default AuthComp;
