const SvgVMSOrganise = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M16.9209 32.2891C25.6721 32.2891 32.9209 25.0558 32.9209 16.2891C32.9209 7.53785 25.6721 0.289062 16.9054 0.289062C8.1542 0.289062 0.920898 7.53785 0.920898 16.2891C0.920898 25.0558 8.16969 32.2891 16.9209 32.2891ZM9.31586 12.231C8.49495 12.231 7.93736 11.7198 7.93736 10.9609C7.93736 10.2019 8.49495 9.69081 9.31586 9.69081H24.5879C25.3933 9.69081 25.9509 10.2019 25.9509 10.9609C25.9509 11.7198 25.3933 12.231 24.5879 12.231H9.31586ZM9.31586 17.5592C8.49495 17.5592 7.93736 17.048 7.93736 16.2736C7.93736 15.5146 8.49495 15.0035 9.31586 15.0035H24.5879C25.3933 15.0035 25.9509 15.5146 25.9509 16.2736C25.9509 17.048 25.3933 17.5592 24.5879 17.5592H9.31586ZM9.31586 22.8718C8.49495 22.8718 7.93736 22.3762 7.93736 21.6017C7.93736 20.8428 8.49495 20.3317 9.31586 20.3317H24.5879C25.3933 20.3317 25.9509 20.8428 25.9509 21.6017C25.9509 22.3762 25.3933 22.8718 24.5879 22.8718H9.31586Z"
        fill="#EB6223"
      />
    </svg>
  );
};
SvgVMSOrganise.defaultProps = {
  size: 40,
  color: "currentColor"
};
export default SvgVMSOrganise;
