import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ABOUT_ME, APPLICATION, LOGOUT } from "../../../routes/CONSTANTS";
import { AiFillProfile } from "react-icons/ai";
import { RiAppsFill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { CiSettings } from "react-icons/ci";

const sidebar = [
  {
    Icon: <AiFillProfile className="text-textH3 p-1" />,
    name: "About Me",
    to: ABOUT_ME
  },
  {
    Icon: <RiAppsFill className="text-textH3 p-1 active:text-usafSecondary" />,
    name: "Applications",
    to: APPLICATION
  },
  {
    Icon: <CiSettings className="text-textH3 p-1" />,
    name: "Settings",
    to: "/Profile/Settings"
  },
  {
    Icon: <FiLogOut className="text-textH3 rotate-180 p-1" />,
    name: "Log Out",
    to: LOGOUT
  }
];
const Sidebar = () => {
  const { pathname } = useLocation();
  const [nav, setNav] = useState(false);
  const handleClose = () => setNav(!nav);
  return (
    <div className="md:w-[275px] pt-10 min-h-screen items-center w-fit p-2 md:px-8 bg-[#002D5E] gap-[5.93px]">
      {sidebar.map(({ Icon, name, to }, key) => (
        <Link
          key={key}
          to={to}
          onClick={handleClose}
          className={`${
            pathname === to
              ? " bg-white text-usafSecondary md:text-[#004B9D] font-extrabold border-[1px] border-[#004B9D]"
              : " bg-[#004B9D] text-white border-[1px] border-white "
          } flex item-center md:px-4 mx-auto justify-center w-full rounded-[3px] md:py-[18px] mb-5`}
        >
          <div className="font-['Montserrat'] font-bold text-[13px] hidden md:block">
            <span className="text-textsm">{name}</span>
          </div>
          <div className="md:hidden ">{Icon}</div>
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
