const SvgVMSDown = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M8.99973 7.87559L14.6427 1.24967C14.8697 0.985201 15.1946 0.819537 15.5462 0.788959C15.8979 0.758382 16.2475 0.865381 16.5187 1.08653C16.7898 1.30767 16.9604 1.62494 16.993 1.96885C17.0255 2.31277 16.9175 2.6553 16.6925 2.92144L10.0247 10.7502C9.89932 10.897 9.74256 11.0151 9.56546 11.0961C9.38836 11.1772 9.19524 11.2191 8.99974 11.2191C8.80424 11.2191 8.61112 11.1772 8.43402 11.0961C8.25692 11.0151 8.10016 10.897 7.97482 10.7502L1.307 2.92144C1.08093 2.65542 0.972016 2.31247 1.0042 1.96794C1.03638 1.6234 1.20702 1.30546 1.47863 1.08394C1.75025 0.862423 2.10062 0.755452 2.45279 0.786527C2.80496 0.817602 3.13012 0.984182 3.35684 1.24967L8.99973 7.87559Z"
        fill="white"
      />
    </svg>
  );
};
SvgVMSDown.defaultProps = {
  size: 17,
  color: "currentColor"
};
export default SvgVMSDown;
