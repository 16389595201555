const SvgVMSCalendar = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M7.5 1.5V3H13.5V1.5C13.5 0.671719 14.1703 0 15 0C15.8297 0 16.5 0.671719 16.5 1.5V3H18.75C19.9922 3 21 4.00734 21 5.25V7.5H0V5.25C0 4.00734 1.00734 3 2.25 3H4.5V1.5C4.5 0.671719 5.17031 0 6 0C6.82969 0 7.5 0.671719 7.5 1.5ZM0 9H21V21.75C21 22.9922 19.9922 24 18.75 24H2.25C1.00734 24 0 22.9922 0 21.75V9ZM3 14.25C3 14.6625 3.33562 15 3.75 15H5.25C5.6625 15 6 14.6625 6 14.25V12.75C6 12.3375 5.6625 12 5.25 12H3.75C3.33562 12 3 12.3375 3 12.75V14.25ZM9 14.25C9 14.6625 9.3375 15 9.75 15H11.25C11.6625 15 12 14.6625 12 14.25V12.75C12 12.3375 11.6625 12 11.25 12H9.75C9.3375 12 9 12.3375 9 12.75V14.25ZM15.75 12C15.3375 12 15 12.3375 15 12.75V14.25C15 14.6625 15.3375 15 15.75 15H17.25C17.6625 15 18 14.6625 18 14.25V12.75C18 12.3375 17.6625 12 17.25 12H15.75ZM3 20.25C3 20.6625 3.33562 21 3.75 21H5.25C5.6625 21 6 20.6625 6 20.25V18.75C6 18.3375 5.6625 18 5.25 18H3.75C3.33562 18 3 18.3375 3 18.75V20.25ZM9.75 18C9.3375 18 9 18.3375 9 18.75V20.25C9 20.6625 9.3375 21 9.75 21H11.25C11.6625 21 12 20.6625 12 20.25V18.75C12 18.3375 11.6625 18 11.25 18H9.75ZM15 20.25C15 20.6625 15.3375 21 15.75 21H17.25C17.6625 21 18 20.6625 18 20.25V18.75C18 18.3375 17.6625 18 17.25 18H15.75C15.3375 18 15 18.3375 15 18.75V20.25Z"
        fill="#004B9D"
      />
    </svg>
  );
};
SvgVMSCalendar.defaultProps = {
  size: 24,
  color: "currentColor"
};
export default SvgVMSCalendar;
