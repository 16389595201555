import axios from "axios";
import env from "configs";
import authHeader from "./auth-header";
import { LOGIN, LOGIN_SUCCESS_URL, LOG_OUT, REGISTER, VMS_USER_DATA } from "./constants";
const API_BASE_URL = env.API_BASE_URL;
const register = (firstName, lastName, email, password) => {
  return axios.post(API_BASE_URL + REGISTER, {
    firstName,
    lastName,
    email,
    password
  });
};

const login = async (username, password) => {
  const response = await axios.post(API_BASE_URL + LOGIN, {
    username,
    password
  });
  if (response.data?.DATA?.accessToken) {
    localStorage.setItem(VMS_USER_DATA, JSON.stringify(response.data.DATA));
  }
  return response.data;
};

const logout = async () => {
  try {
    const response = await axios.get(API_BASE_URL + LOG_OUT, { headers: authHeader() });
    if (response) {
      localStorage.removeItem(VMS_USER_DATA);
    }
  } catch (err) {
    console.log("logout err", err);
    localStorage.removeItem(VMS_USER_DATA);
  }
};
const verifyAccount = async (confirmationCode) => {
  try {
    const response = await axios.get(
      API_BASE_URL + `auth/local/account-activation/${confirmationCode}`
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const loginSuccess = async () => {
  try {
    const config = {
      url: API_BASE_URL + LOGIN_SUCCESS_URL,
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    };
    const response = await axios(config);
    if (response.data?.DATA?.accessToken) {
      localStorage.setItem(VMS_USER_DATA, JSON.stringify(response.data.DATA));
    }
    return response.data;
  } catch (err) {
    return err;
  }
};
const authService = {
  register,
  login,
  logout,
  verifyAccount,
  loginSuccess
};

export default authService;
