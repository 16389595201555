import { FAQ } from "components";
import Testimonial from "components/modules/Testimonial/TestimonialContainer";
import { Hero, ConnectWithOthers, ControlWork, ManageYourImpact } from "./ConnectWithSaiKaiScreen";
import { SignUpToday } from "pages/Home/HomeCompScreen";

const ConnectWithSaiKaiView = () => {
  return (
    <div className="overflow-hidden">
      <Hero />
      <ConnectWithOthers />
      <ControlWork />
      <ManageYourImpact />
      <Testimonial />
      <FAQ title="Frequently Asked Questions (FAQs)" />
      <SignUpToday />
    </div>
  );
};
export default ConnectWithSaiKaiView;
