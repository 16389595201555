export const HOME = "/";
export const PERFECT_VOLUNTEER = "/perfect_volunteer";
export const SAI_KAI_TASKS = "/sai_kai_tasks";
export const CONNECT_WITH_SAI_KAI_ = "/connect_with_sai_kai";
export const HELP_CENTER = "/help-center";
export const CUSTOMER_SUPPORT = "/support";
export const CONTACT = "/contact";
export const ABOUT = "/about";
export const PRIVACY = "/privacy";
export const TERMS = "/terms_and_cond";

export const JOB_ROLES = "/job_roles";
export const JOB_DESCRIPTION = "/job_description";

export const ABOUT_YOURSELF = "/about_yourself";
export const WORK_EXPERIENCE = "/work_experience";
export const QUALIFICATION = "/qualification";
export const REVIEW_APPLICATION = "/review_application";
export const APPLICATION_SUCCESSFUL = "/application_successful";

export const LOGIN = "/auth/login";
export const SIGNUP = "/auth/register";
export const SIGNUP_SUCCESS = "/auth/register/successful";
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const RESET_PASSWORD = "/auth/reset-password";
// export const RESET_PASSWORD = "/token/:token";
export const CHANGE_PASSWORD = "/auth/change-password";
export const CONFIRM_ACCOUNT = "/confirm/:token";

export const DASHBOARD = "/dashboard";

export const PROFILE = "/profile";
export const ABOUT_ME = "/profile/about-me";
export const ADD_WORK_EXPERIENCE = "/profile/about/work-experience/add";
export const APPLICATION = "/profile/application";
export const SETTINGS = "/profile/settings";
export const LOGOUT = "/profile/logout";
