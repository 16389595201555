import React, { useState } from "react";
import "./FilterDropdown.css";
import dropVector from "assets/svg/dropVector.svg";
import droptoggle from "assets/svg/droptoggle.svg";
import searchIcon from "assets/svg/searchIconBlue.svg";
import searchIcon2 from "assets/svg/searchIconWhite.svg";
// import { option1, option2, option3, option4, option5 } from "utils/jobFilterCate";

const FilterDropdownTwo = ({ selectedOption, dropOptions, icon, dropOptionsMV }) => {
  //styles for dropdown
  let normBorder = "8px";
  let newBd = "0%";
  let normBg = "#fff";
  let normColor = "#004B9D";
  let newBg = "#004B9D";
  let bgColor = "#fff";

  const [bg, setBg] = useState("");
  const [color, setBgColor] = useState("");

  //dropdown function and border style
  const [isActive, setIsActive] = useState(false);
  const [chosen, setChosen] = useState(selectedOption);

  const [flatBorder, setFlatBorder] = useState(normBorder);
  const flatIn = () => {
    setFlatBorder(newBd);
    setBg(normBg);
    setBgColor(normColor);
    setImage(dropVector);
  };
  const flatOut = () => {
    setFlatBorder(normBorder);
    setBg(newBg);
    setBgColor(bgColor);
    setImage(droptoggle);
  };
  const flatNorm = () => {
    setFlatBorder(normBorder);
    setBg(normBg);
    setBgColor(normColor);
    setImage(dropVector);
  };

  const style = {
    borderBottomRightRadius: flatBorder,
    borderBottomLeftRadius: flatBorder,
    backgroundColor: bg,
    color: color
  };

  // for image toggle change
  const [image, setImage] = useState(icon);
  function toggleImage() {
    if (image === dropVector) {
      setImage(droptoggle);
    } else {
      setImage(dropVector);
    }

    if (image === searchIcon) {
      setImage(searchIcon2);
    } else {
      setImage(searchIcon);
    }
  }

  //mobile view filter dropdown list
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptionMV, setSelectedOptionMV] = useState(selectedOption);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleRadioClick = (option) => {
    setSelectedOptionMV(option);
    setIsOpen(!false);
  };

  return (
    <div className="head-filter">
      <div className="jobFilter">
        <div
          className="dropFilter xxs:hidden md:block relative"
          chosen={chosen}
          setChosen={setChosen}
        >
          <button
            className="dropFilter-btn flex justify-between w-[180px] p-4 text-textsm text-usafPrimary font-LatoRegular 
            bg-[#fff] rounded-t-lg items-center border-2 border-usafPrimary"
            style={style}
            onClick={(e) => {
              setIsActive(!isActive);
              flatIn();
            }}
          >
            {chosen}
            {/* {chosen.length > 12 ? chosen.slice(0, 12) + '...' : chosen} */}
            <img
              src={image}
              alt="dropVector"
              className="vectorFilter"
              style={{ width: "15px", height: "15px" }}
            />
          </button>

          {isActive && (
            <div className="dropFilter-content z-10">
              {selectedOption.map((option) => (
                <div
                  className="dropFilter-item"
                  onClick={(e) => {
                    setChosen(option);
                    setIsActive(false);
                    flatNorm();
                  }}
                >
                  {option}
                </div>
              ))}

              {dropOptions.map((option) => (
                <div
                  className="dropFilter-item"
                  onClick={(e) => {
                    setChosen(option);
                    const truncatedOption =
                      option.length > 13 ? option.slice(0, 14) + "..." : option;
                    setChosen(truncatedOption);
                    setIsActive(false);
                    flatOut();
                  }}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="grid-row relative xxs:block md:hidden">
          <button
            className="flex justify-between w-[150px] p-4 text-textsm text-[#333] font-LatoRegular 
            bg-[#fff] rounded-t-lg items-center border-[1px] border-usafPrimary px-4 py-2 text-sm rounded-md 
              focus:outline-none focus:border-blue-500"
            onClick={handleDropdownToggle}
          >
            {selectedOption ? selectedOption : selectedOption.label}

            <div
              className={`w-4 h-4 ml-2 transition-transform duration-200 transform ${
                isOpen ? "rotate-180" : ""
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <img
                src={image}
                alt="dropVector"
                className="vectorFilter"
                style={{ width: "15px", height: "15px" }}
              />
            </div>
          </button>

          {isOpen && (
            <div
              className="cursor-pointer overflow-y-auto absolute z-10 w-[150px] max-h-[300px] bg-white border 
              border-gray-300 rounded-md shadow-lg"
            >
              {dropOptionsMV.map((option) => (
                <label
                  key={option.id}
                  htmlFor={`radioButton-${option.id}`}
                  className={`dropFilter-item flex items-center px-4 py-2 text-[10px] cursor-pointer 
                    ${selectedOptionMV?.id === option.id ? "bg-usafPrimary text-[#FFF]" : ""}`}
                >
                  <input
                    type="radio"
                    id={`radioButton-${option.id}`}
                    value={option.id}
                    checked={selectedOptionMV?.id === option.id}
                    onChange={() => {
                      handleRadioClick(option);
                    }}
                    className="dropFilter-item mr-2"
                  />
                  <span>{option.label}</span>
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default FilterDropdownTwo;
