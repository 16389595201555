import { select_icon } from "assets/images";
import { React, useState } from "react";

const SingleDropdown = ({ options, defaultOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between px-1 md:px-4 py-2 text-usafBlack bg-transparent border-2 border-usafPrimary6 rounded-md hover:text-usafBlack focus:outline-none"
      >
        <h6>{selectedOption || defaultOption}</h6>

        <img src={select_icon} alt="/" className="w-5 h-5 md:ml-5 ml-2" />
      </button>
      {isOpen && (
        <ul className="absolute z-10 w-full py-2 mt-1 border-usafPrimary6 border-2 overflow-auto bg-white rounded-md shadow-lg max-h-56">
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleOptionSelect(option)}
              className="px-4 py-2 cursor-pointer hover:bg-usafPrimary6 hover:text-white"
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SingleDropdown;
