import { Link } from "react-router-dom";
import { VMSimages } from "../../../assets";
import { Button, Navbar } from "../../../components";
import { HOME } from "../../../routes/CONSTANTS";

export const AccoutSuccessfullyCreated = () => {
  return (
    <div>
      <Navbar />
      <div className="min-h-[85vh] flex flex-col items-center justify-center">
        <img className="object-contain max-w-[120px] pb-[70px]" src={VMSimages.check_mark} alt="" />
        <h4 className="text-center font-['Spectral'] text-[#004B9D] text-[30px] font-semibold">
          Volunteer Account Successfully Created
        </h4>
        <p className="text-center font-['Lato'] text-[#666666] text-[14px]">
          Please click the link provided in your mail to confirm your account.
        </p>
        <div className="flex items-center justify-center pt-[74px]">
          <Link to={HOME}>
            <Button variant="full" size="lg" className="text-[12px] h-[52px]">
              Go Home
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
