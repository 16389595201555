import React, { useState } from "react";
import { Dashboard, Modal, ToggleComp } from "../../../components";
import { Link } from "react-router-dom";
import { SETTINGS, HOME, PROFILE } from "routes/CONSTANTS";
import ChangePassword from "./ChangePassword";
import edit_pen from "../../../assets/svg/editPen.svg";
import linkedIn_logo from "../../../assets/svg/linkedIn_logo.svg";
import { SingleDropdown } from "components/widgets/singleDropdown";
import { Button } from "components/widgets";
import SettingsCompWrapper from "./SettingsCompWrapper";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Home",
    to: HOME,
    direction: false
  },
  {
    id: 2,
    link: "Profile",
    to: SETTINGS,
    direction: true
  },
  {
    id: 3,
    link: "Settings",
    to: SETTINGS,
    direction: true
  }
];

export const Settings = () => {
  const [modal, setModal] = useState(false);
  const [toggleObj, setToggleObj] = useState({
    connect: false,
    email: false,
    text: false,
    account: false,
    job: false
  });
  const toggleModal = () => {
    setModal(!modal);
  };

  const handleToggle = (key) => {
    setToggleObj((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };
  const dropdownOptions = ["Option 1", "Option 2", "Option 3"];

  return (
    <div>
      {modal && (
        <Modal
          title="Change Password"
          content={<ChangePassword />}
          setModal={() => setModal(null)}
        />
      )}
      <Dashboard breadCrumbsLinks={breadCrumbsLinks}>
        <SettingsCompWrapper
          title="Connected Accounts"
          subTitle="Manage all accounts connected to your profile"
        >
          <ToggleComp
            id="toggle-connect"
            icon={linkedIn_logo}
            isChecked={toggleObj.connect}
            handleToggle={() => handleToggle("connect")}
          />
        </SettingsCompWrapper>

        <SettingsCompWrapper
          title="Notification Settings"
          subTitle="Get notified when new job opportunities are posted. Make changes to your account"
        >
          <ToggleComp
            id="toggle-email"
            title="Email Notification"
            isChecked={toggleObj.email}
            handleToggle={() => handleToggle("email")}
          />
          <ToggleComp
            id="toggle-text"
            title="Text Notification"
            isChecked={toggleObj.text}
            handleToggle={() => handleToggle("text")}
          />
        </SettingsCompWrapper>

        <SettingsCompWrapper title="Account Settings" subTitle="Make changes to your account">
          <div className="flex justify-between w-full items-center bg-usafGray3 p-2 mb-2 border-2 border-usafGray7 rounded-[4px]">
            <h6>Change Password</h6>
            <div className="flex">
              <section className="px-2 flex cursor-pointer">
                <p className="text-textxs pt-2 px-2">*******</p>
                <img
                  src={edit_pen}
                  className="object-contain pb-2 max-w-[20px] cursor-pointer"
                  alt=""
                  onClick={toggleModal}
                />
              </section>
            </div>
          </div>
          <ToggleComp
            id="toggle-account"
            title="Hide Account"
            isChecked={toggleObj.account}
            handleToggle={() => setToggleObj({ ...toggleObj, account: !toggleObj.account })}
          />
        </SettingsCompWrapper>

        <SettingsCompWrapper
          title="Privacy Settings"
          subTitle="Get notified when new job opportunities are posted. Make changes to your account"
        >
          <div className="flex justify-between items-center w-full bg-usafGray3 px-3 py-1 mb-2 border-2 border-usafGray7 rounded-[4px]">
            <h6>Who can see my profile</h6>
            <SingleDropdown options={dropdownOptions} defaultOption="Limited" />
          </div>
          <div className="flex justify-between items-center w-full bg-usafGray3 px-3 py-1 mb-2 border-2 border-usafGray7 rounded-[4px]">
            <h6>Search visibility</h6>
            <SingleDropdown options={dropdownOptions} defaultOption="Limited" />
          </div>
          <ToggleComp
            id="toggle-job"
            title="Hide job search activity"
            isChecked={toggleObj.job}
            handleToggle={() => setToggleObj({ ...toggleObj, job: !toggleObj.job })}
          />
        </SettingsCompWrapper>
        <div className="flex justify-center w-full">
          <Link to={PROFILE}>
            <Button
              variant="full"
              size="md"
              className="flex flex-wrap text-[12px] m-8 font-bold items-center cursor-pointer"
            >
              Save Changes
            </Button>
          </Link>
        </div>
      </Dashboard>
    </div>
  );
};
