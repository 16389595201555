export const countryOptions = [
  { key: "af", value: "af", flag: "af", text: "Afghanistan" },
  { key: "ax", value: "ax", flag: "ax", text: "Aland Islands" },
  { key: "al", value: "al", flag: "al", text: "Albania" },
  { key: "dz", value: "dz", flag: "dz", text: "Algeria" },
  { key: "as", value: "as", flag: "as", text: "American Samoa" },
  { key: "ad", value: "ad", flag: "ad", text: "Andorra" },
  { key: "ao", value: "ao", flag: "ao", text: "Angola" },
  { key: "ai", value: "ai", flag: "ai", text: "Anguilla" },
  { key: "ag", value: "ag", flag: "ag", text: "Antigua" },
  { key: "ar", value: "ar", flag: "ar", text: "Argentina" },
  { key: "am", value: "am", flag: "am", text: "Armenia" },
  { key: "aw", value: "aw", flag: "aw", text: "Aruba" },
  { key: "au", value: "au", flag: "au", text: "Australia" },
  { key: "at", value: "at", flag: "at", text: "Austria" },
  { key: "az", value: "az", flag: "az", text: "Azerbaijan" },
  { key: "bs", value: "bs", flag: "bs", text: "Bahamas" },
  { key: "bh", value: "bh", flag: "bh", text: "Bahrain" },
  { key: "bd", value: "bd", flag: "bd", text: "Bangladesh" },
  { key: "bb", value: "bb", flag: "bb", text: "Barbados" },
  { key: "by", value: "by", flag: "by", text: "Belarus" },
  { key: "be", value: "be", flag: "be", text: "Belgium" },
  { key: "bz", value: "bz", flag: "bz", text: "Belize" },
  { key: "bj", value: "bj", flag: "bj", text: "Benin" },
  { key: "ae", value: "ae", flag: "ae", text: "United Arab Emirates" },
  { key: "AD", value: "AD", flag: "ad", text: "Andorra" },
  { key: "AF", value: "AF", flag: "af", text: "Afghanistan" },
  { key: "AG", value: "AG", flag: "ag", text: "Antigua and Barbuda" },
  { key: "AI", value: "AI", flag: "ai", text: "Anguilla" },
  { key: "AL", value: "AL", flag: "al", text: "Albania" },
  { key: "AM", value: "AM", flag: "am", text: "Armenia" },
  { key: "AO", value: "AO", flag: "ao", text: "Angola" },
  {
    key: "AQ",
    value: "AQ",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg",
    text: "Antarctica"
  },
  { key: "AR", value: "AR", flag: "ar", text: "Argentina" },
  { key: "AS", value: "AS", flag: "as", text: "American Samoa" },
  { key: "AT", value: "AT", flag: "at", text: "Austria" },
  { key: "AU", value: "AU", flag: "au", text: "Australia" },
  { key: "AW", value: "AW", flag: "aw", text: "Aruba" },
  { key: "AX", value: "AX", flag: "ax", text: "\u00c5land Islands" },
  { key: "AZ", value: "AZ", flag: "az", text: "Azerbaijan" },
  { key: "BA", value: "BA", flag: "ba", text: "Bosnia and Herzegovina" },
  { key: "BB", value: "BB", flag: "bb", text: "Barbados" },
  { key: "BD", value: "BD", flag: "bd", text: "Bangladesh" },
  { key: "BE", value: "BE", flag: "be", text: "Belgium" },
  { key: "BF", value: "BF", flag: "bf", text: "Burkina Faso" },
  { key: "BG", value: "BG", flag: "bg", text: "Bulgaria" },
  { key: "BH", value: "BH", flag: "bh", text: "Bahrain" },
  { key: "BI", value: "BI", flag: "bi", text: "Burundi" },
  { key: "BJ", value: "BJ", flag: "bj", text: "Benin" },
  {
    key: "BL",
    value: "BL",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg",
    text: "Saint Barthélemy"
  },
  { key: "BM", value: "BM", flag: "bm", text: "Bermuda" },
  { key: "BN", value: "BN", flag: "bn", text: "Brunei Darussalam" },
  { key: "BO", value: "BO", flag: "bo", text: "Bolivia, Plurinational State of" },
  {
    key: "BQ",
    value: "BQ",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg",
    text: "Caribbean Netherlands"
  },
  { key: "BR", value: "BR", flag: "br", text: "Brazil" },
  { key: "BS", value: "BS", flag: "bs", text: "Bahamas" },
  { key: "BT", value: "BT", flag: "bt", text: "Bhutan" },
  { key: "BV", value: "BV", flag: "bv", text: "Bouvet Island" },
  { key: "BW", value: "BW", flag: "bw", text: "Botswana" },
  { key: "BY", value: "BY", flag: "by", text: "Belarus" },
  { key: "BZ", value: "BZ", flag: "bz", text: "Belize" },
  { key: "CA", value: "CA", flag: "ca", text: "Canada" },
  { key: "CC", value: "CC", flag: "cc", text: "Cocos (Keeling) Islands" },
  { key: "CD", value: "CD", flag: "cd", text: "Congo, the Democratic Republic of the" },
  { key: "CF", value: "CF", flag: "cf", text: "Central African Republic" },
  { key: "CG", value: "CG", flag: "cg", text: "Republic of the Congo" },
  { key: "CH", value: "CH", flag: "ch", text: "Switzerland" },
  { key: "CI", value: "CI", flag: "ci", text: "C\u00f4te d'Ivoire" },
  { key: "CK", value: "CK", flag: "ck", text: "Cook Islands" },
  { key: "CL", value: "CL", flag: "cl", text: "Chile" },
  { key: "CM", value: "CM", flag: "cm", text: "Cameroon" },
  { key: "CN", value: "CN", flag: "cn", text: "China (People's Republic of China)" },
  { key: "CO", value: "CO", flag: "co", text: "Colombia" },
  { key: "CR", value: "CR", flag: "cr", text: "Costa Rica" },
  { key: "CU", value: "CU", flag: "cu", text: "Cuba" },
  { key: "CV", value: "CV", flag: "cv", text: "Cape Verde" },
  {
    key: "CW",
    value: "CW",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg",
    text: "Cura\u00e7ao"
  },
  { key: "CX", value: "CX", flag: "cx", text: "Christmas Island" },
  { key: "CY", value: "CY", flag: "cy", text: "Cyprus" },
  { key: "CZ", value: "CZ", flag: "cz", text: "Czech Republic" },
  { key: "DE", value: "DE", flag: "de", text: "Germany" },
  { key: "DJ", value: "DJ", flag: "dj", text: "Djibouti" },
  { key: "DK", value: "DK", flag: "dk", text: "Denmark" },
  { key: "DM", value: "DM", flag: "dm", text: "Dominica" },
  { key: "DO", value: "DO", flag: "do", text: "Dominican Republic" },
  { key: "DZ", value: "DZ", flag: "dz", text: "Algeria" },
  { key: "EC", value: "EC", flag: "ec", text: "Ecuador" },
  { key: "EE", value: "EE", flag: "ee", text: "Estonia" },
  { key: "EG", value: "EG", flag: "eg", text: "Egypt" },
  { key: "EH", value: "EH", flag: "eh", text: "Western Sahara" },
  { key: "ER", value: "ER", flag: "er", text: "Eritrea" },
  { key: "ES", value: "ES", flag: "es", text: "Spain" },
  { key: "ET", value: "ET", flag: "et", text: "Ethiopia" },
  { key: "EU", value: "EU", flag: "eu", text: "Europe" },
  { key: "FI", value: "FI", flag: "fi", text: "Finland" },
  { key: "FJ", value: "FJ", flag: "fj", text: "Fiji" },
  { key: "FK", value: "FK", flag: "fk", text: "Falkland Islands (Malvinas)" },
  { key: "FM", value: "FM", flag: "fm", text: "Micronesia, Federated States of" },
  { key: "FO", value: "FO", flag: "fo", text: "Faroe Islands" },
  { key: "FR", value: "FR", flag: "fr", text: "France" },
  { key: "GA", value: "GA", flag: "ga", text: "Gabon" },

  {
    key: "GB-ENG",
    value: "GB-ENG",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg",
    text: "England"
  },
  {
    key: "GB-NIR",
    value: "GB-NIR",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB-NIR.svg",
    text: "Northern Ireland"
  },
  {
    key: "GB-SCT",
    value: "GB-SCT",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg",
    text: "Scotland"
  },
  {
    key: "GB-WLS",
    value: "GB-WLS",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg",
    text: "Wales"
  },

  { key: "GB", flag: "gb", value: "GB", text: "United Kingdom" },
  { key: "GD", flag: "gd", value: "GD", text: "Grenada" },
  { key: "GE", flag: "ge", value: "GE", text: "Georgia" },
  { key: "GF", flag: "gf", value: "GF", text: "French Guiana" },
  {
    key: "GG",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg",
    value: "GG",
    text: "Guernsey"
  },
  { key: "GH", flag: "gh", value: "GH", text: "Ghana" },
  { key: "GI", flag: "gi", value: "GI", text: "Gibraltar" },
  { key: "GL", flag: "gl", value: "GL", text: "Greenland" },
  { key: "GM", flag: "gm", value: "GM", text: "Gambia" },
  { key: "GN", flag: "gn", value: "GN", text: "Guinea" },
  { key: "GP", flag: "gp", value: "GP", text: "Guadeloupe" },
  { key: "GQ", flag: "gq", value: "GQ", text: "Equatorial Guinea" },
  { key: "GR", flag: "gr", value: "GR", text: "Greece" },
  { key: "GS", flag: "gs", value: "GS", text: "South Georgia and the South Sandwich Islands" },
  { key: "GT", flag: "gt", value: "GT", text: "Guatemala" },
  { key: "GU", flag: "gu", value: "GU", text: "Guam" },
  { key: "GW", flag: "gw", value: "GW", text: "Guinea-Bissau" },
  { key: "GY", flag: "gy", value: "GY", text: "Guyana" },
  { key: "HK", flag: "hk", value: "HK", text: "Hong Kong" },
  { key: "HM", flag: "hm", value: "HM", text: "Heard Island and McDonald Islands" },
  { key: "HN", flag: "hn", value: "HN", text: "Honduras" },
  { key: "HR", flag: "hr", value: "HR", text: "Croatia" },
  { key: "HT", flag: "ht", value: "HT", text: "Haiti" },
  { key: "HU", flag: "hu", value: "HU", text: "Hungary" },
  { key: "ID", flag: "id", value: "ID", text: "Indonesia" },
  { key: "IE", flag: "ie", value: "IE", text: "Ireland" },
  { key: "IL", flag: "il", value: "IL", text: "Israel" },
  {
    key: "IM",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg",
    value: "IM",
    text: "Isle of Man"
  },
  { key: "IN", flag: "in", value: "IN", text: "India" },
  { key: "IO", flag: "io", value: "IO", text: "British Indian Ocean Territory" },
  { key: "IQ", flag: "iq", value: "IQ", text: "Iraq" },
  { key: "IR", flag: "ir", value: "IR", text: "Iran, Islamic Republic of" },
  { key: "IS", flag: "is", value: "IS", text: "Iceland" },
  { key: "IT", flag: "it", value: "IT", text: "Italy" },
  {
    key: "JE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg",
    value: "JE",
    text: "Jersey"
  },
  { key: "JM", flag: "jm", value: "JM", text: "Jamaica" },
  { key: "JO", flag: "jo", value: "JO", text: "Jordan" },
  { key: "JP", flag: "jp", value: "JP", text: "Japan" },
  { key: "KE", flag: "ke", value: "KE", text: "Kenya" },
  { key: "KG", flag: "kg", value: "KG", text: "Kyrgyzstan" },
  { key: "KH", flag: "kh", value: "KH", text: "Cambodia" },
  { key: "KI", flag: "ki", value: "KI", text: "Kiribati" },
  { key: "KM", flag: "km", value: "KM", text: "Comoros" },
  { key: "KN", flag: "kn", value: "KN", text: "Saint Kitts and Nevis" },
  { key: "KP", flag: "kp", value: "KP", text: "Korea, Democratic People's Republic of" },
  { key: "KR", flag: "kr", value: "KR", text: "Korea, Republic of" },
  { key: "KW", flag: "kw", value: "KW", text: "Kuwait" },
  { key: "KY", flag: "ky", value: "KY", text: "Cayman Islands" },
  { key: "KZ", flag: "kz", value: "KZ", text: "Kazakhstan" },
  { key: "LA", flag: "la", value: "LA", text: "Laos (Lao People's Democratic Republic)" },
  { key: "LB", flag: "lb", value: "LB", text: "Lebanon" },
  { key: "LC", flag: "lc", value: "LC", text: "Saint Lucia" },
  { key: "LI", flag: "li", value: "LI", text: "Liechtenstein" },
  { key: "LK", flag: "lk", value: "LK", text: "Sri Lanka" },
  { key: "LR", flag: "lr", value: "LR", text: "Liberia" },
  { key: "LS", flag: "ls", value: "LS", text: "Lesotho" },
  { key: "LT", flag: "lt", value: "LT", text: "Lithuania" },
  { key: "LU", flag: "lu", value: "LU", text: "Luxembourg" },
  { key: "LV", flag: "lv", value: "LV", text: "Latvia" },
  { key: "LY", flag: "ly", value: "LY", text: "Libya" },
  { key: "MA", flag: "ma", value: "MA", text: "Morocco" },
  { key: "MC", flag: "mc", value: "MC", text: "Monaco" },
  { key: "MD", flag: "md", value: "MD", text: "Moldova, Republic of" },
  { key: "ME", flag: "me", value: "ME", text: "Montenegro" },
  {
    key: "MF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg",
    value: "MF",
    text: "Saint Martin"
  },
  { key: "MG", flag: "mg", value: "MG", text: "Madagascar" },
  { key: "MH", flag: "mh", value: "MH", text: "Marshall Islands" },
  { key: "MK", flag: "mk", value: "MK", text: "North Macedonia" },
  { key: "ML", flag: "ml", value: "ML", text: "Mali" },
  { key: "MM", flag: "mm", value: "MM", text: "Myanmar" },
  { key: "MN", flag: "mn", value: "MN", text: "Mongolia" },
  { key: "MO", flag: "mo", value: "MO", text: "Macao" },
  { key: "MP", flag: "mp", value: "MP", text: "Northern Mariana Islands" },
  { key: "MQ", flag: "mq", value: "MQ", text: "Martinique" },
  { key: "MR", flag: "mr", value: "MR", text: "Mauritania" },
  { key: "MS", flag: "ms", value: "MS", text: "Montserrat" },
  { key: "MT", flag: "mt", value: "MT", text: "Malta" },
  { key: "MU", flag: "mu", value: "MU", text: "Mauritius" },
  { key: "MV", flag: "mv", value: "MV", text: "Maldives" },
  { key: "MW", flag: "mw", value: "MW", text: "Malawi" },
  { key: "MX", flag: "mx", value: "MX", text: "Mexico" },
  { key: "MY", flag: "my", value: "MY", text: "Malaysia" },
  { key: "MZ", flag: "mz", value: "MZ", text: "Mozambique" },
  { key: "NA", flag: "na", value: "NA", text: "Namibia" },
  { key: "NC", flag: "nc", value: "NC", text: "New Caledonia" },
  { key: "NE", flag: "ne", value: "NE", text: "Niger" },
  { key: "NF", flag: "nf", value: "NF", text: "Norfolk Island" },
  { key: "NG", flag: "ng", value: "NG", text: "Nigeria" },
  { key: "NI", flag: "ni", value: "NI", text: "Nicaragua" },
  { key: "NL", flag: "nl", value: "NL", text: "Netherlands" },
  { key: "NO", flag: "no", value: "NO", text: "Norway" },
  { key: "NP", flag: "np", value: "NP", text: "Nepal" },
  { key: "NR", flag: "nr", value: "NR", text: "Nauru" },
  { key: "NU", flag: "nu", value: "NU", text: "Niue" },
  { key: "NZ", flag: "nz", value: "NZ", text: "New Zealand" },
  { key: "OM", flag: "om", value: "OM", text: "Oman" },
  { key: "PA", flag: "pa", value: "PA", text: "Panama" },
  { key: "PE", flag: "pe", value: "PE", text: "Peru" },
  { key: "PF", flag: "pf", value: "PF", text: "French Polynesia" },
  { key: "PG", flag: "pg", value: "PG", text: "Papua New Guinea" },
  { key: "PH", flag: "ph", value: "PH", text: "Philippines" },
  { key: "PK", flag: "pk", value: "PK", text: "Pakistan" },
  { key: "PL", flag: "pl", value: "PL", text: "Poland" },
  { key: "PM", flag: "pm", value: "PM", text: "Saint Pierre and Miquelon" },
  { key: "PN", flag: "pn", value: "PN", text: "Pitcairn" },
  { key: "PR", flag: "pr", value: "PR", text: "Puerto Rico" },
  { key: "PS", flag: "ps", value: "PS", text: "Palestine" },
  { key: "PT", flag: "pt", value: "PT", text: "Portugal" },
  { key: "PW", flag: "pw", value: "PW", text: "Palau" },
  { key: "PY", flag: "py", value: "PY", text: "Paraguay" },
  { key: "QA", flag: "qa", value: "QA", text: "Qatar" },
  { key: "RE", flag: "re", value: "RE", text: "Réunion" },
  { key: "RO", flag: "ro", value: "RO", text: "Romania" },
  { key: "RS", flag: "rs", value: "RS", text: "Serbia" },
  { key: "RU", flag: "ru", value: "RU", text: "Russian Federation" },
  { key: "RW", flag: "rw", value: "RW", text: "Rwanda" },
  { key: "SA", flag: "sa", value: "SA", text: "Saudi Arabia" },
  { key: "SB", flag: "sb", value: "SB", text: "Solomon Islands" },
  { key: "SC", flag: "sc", value: "SC", text: "Seychelles" },
  { key: "SD", flag: "sd", value: "SD", text: "Sudan" },
  { key: "SE", flag: "se", value: "SE", text: "Sweden" },
  { key: "SG", flag: "sg", value: "SG", text: "Singapore" },
  { key: "SH", flag: "sh", value: "SH", text: "Saint Helena, Ascension and Tristan da Cunha" },
  { key: "SI", flag: "si", value: "SI", text: "Slovenia" },
  { key: "SJ", flag: "sj", value: "SJ", text: "Svalbard and Jan Mayen Islands" },
  { key: "SK", flag: "sk", value: "SK", text: "Slovakia" },
  { key: "SL", flag: "sl", value: "SL", text: "Sierra Leone" },
  { key: "SM", flag: "sm", value: "SM", text: "San Marino" },
  { key: "SN", flag: "sn", value: "SN", text: "Senegal" },
  { key: "SO", flag: "so", value: "SO", text: "Somalia" },
  { key: "SR", flag: "sr", value: "SR", text: "Suriname" },
  {
    key: "SS",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg",
    value: "SS",
    text: "South Sudan"
  },
  { key: "ST", flag: "st", value: "ST", text: "Sao Tome and Principe" },
  { key: "SV", flag: "sv", value: "SV", text: "El Salvador" },
  {
    key: "SX",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg",
    value: "SX",
    text: "Sint Maarten (Dutch part)"
  },
  { key: "SY", flag: "sy", value: "SY", text: "Syrian Arab Republic" },
  { key: "SZ", flag: "sz", value: "SZ", text: "Swaziland" },
  { key: "TC", flag: "tc", value: "TC", text: "Turks and Caicos Islands" },
  { key: "TD", flag: "td", value: "TD", text: "Chad" },
  { key: "TF", flag: "tf", value: "TF", text: "French Southern Territories" },
  { key: "TG", flag: "tg", value: "TG", text: "Togo" },
  { key: "TH", flag: "th", value: "TH", text: "Thailand" },
  { key: "TJ", flag: "tj", value: "TJ", text: "Tajikistan" },
  { key: "TK", flag: "tk", value: "TK", text: "Tokelau" },
  { key: "TL", flag: "tl", value: "TL", text: "Timor-Leste" },
  { key: "TM", flag: "tm", value: "TM", text: "Turkmenistan" },
  { key: "TN", flag: "tn", value: "TN", text: "Tunisia" },
  { key: "TO", flag: "to", value: "TO", text: "Tonga" },
  { key: "TR", flag: "tr", value: "TR", text: "Turkey" },
  { key: "TT", flag: "tt", value: "TT", text: "Trinidad and Tobago" },
  { key: "TV", flag: "tv", value: "TV", text: "Tuvalu" },
  { key: "TW", flag: "tw", value: "TW", text: "Taiwan (Republic of China)" },
  { key: "TZ", flag: "tz", value: "TZ", text: "Tanzania, United Republic of" },
  { key: "UA", flag: "ua", value: "UA", text: "Ukraine" },
  { key: "UG", flag: "ug", value: "UG", text: "Uganda" },
  { key: "UM", flag: "um", value: "UM", text: "US Minor Outlying Islands" },
  { key: "US", flag: "us", value: "US", text: "United States" },
  { key: "UY", flag: "uy", value: "UY", text: "Uruguay" },
  { key: "UZ", flag: "uz", value: "UZ", text: "Uzbekistan" },
  { key: "VA", flag: "va", value: "VA", text: "Holy See (Vatican City State)" },
  { key: "VC", flag: "vc", value: "VC", text: "Saint Vincent and the Grenadines" },
  { key: "VE", flag: "ve", value: "VE", text: "Venezuela, Bolivarian Republic of" },
  { key: "VG", flag: "vg", value: "VG", text: "Virgin Islands, British" },
  { key: "VI", flag: "vi", value: "VI", text: "Virgin Islands, U.S." },
  { key: "VN", flag: "vn", value: "VN", text: "Vietnam" },
  { key: "VU", flag: "vu", value: "VU", text: "Vanuatu" },
  { key: "WF", flag: "wf", value: "WF", text: "Wallis and Futuna Islands" },
  { key: "WS", flag: "ws", value: "WS", text: "Samoa" },
  {
    key: "XK",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg",
    value: "XK",
    text: "Kosovo"
  },
  { key: "YE", flag: "ye", value: "YE", text: "Yemen" },
  { key: "YT", flag: "yt", value: "YT", text: "Mayotte" },
  { key: "ZA", flag: "za", value: "ZA", text: "South Africa" },
  { key: "ZM", flag: "zm", value: "ZM", text: "Zambia" },
  { key: "ZW", flag: "zw", value: "ZW", text: "Zimbabwe" }
];
