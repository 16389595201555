import React, { useState } from "react";
import { BreadCrumbs, Button, FormInput, FormSelect, Loader } from "../../../../../components";
import { VMSAdd, VMSOrganise } from "../../../../../components/icons";
import { industriesList } from "../../../../../utils/customOptions";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Skills",
    direction: false
  }
];

const breadCrumbsLinks2 = [
  {
    id: 1,
    link: "Skills",
    direction: false
  },
  {
    id: 2,
    link: "Add Skills",
    direction: true
  }
];

const AddSkills = ({ title, loading = false }) => {
  const [AddSkills, setAddSkills] = useState(false);
  return (
    <div>
      {AddSkills ? (
        <div>
          <BreadCrumbs
            breadCrumbsLinks={breadCrumbsLinks2}
            customStyles="px-[23px] min-h-[23px] mb-6"
          />
          <div className="flex flex-col space-y-10 mb-[100px] px-[23px]">
            <h4 className="font-['Spectral'] text-[#000] text-[20px]">Add Skills</h4>
            <div className="w-full h-[18.5rem] overflow-auto">
              <form className="max-w-[28rem] m-auto space-y-6">
                <div className="flex items-center">
                  <FormSelect
                    size="sm"
                    name="relatedSkills"
                    id="relatedSkills"
                    isMulti
                    options={industriesList}
                    label="Relevant Skills"
                    placeholder="Search by Skills, Language or Frameworks....."
                  />
                </div>
                <div className="flex items-center gap-3">
                  <FormInput
                    size="sm"
                    type="text"
                    name="rate"
                    id="rate"
                    placeholder="Rate your skill level"
                    className="w-full "
                    label="Rate Your Skill Level"
                  />
                </div>
                <div className="flex items-center">
                  <FormInput
                    size="sm"
                    type="text"
                    name="certificate"
                    id="certificate"
                    placeholder="Certificates earned during the period of work"
                    className="w-full"
                    optional
                    label="Certification & Licenses"
                  />
                </div>
                <div className="flex items-center">
                  <FormInput
                    size="sm"
                    type="text"
                    name="briefDescription"
                    id="briefDescription"
                    placeholder="Brief Description"
                    className="w-full"
                    optional
                    label="Brief Description"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <Button type="submit" variant="full" size="sm" className="text-[12px]">
                    {loading ? <Loader /> : "Add Skills"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} customStyles="px-[23px] min-h-[23px]" />
          <div className="flex flex-col space-y-10 mb-[100px]">
            <div className="flex space-x-5 my-6 px-4 items-center justify-end">
              <VMSOrganise
                size={20}
                onClick={() => {
                  // setOpenModal(null);
                }}
                className="cursor-pointer"
              />
              <VMSAdd
                size={20}
                onClick={() => {
                  setAddSkills(true);
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-center justify-center w-full bg-[#99B7D8] m-auto max-w-[36rem] py-14">
              <h6 className=" font-LatoRegular text-[16px] text-[#001E3F]">
                You have not added any {title}
              </h6>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddSkills;
