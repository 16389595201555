import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

const InputField = ({ label, touched, size, ...rest }) => {
  return (
    <div>
      <form>
        <div className="border w-full rounded-md h-16 bg-usafBlack4 px-8 items-center flex justify-between ">
          <p className="text-base text-usafBlack font-LatoBold">Attachment</p>
          <div className=" flex justify-between items-center gap-8 h-12" size="sm">
            <input size={size} {...rest} type="file" id="upload" hidden />
            <label
              for="upload"
              className="flex items-center px-6 py-3 text-base rounded-md gap-6 cursor-pointer bg-usafSecondary text-usafWhite P-4 font-LatoRegular "
            >
              <AiOutlineCloudUpload className="text-usafWhite text-[24px] font-bold" />
              Upload
            </label>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InputField;
