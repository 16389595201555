import React from "react";
import { BreadCrumbs, FAQ, HelpAndSupportComp } from "../../components";
import { helpCardDetails } from "../../utils/helpCardDetails";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Home",
    direction: false
  },
  {
    id: 2,
    link: "Help",
    direction: true
  }
];

const HelpCenterView = () => {
  return (
    <div>
      <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} />
      <HelpAndSupportComp title="Help Center" cardDetails={helpCardDetails} />
      <FAQ
        title="Frequently Asked Questions"
        customStyle="px-2 md:px-5 xl:px-9 py-9 grid bg-inherit mb-10"
        textStyle="mb-8"
      />
    </div>
  );
};

export default HelpCenterView;
