import React, { useState, useEffect, useRef } from "react";
import "../paginateStyles.css";
import { VMSimages } from "assets";
import ReactPaginate from "react-paginate";

import jobListingCss from "../jobListingStyles.module.css";
import { FilterDropdownTwo } from "components/widgets/FilterDropdowns";
import { Link } from "react-router-dom";
import dropVector from "assets/svg/dropVector.svg";
import searchIcon from "assets/svg/searchIconBlue.svg";
import { JOB_DESCRIPTION } from "routes/CONSTANTS";
import { JobFilterCate } from "utils/jobFilterCate";
import { Modal } from "components";

import {
  AboutYourSelf,
  ApplicationSuccessful,
  Qualification,
  ReviewApplication,
  WorkExperience
} from "pages";
import { option1, option2, option3, option4, option5, option6 } from "utils/jobFilterCate";
import {
  option1MV,
  option2MV,
  option3MV,
  option4MV,
  option5MV,
  option6MV,
  option7MV
} from "utils/jobFilterCate";
import logo from "assets/svg/logo.svg";

const JobCardsComp = (props) => {
  const [pages, setPages] = useState(props.jobs.slice(0, props.jobsLength));
  const [pageNumber, setPageNumber] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  useEffect(() => {
    if (pages.length == 0) {
      setPages(props.jobs.slice(0, props.jobsLength));
    }
  }, [pages]);

  const [jobCategorySelect, setJobCategorySelect] = useState("");
  const [experienceSelect, setExperienceSelect] = useState("");
  const [jobTypeSelect, setJobTypeSelect] = useState("");
  const [workTypeSelect, setWorktypeSelect] = useState("");

  const main_item_on_page = props.jobs.filter((item) =>
    wordEntered.toLowerCase() === ""
      ? item
      : item.description.toLowerCase().includes(wordEntered.toLowerCase())
  ).length;
  // setPages(props.jobs.slice(0, props.jobsLength));
  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;

  const currentPageNumber2 = pages.length - main_item_on_page;
  const currentPageNumber = pages.length - currentPageNumber2;

  const pageCount = Math.ceil(pages.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [countPage, setCountPage] = useState("default");
  const showPageCount = () => {
    setCountPage("showPageCount");
  };
  const hidePageCount = () => {
    setCountPage("hidePageCount");
  };

  // console.log(props.jobs);
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth"
  //   });
  // }, ['top'])

  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);

  const categories = ["All Categories"];
  const datePosted = ["Date Posted"];
  const experience = ["Experience"];
  const jobType = ["Job Type"];
  const workType = ["Work Type"];
  const location = ["Location"];
  // const optionSelect7 = ["Industries"];

  let white = "#FFF";
  const [bgColor, setBgColor] = useState(white);
  const changeColor = () => {
    let newBg = "#E1E1E1";
    setBgColor(newBg);
  };

  //searchbar border change
  let border = "2px solid #004B9D";
  const [borderBefore, setBorderBefore] = useState("");
  const bottomBorder = () => {
    setBorderBefore(border);
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;

    setWordEntered(searchWord);

    const newFilter = props.jobs.filter((value) => {
      return (
        value.description.toLowerCase().startsWith(wordEntered.toLowerCase()) &&
        value.description.toLowerCase() !== wordEntered.toLowerCase()
      );
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  // At mobile view
  const [showInput, setShowInput] = useState(false);
  const showSearchInputMobile = () => {
    setShowInput(true);
  };

  const handleFilterMobileView = (event) => {
    if (event && event.target) {
      // Access the 'target' property safely
      const searchWord = event.target.value;
      setWordEntered(searchWord);

      if (searchWord === "") {
        setFilteredData([]);
      } else {
        const newFilter = props.jobs.filter((value) => {
          return (
            value.description.toLowerCase().startsWith(wordEntered.toLowerCase()) &&
            value.description.toLowerCase() !== wordEntered.toLowerCase()
          );
        });
        setFilteredData(newFilter);
        showPageCount();
      }
    }
  };

  //add the filtere title to the searchbar
  const onSearch = (searchWord) => {
    setWordEntered(searchWord);
    // Api to fetch the search result
    console.log("search ", searchWord);
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
    setBgColor(white);
    bottomBorder(border);
    addPagination("show");
    hidePageCount("hidePageCount");
  };

  const [error, setError] = useState();

  //end of search bar section and filter

  const [next, setNext] = useState("");
  const removePagination = () => {
    setNext("hide");
  };
  const addPagination = () => {
    setNext("show");
  };

  const [active, setActive] = useState("toggle");
  const showNav = () => {
    setActive("toggle open_nav");
  };
  const removeNav = () => {
    setActive("toggle close_nav");
  };

  // Pagination

  return (
    <div id="top" className={jobListingCss.jobSec}>
      <div
        className={`w-full
        ${jobListingCss.searchJobListing}`}
      >
        <div className={`xxs:-mt-12 md:w-full md:-mt-1 px-3 relative ${jobListingCss.col}`}>
          <h2
            className={`max-md:w-[100%] xxs:text-center lg:text-right lg:mr-12 md:mx-auto font-[500] md:text-[40px] xxs:text-[20px]
            ${jobListingCss.heading}`}
          >
            Available Job Listings
          </h2>
        </div>

        <div className={`max-md:w-full ${jobListingCss.col}`}>
          <div className={jobListingCss.search}>
            <div className="max-lg:w-[100%]">
              {filteredData.length === 0 ? (
                <input
                  style={{ background: bgColor }}
                  placeholder="Search by Title, Keyword or skills....."
                  size="md"
                  iconSize={0}
                  className={`md:items-center md:justify-center md:mx-auto ${jobListingCss.search_input}`}
                  value={wordEntered}
                  onClick={removePagination}
                  onChange={handleFilter}
                />
              ) : (
                <input
                  style={{ background: bgColor, borderBottom: borderBefore }}
                  placeholder="Search by Title, Keyword or skills....."
                  size="md"
                  iconSize={0}
                  className={jobListingCss.search_input_bottom_border_change}
                  value={wordEntered}
                  onClick={removePagination}
                  onChange={handleFilter}
                />
              )}
            </div>

            <div className={jobListingCss.searchIcons}>
              {filteredData.length === 0 ? (
                <img
                  src={VMSimages.search_icon_blue}
                  alt="search"
                  className={jobListingCss.iconSearch}
                />
              ) : (
                <img
                  src={VMSimages.close}
                  alt="close"
                  className={jobListingCss.iconClose}
                  onClick={clearInput}
                />
              )}
            </div>

            <div className="">
              {!showInput ? (
                <img
                  src={VMSimages.search_icon_blue}
                  alt="search"
                  className={`${jobListingCss.iconSearch_2}`}
                  onClick={showSearchInputMobile}
                />
              ) : (
                <div>
                  <input
                    placeholder="search..."
                    className={jobListingCss.searchInput}
                    value={wordEntered}
                    onClick={removePagination}
                    onChange={handleFilterMobileView}
                  />
                  <img
                    src={VMSimages.close}
                    alt="search"
                    className={jobListingCss.iconSearch_2}
                    onClick={() => {
                      setShowInput(false);
                      clearInput();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={jobListingCss.filteredDataDropdown}>
          {filteredData.length !== 0 && (
            <div className={jobListingCss.dataResult}>
              {props.jobs
                .filter((value) => {
                  return (
                    wordEntered.toLowerCase() &&
                    value.description.toLowerCase().startsWith(wordEntered.toLowerCase()) &&
                    value.description.toLowerCase() !== wordEntered.toLowerCase()
                  );
                })
                .slice(0, 13)
                .map((value) => (
                  <div className={jobListingCss.dataItem}>
                    <p
                      onClick={() => {
                        onSearch(value.description);
                        bottomBorder();
                        changeColor();
                        showPageCount();
                      }}
                    >
                      {value.description}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <div
        className={`flex flex-col -gap-13 lg:flex-row ml-13 xxs:hidden md:inline-flex ${jobListingCss.filterWrapper}`}
      >
        <div className="flex items-center ml-9 gap-5">
          <FilterDropdownTwo
            selectedOption={categories.map((option) =>
              option.length > 14 ? option.slice(0, 14) + "..." : option
            )}
            dropOptions={option1}
            dropOptionsMV={option1MV}
            icon={dropVector}
            onChange={(selectedOption) => {
              console.log(selectedOption);
            }}
          />
          <FilterDropdownTwo
            selectedOption={datePosted.map((option) =>
              option.length > 14 ? option.slice(0, 14) + "..." : option
            )}
            dropOptions={option2}
            dropOptionsMV={option2MV}
            icon={dropVector}
            onChange={(selectedOption) => {
              console.log(selectedOption);
            }}
          />

          <FilterDropdownTwo
            selectedOption={experience.map((option) =>
              option.length > 14 ? option.slice(0, 14) + "..." : option
            )}
            dropOptions={option3}
            dropOptionsMV={option3MV}
            icon={dropVector}
            onChange={(selectedOption) => {
              console.log(selectedOption);
            }}
          />
        </div>

        <div className="flex items-center ml-7 gap-5">
          <FilterDropdownTwo
            selectedOption={jobType.map((option) =>
              option.length > 14 ? option.slice(0, 14) + "..." : option
            )}
            dropOptions={option4}
            dropOptionsMV={option4MV}
            icon={dropVector}
            onChange={(selectedOption) => {
              console.log(selectedOption);
            }}
          />
          <FilterDropdownTwo
            selectedOption={workType.map((option) =>
              option.length > 14 ? option.slice(0, 14) + "..." : option
            )}
            dropOptions={option5}
            dropOptionsMV={option5MV}
            icon={dropVector}
          />
          <FilterDropdownTwo
            selectedOption={location.map((option) =>
              option.length > 14 ? option.slice(0, 14) + "..." : option
            )}
            dropOptions={option6}
            dropOptionsMV={option6MV}
            icon={searchIcon}
          />
        </div>

        <div className="flex items-center -ml-3 gap-5">
          {/* <FilterDropdownTwo
            selectedOption={optionSelect7.map((option) =>
              option.length > 14 ? option.slice(0, 14) + "..." : option
            )}
            dropOptions={option7}
            dropOptionsMV={option7MV}
            icon={dropVector}
          /> */}

          <div className="xxs:hidden md:block mt-[3rem] w-[200px] ml-5">
            <p className={`w-[100px] ${jobListingCss.clearFilter}`}>Clear Filters</p>
          </div>
        </div>
      </div>

      <div
        className={`flex flex-col gap-3 lg:flex-row -ml-3 xxs:block md:hidden ${jobListingCss.filterWrapper}`}
      >
        <div className="flex items-center -ml-3 gap-5">
          <FilterDropdownTwo
            selectedOption={categories.map((option) =>
              option.length > 14 ? option.slice(0, 14) + "..." : option
            )}
            dropOptions={option1}
            dropOptionsMV={option1MV}
            icon={dropVector}
          />
        </div>
      </div>

      <div className={jobListingCss.hrLine}></div>

      <div className="pageCount">
        <span className={countPage}>
          <center>
            <strong>
              showing &nbsp; {currentPageNumber} of {currentPageNumber} results
            </strong>
          </center>
        </span>
      </div>

      <div className="mt-8">
        <div
          className={` w-full
          ${jobListingCss.cardContainer}`}
        >
          {props.jobs
            .filter((item) => {
              return wordEntered.toLowerCase() === ""
                ? item
                : item.description.toLowerCase().includes(wordEntered.toLowerCase());
            })
            .slice(pagesVisited, pagesVisited + usersPerPage)
            .map((item) => (
              <div
                className={`xxs:px-3 xxs:max-w-[300px] md:max-w-[291px]
                ${jobListingCss.cardWrapper}`}
                key={item.id}
              >
                <div className={jobListingCss.imageDate}>
                  <img src={logo} alt="" className={jobListingCss.imageDate_img} />
                  <h3 className={jobListingCss.imageDate_h3}>{item.datePosted}</h3>
                </div>

                <div className={`xxs:-mt-6 md:mt-0 ${jobListingCss.typeLocation}`}>
                  <h4>USAF</h4>
                  <h4>{item.workType}</h4>
                </div>

                <div
                  className={`xxs:-mt-5 xxs:text-[18px] md:text-[22px] md:mt-0 ${jobListingCss.destTitle}`}
                >
                  <h5>{item.description}</h5>
                </div>

                <div className={`xxs:-mt-6 md:mt-0 ${jobListingCss.typeLocation}`}>
                  <h3 className={jobListingCss.location}>{item.country}</h3>
                  <h3 className={jobListingCss.type}>{item.jobType}</h3>
                </div>

                <div
                  className={`xxs:flex xxs:justify-between xxs:gap-[3.9rem] xxs:py-3 xxs:-mt-9 md:mt-0 md:py-2 md:grid md:justify-start md:gap-0 
                  ${jobListingCss.para}`}
                >
                  <p className={`text-[10px] lg:text-[12px] ${jobListingCss.para_p}`}>
                    {item.role}
                  </p>
                  <p className={`text-[10px] lg:text-[12px] ${jobListingCss.para_p}`}>
                    {item.experienceLevel}
                  </p>
                </div>

                <div className="xxs:hidden md:block mb-0">
                  <p className={`xxs:hidden md:block ${jobListingCss.desc_p}`}> {item.details}</p>
                </div>

                <div
                  className="md:pb-12 md:pt-2 md:mt-0 justify-center md:grid md:gap-14 pb-0
                  xxs:-mt-2 xxs:flex xxs:justify-between xxs:max-w-[140px]"
                >
                  <Link onClick={() => setOpenModal(true)}>
                    <button
                      className={`xxs:w-[125px] xxs:h-[35px] xxs:text-[12px] md:text-[14px] md:w-[262px] 
                      ${jobListingCss.buttonAV_btnOne}`}
                    >
                      Apply
                    </button>
                  </Link>

                  <Link to={JOB_DESCRIPTION}>
                    <button
                      className={`xxs:w-[125px] xxs:h-[35px] xxs:text-[12px] md:w-[262px] md:text-[14px] 
                      ${jobListingCss.buttonAV_btnTwo}`}
                    >
                      View
                    </button>
                  </Link>
                </div>
              </div>
            ))}

          {props.jobs.filter((item) =>
            wordEntered.toLowerCase() === ""
              ? item
              : item.description.toLowerCase().includes(wordEntered.toLowerCase())
          ).length === 0 ? (
            <h2 className={jobListingCss.errorMsg}>
              no available job listing matching the keyword
              <span className="font-LatoBold text-red-600">"{wordEntered}"</span>
            </h2>
          ) : (
            ""
          )}

          {error ? <h1>{error}</h1> : null}
        </div>
      </div>

      {openModal && (
        <Modal
          title="UI/UX Designer"
          content={
            <AboutYourSelf
              title="UI/UX Designer"
              setModal={() => setOpenModal(null)}
              setModal1={() => setOpenModal1(true)}
            />
          }
        />
      )}

      {openModal1 && (
        <Modal
          title="UI/UX Designer"
          content={
            <WorkExperience
              title="UI/UX Designer"
              breadCrumbAYS={() => setOpenModal(true)}
              setModal1={() => setOpenModal1(null)}
              setModal2={() => setOpenModal2(true)}
            />
          }
        />
      )}

      {openModal2 && (
        <Modal
          title="UI/UX Designer"
          content={
            <Qualification
              title="UI/UX Designer"
              breadCrumbAYS={() => setOpenModal(true)}
              breadCrumbWExp={() => setOpenModal1(true)}
              setModal2={() => setOpenModal2(null)}
              setModal3={() => setOpenModal3(true)}
            />
          }
        />
      )}

      {openModal3 && (
        <Modal
          title="UI/UX Designer"
          content={
            <ReviewApplication
              title="UI/UX Designer"
              breadCrumbAYS={() => setOpenModal(true)}
              breadCrumbWExp={() => setOpenModal1(true)}
              breadCrumbQua={() => setOpenModal2(true)}
              setModal3={() => setOpenModal3(null)}
              setModal4={() => setOpenModal4(true)}
            />
          }
        />
      )}

      {openModal4 && (
        <Modal
          title="UI/UX Designer"
          content={
            <ApplicationSuccessful
              title="UI/UX Designer"
              setModal={() => setOpenModal(null)}
              setModal1={() => setOpenModal1(null)}
              setModal2={() => setOpenModal2(null)}
              setModal3={() => setOpenModal3(null)}
              setModal4={() => setOpenModal4(null)}
            />
          }
        />
      )}

      <div className="pagination">
        <center className={next}>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </center>
      </div>
    </div>
  );
};

export default JobCardsComp;
