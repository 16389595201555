import axios from "axios";
import { env } from "configs/environment.config";

const API_BASE_URL = env.API_BASE_URL;

export let getAllJobs = async () => {
  try {
    const allJobs = await axios.get(API_BASE_URL + "jobs");
    const response = allJobs.data.DATA.programs;
    const responseLength = response.length;

    return { response, responseLength };
  } catch (error) {
    console.log(error);
  }
};

const jobsService = { getAllJobs };

export default jobsService;
