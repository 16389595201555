import React from "react";
import Review from "./Reviews/Review";
import { BreadCrumbs } from "components";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Application Status",
    direction: false
  }
];

const breadCrumbsLinks2 = [
  {
    id: 1,
    link: "Application Status",
    direction: false
  },
  {
    id: 2,
    link: "Add Application Status",
    direction: true
  }
];

const ApplicationStatus = () => {
  return (
    <div>
      <div>
        <BreadCrumbs
          breadCrumbsLinks={breadCrumbsLinks}
          customStyles="px-[23px] min-h-[23px] mb-6 "
        />
      </div>

      <div className="">
        <div className=" mx-14 mt-14 mb-32">
          <Review />
        </div>
      </div>
    </div>
  );
};

export default ApplicationStatus;
