import React, { useState } from "react";
import ellipse from "../../../../assets/img/ellipse.png";
import smallCircle from "../../../../assets/img/smallCircle.png";
import smallDots from "../../../../assets/img/smallDots.png";
import { Button } from "../../../../components";
import { Modal } from "../../../../components";
import ApplicationStatus from "../ApplicationStatus/ApplicationStatus";

const ApplicationProfile = () => {
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <d className="space-y-4 relative hidden md:block">
      {openModal && (
        <Modal
          title="Application Status"
          content={<ApplicationStatus />}
          setOpenModal={() => setOpenModal(null)}
        />
      )}
      <section>
        <div className="flex">
          <div className="flex  items-center justify-between w-full  bg-usafWhite  mt-6 rounded-2xl border border-usafBlack4">
            <div className="flex items-start space-x-5 p-6">
              <img src={ellipse} className="object-contain " alt="hello" />
              <div className="">
                <span className="flex items-center space-x-4">
                  <p className=" font-SpectralRegular text-textH4  text-usafBlack font-bold">
                    Rotherham Institute of Design
                  </p>
                </span>
                <p className="font-LatoRegular text-[20px] text-usafBlack2 pb-1">
                  Product Designer
                </p>
                <p className="font-LatoRegular text-base text-usafBlack2 pb-1">Lagos, Nigeria</p>
                <p className="font-LatoRegular text-base text-usafBlack2 pb-1">Remote</p>
              </div>
            </div>
            <div className="space-y-12">
              <p className="text-textsm flex justify-end  text-usafBlack2 font-LatoRegular">
                Applied 2 hours ago
              </p>
              <Button onClick={toggleModal}>
                <p className="text-textsm font-MontserratSemiBold text-usafWhite">View Status</p>
              </Button>
            </div>
            <div className=" md:block hidden px-6 py-4 h-full my-auto bg-usafPrimary3 rounded-tr-2xl rounded-br-2xl ">
              <div className="flex items-center space-x-4   ">
                <div className="">
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="">
                  <p className="text-usafPrimary8  text-base font-LatoRegular">
                    Application Submitted
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex ">
          <div className="flex items-center justify-between w-full bg-usafWhite rounded-2xl border border-usafBlack4">
            <div className="flex items-start space-x-5 p-6">
              <img src={ellipse} className="object-contain " alt="hello" />
              <div className="">
                <span className="flex items-center space-x-4">
                  <p className=" font-SpectralRegular text-textH4  text-usafBlack font-bold">
                    Rotherham Institute of Design
                  </p>
                </span>
                <p className=" font-LatoRegular text-[20px] text-usafBlack2 pb-1">
                  Product Designer
                </p>
                <p className="font-LatoRegular text-base text-usafBlack2 pb-1">Lagos, Nigeria</p>
                <p className="font-LatoRegular text-base text-usafBlack2 pb-1">Remote</p>
              </div>
            </div>
            <div className="space-y-12">
              <p className="text-textsm flex justify-end  text-usafBlack2 font-LatoRegular">
                Applied 2 hours ago
              </p>
              <Button onClick={toggleModal}>
                <p className="text-textsm font-MontserratSemiBold text-usafWhite">View Status</p>
              </Button>
            </div>
            <div className=" md:block hidden top-0 p-4   h-full my-auto bg-usafPrimary3 rounded-tr-2xl rounded-br-2xl ">
              <div className="flex items-center space-x-4  ">
                <div>
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="flex">
                  <p className="text-usafPrimary8  text-base font-LatoRegular">
                    Application Submitted
                  </p>
                </div>
              </div>
              <div className="flex pl-[2px]">
                <img src={smallDots} alt="dots" />
              </div>
              <div className="flex items-center space-x-4  ">
                <div>
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="">
                  <p className="text-usafPrimary8  text-base  font-LatoRegular">
                    Application Viewed
                  </p>
                </div>
              </div>
              <div className="pl-[2px]">
                <img src={smallDots} alt="dots" />
              </div>
              <div className="flex items-center space-x-4  ">
                <div>
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="flex">
                  <p className="text-usafPrimary8  text-base font-LatoRegular">
                    Application Rejected
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex ">
          <div className="flex items-center justify-between w-full  bg-usafWhite  rounded-2xl border border-usafBlack4">
            <div className="flex items-start space-x-5 p-6">
              <img src={ellipse} className="object-contain " alt="hello" />
              <div className="">
                <span className="flex items-center space-x-4">
                  <p className=" font-SpectralRegular text-textH4  text-usafBlack font-bold">
                    Rotherham Institute of Design
                  </p>
                </span>
                <p className="font-LatoRegular text-[20px] text-usafBlack2 pb-1">
                  Product Designer
                </p>
                <p className="font-LatoRegular text-base text-usafBlack2 pb-1">Lagos, Nigeria</p>
                <p className="font-LatoRegular text-base text-usafBlack2 pb-1">Remote</p>
              </div>
            </div>
            <div className="space-y-12">
              <p className="text-textsm flex justify-end  text-usafBlack2 font-LatoRegular">
                Applied 2 hours ago
              </p>
              <Button onClick={toggleModal}>
                <p className="text-textsm font-MontserratSemiBold text-usafWhite">View Status</p>
              </Button>
            </div>
            <div className="md:block hidden  top-0 p-4  h-full my-auto bg-usafPrimary3 rounded-tr-2xl rounded-br-2xl ">
              <div className="flex items-center space-x-4  ">
                <div>
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="flex">
                  <p className="text-usafPrimary8  text-base font-LatoRegular">
                    Application Submitted
                  </p>
                </div>
              </div>
              <div className="pl-[2px]">
                <img src={smallDots} alt="dots" />
              </div>
              <div className="flex items-center space-x-4  ">
                <div>
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="">
                  <p className="text-usafPrimary8  text-base  font-LatoRegular">
                    Application Viewed
                  </p>
                </div>
              </div>
              <div className="pl-[2px]">
                <img src={smallDots} alt="dots" />
              </div>
              <div className="flex items-center space-x-4  ">
                <div>
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="flex">
                  <p className="text-usafPrimary8  text-base font-LatoRegular">
                    Application Rejected
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex ">
          <div className="flex items-center justify-between w-full mb-11 bg-usafWhite rounded-2xl border border-usafBlack4">
            <div className="flex items-start space-x-5 p-6">
              <img src={ellipse} className="object-contain " alt="hello" />
              <div className="">
                <span className="flex items-center space-x-4">
                  <p className=" font-SpectralRegular text-textH4  text-usafBlack font-bold">
                    Rotherham Institute of Design
                  </p>
                </span>
                <p className="font-LatoRegular text-[20px] text-usafBlack2 pb-1">
                  Product Designer
                </p>
                <p className="font-LatoRegular text-base text-usafBlack2 pb-1">Lagos, Nigeria</p>
                <p className="font-LatoRegular text-base text-usafBlack2 pb-1">Remote</p>
              </div>
            </div>
            <div className="space-y-12">
              <p className="text-textsm flex justify-end  text-usafBlack2 font-LatoRegular">
                Applied 2 hours ago
              </p>
              <Button onClick={toggleModal}>
                <p className="text-textsm font-MontserratSemiBold text-usafWhite">View Status</p>
              </Button>
            </div>
            <div className="md:block hidden  top-0 p-4   h-full my-auto bg-usafPrimary3 rounded-tr-2xl rounded-br-2xl ">
              <div className="flex items-center space-x-4">
                <div>
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="flex">
                  <p className="text-usafPrimary8  text-base font-LatoRegular">
                    Application Submitted
                  </p>
                </div>
              </div>
              <div className="pl-[2px]">
                <img src={smallDots} alt="dots" />
              </div>
              <div className="flex items-center space-x-4   ">
                <div>
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="">
                  <p className="text-usafPrimary8  text-base  font-LatoRegular">
                    Application Viewed
                  </p>
                </div>
              </div>
              <div className="pl-[2px]">
                <img src={smallDots} alt="dots" />
              </div>
              <div className="flex items-center space-x-4 ">
                <div>
                  <img src={smallCircle} alt="common" />
                </div>
                <div className="flex">
                  <p className="text-usafPrimary8  text-base font-LatoRegular">
                    Application Rejected
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </d>
  );
};

export default ApplicationProfile;
