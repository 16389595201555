import CustomerSupportView from "./CustomerSupportView";
import { Landing } from "../../components";

export const CustomerSupportContainer = () => {
  return (
    <Landing>
      <CustomerSupportView />
    </Landing>
  );
};
