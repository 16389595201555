import React, { useState } from "react";
import { NavigationComp } from "components";
import TestimonialComp from "./TestimonialComp";
import { testimonialData } from "utils/testimonialData";
import { EffectCoverflow, Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

const Testimonial = () => {
  return (
    <div className="px-2 md:px-5 xl:px-9 py-9 grid bg-white mb-10">
      <div className="flex items-center justify-between mb-10">
        <h4 className="ffont-['Spectral_Semi_Bold'] text-[#004B9D] text-[40px] font-[500]`">
          Testimonials
        </h4>
        <NavigationComp description="See More" />
      </div>
      <div className="flex w-[100%] p-4 justify-center items-center">
        <Swiper
          className="w-[190vh] rounded-3xl shadow-xl"
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          spaceBetween={30}
          slidesPerView={"auto"}
          navigation={true}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          coverflowEffect={{
            rotate: 0,
            stretch: 5,
            depth: 0,
            modifier: 1.5
          }}

          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log('slide change')}
        >
          {testimonialData?.map((data) => (
            <SwiperSlide
              key={data.id}
              className="md:max-w-fit lg:max-w-fit justify-center items-center"
            >
              <TestimonialComp {...data} />
            </SwiperSlide>
          ))}
          ...
        </Swiper>
      </div>
    </div>

    // <div className="px-2 md:px-5 xl:px-9 py-9 grid bg-white mb-10">
    //   <div className="flex items-center justify-between mb-14">
    //     <h4 className="ffont-['Spectral_Semi_Bold'] text-[#004B9D] text-[40px] font-[500]`">
    //       Testimonials
    //     </h4>
    //     <NavigationComp description="See More" />
    //   </div>
    //   <div className="flex justify-center items-center">
    //     <Swiper
    //       effect={"coverflow"}
    //       grabCursor={true}
    //       centeredSlides={true}
    //       loop={true}
    //       coverflowEffect={{
    //         rotate: 0,
    //         stretch: 0,
    //         depth: 50,
    //         modifier: 1.5
    //       }}
    //       slidesPerView={"auto"}
    //       modules={[EffectCoverflow, Pagination, Navigation]}
    //       className="w-full"
    //     >
    //       {testimonialData?.map((data) => (
    //         <SwiperSlide key={data.id} className="max-w-md justify-center items-center">
    //           <TestimonialComp {...data} />
    //         </SwiperSlide>
    //       ))}
    //     </Swiper>
    //   </div>
    // </div>
  );
};

export default Testimonial;
