import React, { useState } from "react";
import "./accordionStyle.css";

const AccordionDropdown = ({ item }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (child) => {
    if (selectedItems.includes(child)) {
      setSelectedItems(selectedItems.filter((item) => item !== child));
    } else {
      setSelectedItems([...selectedItems, child]);
    }
  };

  if (item.childrens) {
    return (
      <div className={isActive ? "sidebar-item open" : "sidebar-item"}>
        <div className="sidebar-title font-SpectralBold text-[20px]">
          <span>{item.title}</span>
          <i className="bi-chevron-down toggle-btn" onClick={() => setIsActive(!isActive)} />
        </div>
        <div className="sidebar-content font-SpectralBold text-[18px]">
          {item.childrens.map((child, index) => (
            <div key={index} className="checkbox-item flex gap-5">
              <input
                type="checkbox"
                id={`checkbox-${item.title}-${index}`}
                value={child.title}
                checked={selectedItems.includes(child.title)}
                onChange={() => handleCheckboxChange(child.title)}
              />
              <label htmlFor={`checkbox-${item.title}-${index}`}>{child.title}</label>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="sidebar-item">
        {item.title}
        <div className="checkbox-item">
          <input
            type="checkbox"
            id={`checkbox-${item.title}`}
            value={item.title}
            checked={selectedItems.includes(item.title)}
            onChange={() => handleCheckboxChange(item.title)}
          />
          <label htmlFor={`checkbox-${item.title}`}>{item.title}</label>
        </div>
      </div>
    );
  }
};

export default AccordionDropdown;
