import React from "react";

const CircularProgressBar = ({ value }) => {
  const r = 20; // radius of the circle

  const circumference = Math.PI * r * 2;
  const offset = circumference - (value / 100) * circumference;

  return (
    <div className="relative inline-block">
      <div className="overflow-hidden rounded-full w-[60px] h-[90px] md:w-[130px] md:h-[130px]">
        <svg
          className="absolute top-0 left-0 w-full h-full"
          viewBox="0 0 44 44"
          transform="rotate(-90)"
        >
          <circle
            className="stroke-[#99D8B6] shadow-lg"
            cx="22"
            cy="22"
            r={r}
            strokeWidth="3"
            fill="none"
          />
          <circle
            className="stroke-[#007E3A] shadow-lg"
            cx="22"
            cy="22"
            r={r}
            strokeWidth="3"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
            fill="none"
          />
        </svg>
        <div className="relative w-full h-full text-center text-2xl font-bold">
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <h4 className="font-['Spectral'] text-[20px] md:text-[30px] text-[#009D49] font-bold">
              {value}%
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircularProgressBar;
