import React from "react";
import { VMSimages } from "assets";
import "./slider.css";

function SliderCarousel(props) {
  const {
    name1,
    organization1,
    role1,
    description1,
    name2,
    organization2,
    role2,
    description2,
    name3,
    organization3,
    role3,
    description3,
    name4,
    organization4,
    role4,
    description4,
    name5,
    organization5,
    role5,
    description5
  } = props;

  return (
    <div className="cardContainer w-full">
      <div className="py-5 mx-auto max-h-[550px] max-w-[900px]">
        <input type="radio" name="slider" className="hidden" id="s1" defaultChecked />
        <input type="radio" name="slider" className="hidden" id="s2" />
        <input type="radio" name="slider" className="hidden" id="s3" />
        <input type="radio" name="slider" className="hidden" id="s4" />
        <input type="radio" name="slider" className="hidden" id="s5" />

        <div className="cards mx-auto w-full">
          <label htmlFor="s1" id="slide1" className="label">
            <div className="carrier pt-[2.5rem]">
              <div className="w-full max-h-[350px] flex">
                <div className="w-[70%]">
                  <img
                    className="absolute top-[2.5rem] left-[3rem] w-9"
                    src={VMSimages.card_vector_front}
                    alt=""
                  />
                  <p className="max-w-[550px] leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description1}
                  </p>
                  <img
                    className="absolute right-[23rem] -mt-[1rem] w-9"
                    src={VMSimages.card_vector_back}
                    alt=""
                  />
                </div>

                <div className="image w-[30%]">
                  <img src={VMSimages.card_img_1} alt="" className="h-[450px] w-[300px]" />
                </div>
              </div>

              <div className="grid justify-start">
                <h4 className="text-left text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name1}
                </h4>
                <h6 className="text-left text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization1}
                </h6>
                <small className="text-left text-[#666666] font-[Lato] text-[10px]">{role1}</small>
              </div>
            </div>
          </label>

          <label htmlFor="s2" id="slide2" className="label">
            <div className="carrier pt-[2.5rem]">
              <div className="max-h-[350px] flex">
                <div className="w-[70%]">
                  <img
                    className="absolute top-[2.5rem] left-[3rem] w-9"
                    src={VMSimages.card_vector_front}
                    alt=""
                  />
                  <p className="max-w-[550px] leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description2}
                  </p>
                  <img
                    className="absolute right-[23rem] -mt-[1rem] w-9"
                    src={VMSimages.card_vector_back}
                    alt=""
                  />
                </div>

                <div className="image w-[30%]">
                  <img src={VMSimages.card_img_3} alt="" className="h-[450px] w-[300px]" />
                </div>
              </div>

              <div className="">
                <h4 className="text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name2}
                </h4>
                <h6 className="text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization2}
                </h6>
                <small className="text-[#666666] font-[Lato] text-[10px]">{role2}</small>
              </div>
            </div>
          </label>

          <label htmlFor="s3" id="slide3" className="label">
            <div className="carrier pt-[2.5rem]">
              <div className="max-h-[350px] flex">
                <div className="w-[70%]">
                  <img
                    className="absolute top-[2.5rem] left-[3rem] w-9"
                    src={VMSimages.card_vector_front}
                    alt=""
                  />
                  <p className="max-w-[550px] leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description3}
                  </p>
                  <img
                    className="absolute right-[23rem] -mt-[1rem] w-9"
                    src={VMSimages.card_vector_back}
                    alt=""
                  />
                </div>

                <div className="image w-[30%]">
                  <img src={VMSimages.card_img_1} alt="" className="h-[450px] w-[300px]" />
                </div>
              </div>

              <div className="">
                <h4 className="text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name3}
                </h4>
                <h6 className="text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization3}
                </h6>
                <small className="text-[#666666] font-[Lato] text-[10px]">{role3}</small>
              </div>
            </div>
          </label>

          <label htmlFor="s4" id="slide4" className="label">
            <div className="carrier pt-[2.5rem]">
              <div className="max-h-[350px] flex">
                <div className="w-[70%]">
                  <img
                    className="absolute top-[2.5rem] left-[3rem] w-9"
                    src={VMSimages.card_vector_front}
                    alt=""
                  />
                  <p className="max-w-[550px] leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description4}
                  </p>
                  <img
                    className="absolute right-[23rem] -mt-[1rem] w-9"
                    src={VMSimages.card_vector_back}
                    alt=""
                  />
                </div>

                <div className="image w-[30%]">
                  <img src={VMSimages.card_img_3} alt="" className="h-[450px] w-[300px]" />
                </div>
              </div>

              <div className="">
                <h4 className="text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name4}
                </h4>
                <h6 className="text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization4}
                </h6>
                <small className="text-[#666666] font-[Lato] text-[10px]">{role4}</small>
              </div>
            </div>
          </label>

          <label htmlFor="s5" id="slide5" className="label">
            <div className="carrier pt-[2.5rem]">
              <div className="max-h-[350px] flex">
                <div className="w-[70%]">
                  <img
                    className="absolute top-[2.5rem] left-[3rem] w-9"
                    src={VMSimages.card_vector_front}
                    alt=""
                  />
                  <p className="max-w-[550px] leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description5}
                  </p>
                  <img
                    className="absolute right-[23rem] -mt-[1rem] w-9"
                    src={VMSimages.card_vector_back}
                    alt=""
                  />
                </div>

                <div className="image w-[30%]">
                  <img src={VMSimages.card_img_2} alt="" className="h-[450px] w-[300px]" />
                </div>
              </div>

              <div className="">
                <h4 className="text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name5}
                </h4>
                <h6 className="text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization5}
                </h6>
                <small className="text-[#666666] font-[Lato] text-[10px]">{role5}</small>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
}

export default SliderCarousel;
