const SvgVMSGear = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M4.77049 11.7687L0.282078 4.28804L3.27436 1.29576L10.7551 5.78418V8.77646L18.4495 16.4709C18.4495 16.6846 18.4495 16.8984 18.4495 17.1121L15.6709 19.6769L7.76277 11.7687H4.77049ZM36.8306 29.9361C36.1894 29.9361 35.7619 29.9361 35.1207 29.9361C33.6246 29.9361 32.3422 29.7224 31.0598 29.5087L26.5714 34.6383L36.6169 45.9662C38.968 48.531 43.0289 48.7447 45.38 46.1799C47.9448 43.6151 47.7311 39.7679 45.1662 37.4168L36.8306 29.9361ZM44.0976 21.8142C47.5173 18.3945 48.586 13.2649 47.3036 8.77646C47.0898 7.92152 46.0212 7.70779 45.5937 8.34899L38.113 15.8297L32.1285 9.84513L39.6092 2.36443C40.2504 1.72323 40.0366 0.868295 39.1817 0.654561C34.6933 -0.841578 29.5637 0.227092 26.1439 3.86057C22.2967 7.70779 21.4418 13.6923 23.5791 18.3945L2.20569 37.4168C-0.359124 39.7679 -0.572859 43.8288 1.99195 46.1799C4.55676 48.7447 8.40398 48.531 10.7551 45.9662L29.7774 24.5928C34.4795 26.7301 40.2504 25.8752 44.0976 21.8142Z"
        fill="#004B9D"
      />
    </svg>
  );
};
SvgVMSGear.defaultProps = {
  size: 48,
  color: "currentColor"
};
export default SvgVMSGear;
