import React from "react";
import { Link } from "react-router-dom";
import { HOME } from "../../../routes/CONSTANTS";
import { VMSimages } from "../../../assets";

const Footer = () => {
  return (
    <div>
      <div className="relative md:block hidden w-full flex-col bg-no-repeat bg-cover text-white bg-primary overflow-hidden">
        <div className="flex items-center justify-between py-4 px-5 md:px-9 border-b border-usafDarkAsh">
          <Link to={HOME}>
            <img src={VMSimages.saiKaiVms} className="object-contain max-w-[70px]" alt="logo" />
          </Link>
          <div className="flex items-center space-x-6">
            <Link to={HOME}>
              <img
                src={VMSimages.twitter_icon}
                className="object-contain max-w-[50px]"
                alt="twitter_icon"
              />
            </Link>
            <Link to={HOME}>
              <img
                src={VMSimages.facebook_icon}
                className="object-contain max-w-[50px]"
                alt="facebook_icon"
              />
            </Link>
            <Link to={HOME}>
              <img
                src={VMSimages.instagram_icon}
                className="object-contain max-w-[50px]"
                alt="instagram_icon"
              />
            </Link>
          </div>
        </div>

        <div
          className="w-full pt-7 pb-14 px-5 md:px-9 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-8 
        justify-between border-b border-usafDarkAsh"
        >
          <div className="space-y-3">
            <h5 className="font-['Spectral'] text-[32px] font-[500] text-white">USAF</h5>

            <div className="flex flex-col space-y-2 font-['Lato'] text-grayTwo text-[18px]">
              <Link to="#">119 Houston, Texas</Link>
              <Link to="#">+1234443</Link>
              <Link to="#">usaf@GMAIL.COM</Link>
            </div>
          </div>

          <div className="hidden xl:flex"></div>

          <div className="space-y-3">
            <h5 className="font-['Spectral'] text-[32px] font-[500] text-white">Products</h5>

            <div className="flex flex-col space-y-2 font-['Lato'] text-grayTwo text-[18px]">
              <Link to="#">VMS</Link>
              <Link to="#">HR Portal</Link>
              <Link to="#">Zumaridi</Link>
            </div>
          </div>

          <div className="space-y-3">
            <h5 className="font-['Spectral'] text-[32px] font-[500] text-white">Company</h5>

            <div className="flex flex-col space-y-2 font-['Lato'] text-grayTwo text-[18px]">
              <Link to="#">About USAF</Link>
              <Link to="#">Events</Link>
              <Link to="#">Press</Link>
              <Link to="#">Partners</Link>
              <Link to="#">Careers</Link>
            </div>
          </div>

          <div className="space-y-3">
            <h5 className="font-['Spectral'] text-[32px] font-[500] text-white">Help</h5>

            <div className="flex flex-col space-y-2 font-['Lato'] text-grayTwo text-[18px]">
              <Link to="#">Help Center</Link>
              <Link to="#">Support</Link>
              <Link to="#">Privacy Policy</Link>
              <Link to="#">FAQ</Link>
            </div>
          </div>
        </div>
        <div className="text-right text-[14px] font-[spectral] font-[600] text-grayTwo py-4 pr-8">
          © Copyright 2023 USAF All rights reserved.
        </div>
      </div>

      {/* mobile */}
      <div className="relative w-full md:hidden flex flex-col bg-no-repeat bg-cover text-white bg-primary overflow-hidden">
        <div className="flex items-center justify-between py-4 px-5 md:px-9 border-b border-white">
          <Link to={HOME} className="flex justify-center items-center space-x-8">
            <img src={VMSimages.saiKaiVms} className="object-contain max-w-[70px]" alt="logo" />
            <div className="space-y-3">
              <h5 className="font-['Spectral'] text-[24px] sm:text-[32px] font-[500] text-white">
                USAF
              </h5>
            </div>
          </Link>

          <div className="flex flex-col space-y-2 font-['Lato'] text-grayTwo text-[14px] sm:text-[18px]">
            <Link to="#">119 Houston, Texas</Link>
            <Link to="#">+1234443</Link>
            <Link to="#">usaf@GMAIL.COM</Link>
          </div>
        </div>

        <div
          className=" b w-full pt-7 space-y-6 pb-14 px-5 md:px-9 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-8 
        justify-between "
        >
          <div className="hidden xl:flex"></div>

          <div className="space-y-3">
            <h5 className="font-['Spectral'] text-[24px] sm:text-[32px] font-[500] text-white">
              Products
            </h5>

            <div className="flex flex-wrap items-center space-x-6 font-['Lato'] text-grayTwo text-[14px] sm:text-[18px]">
              <Link to="#" className="border-b-2 border-grayTwo">
                VMS
              </Link>
              <Link to="#" className="border-b-2 border-grayTwo">
                HR Portal
              </Link>
              <Link to="#" className="border-b-2 border-grayTwo">
                Zumaridi
              </Link>
            </div>
          </div>

          <div className="space-y-3">
            <h5 className="font-['Spectral'] text-[24px] sm:text-[32px] font-[500] text-white">
              Company
            </h5>
            <div className="flex flex-wrap items-center space-x-6 font-['Lato'] text-grayTwo text-[14px] sm:text-[18px]">
              <Link to="#" className="border-b-2 border-grayTwo">
                About USAF
              </Link>
              <Link to="#" className="border-b-2 border-grayTwo">
                Events
              </Link>
              <Link to="#" className="border-b-2 border-grayTwo">
                Press
              </Link>
              <Link to="#" className="border-b-2 border-grayTwo">
                Partners
              </Link>
              <Link to="#" className="border-b-2 border-grayTwo">
                Careers
              </Link>
            </div>
          </div>

          <div className="space-y-3">
            <h5 className="font-['Spectral'] text-[24px] sm:text-[32px] font-[500] text-white">
              Help
            </h5>
            <div className="flex items-center flex-wrap space-x-6 font-['Lato'] text-grayTwo text-[14px] sm:text-[18px]">
              <Link to="#" className="border-b-2 border-grayTwo">
                Help Center
              </Link>
              <Link to="#" className="border-b-2 border-grayTwo">
                Support
              </Link>
              <Link to="#" className="border-b-2 border-grayTwo">
                Privacy Policy
              </Link>
              <Link to="#" className="border-b-2 border-grayTwo">
                FAQ
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center text-[14px] font-[spectral] font-[600] text-grayTwo py-16 pr-8">
          <div className="flex items-center space-x-10">
            <Link to={HOME}>
              <img
                src={VMSimages.twitter_icon}
                className="object-contain max-w-[50px]"
                alt="twitter_icon"
              />
            </Link>
            <Link to={HOME}>
              <img
                src={VMSimages.facebook_icon}
                className="object-contain max-w-[50px]"
                alt="facebook_icon"
              />
            </Link>
            <Link to={HOME}>
              <img
                src={VMSimages.instagram_icon}
                className="object-contain max-w-[50px]"
                alt="instagram_icon"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
