const VMSSearch = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M25.0981 23.1403L19.8171 17.8594C21.4059 15.7981 22.1988 13.2257 22.0382 10.6168C21.8671 7.83969 20.6289 5.23629 18.5825 3.35117C16.5361 1.46605 13.8401 0.445234 11.0583 0.502267C8.27655 0.5593 5.62459 1.68976 3.65718 3.65718C1.68976 5.62459 0.5593 8.27655 0.502267 11.0583C0.445234 13.8401 1.46605 16.5361 3.35117 18.5825C5.23629 20.6289 7.83969 21.8671 10.6168 22.0381C13.2257 22.1988 15.7981 21.4059 17.8594 19.8171L23.141 25.0987C23.1412 25.099 23.1415 25.0992 23.1417 25.0995C23.2677 25.2263 23.4175 25.3269 23.5824 25.3957C23.7478 25.4645 23.9251 25.5 24.1042 25.5C24.2833 25.5 24.4606 25.4645 24.6259 25.3957C24.7862 25.3289 24.9322 25.2319 25.0559 25.1101C25.1894 24.9891 25.2975 24.8425 25.3738 24.6791C25.4524 24.5108 25.4955 24.3281 25.5006 24.1424C25.5057 23.9567 25.4726 23.772 25.4034 23.5996C25.3343 23.4278 25.2309 23.2719 25.0993 23.1416C25.0989 23.1412 25.0985 23.1407 25.0981 23.1403ZM11.2893 19.3211C9.69403 19.3211 8.13455 18.8481 6.80809 17.9618C5.48163 17.0755 4.44779 15.8157 3.83729 14.3418C3.22679 12.868 3.06705 11.2461 3.37828 9.68148C3.68951 8.11682 4.45773 6.67958 5.58579 5.55152C6.71385 4.42347 8.15108 3.65525 9.71574 3.34402C11.2804 3.03279 12.9022 3.19252 14.3761 3.80302C15.85 4.41352 17.1097 5.44737 17.996 6.77383C18.8823 8.10028 19.3554 9.65977 19.3554 11.2551C19.3554 12.3143 19.1468 13.3632 18.7414 14.3418C18.3361 15.3204 17.7419 16.2096 16.9929 16.9586C16.2439 17.7076 15.3547 18.3018 14.3761 18.7071C13.3975 19.1125 12.3486 19.3211 11.2893 19.3211Z"
        fill="#004B9D"
        stroke="#004B9D"
      />
    </svg>
  );
};
VMSSearch.defaultProps = {
  size: 26,
  color: "currentColor"
};
export default VMSSearch;
