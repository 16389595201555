import React from "react";
import { FaqList } from "../../../utils/faqList";
import QuestionsComp from "./QuestionsComp";

const FAQ = ({ customStyle, title, textStyle }) => {
  return (
    <div
      className={`${customStyle ? customStyle : "px-2 md:px-5 xl:px-9 py-9 grid bg-white mb-10"}`}
    >
      <h4
        className={`${
          textStyle ? textStyle : "mb-14"
        } font-['Spectral_Semi_Bold'] text-[#004B9D] lg:text-[40px] lg:font-[800] xxs:font-[500] xxs:text-[24px]`}
      >
        {title}
      </h4>
      {FaqList?.map((data) => (
        <QuestionsComp key={data.id} question={data.question} answer={data.answer} />
      ))}
    </div>
  );
};

export default FAQ;
