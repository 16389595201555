import { BreadCrumbs } from "components";
import React, { useState } from "react";

const Review = () => {
  const [switchFeature, setSwitchFeature] = useState(false);
  const [switchFeature1, setSwitchFeature1] = useState(false);
  const [switchFeature2, setSwitchFeature2] = useState(false);
  const [switchFeature3, setSwitchFeature3] = useState(false);

  const review1 = [
    {
      id: "1",
      title: "Automated Review",
      description:
        "Your application has been received by our automated system and will decide if you fit the role you applied for",
      time: "3 day(s)"
    }
  ];
  const review2 = [
    {
      id: "2",
      title: "First Phase HR Review",
      description: "HR has received application and has begun first phase of application review.",

      time: "3 day(s)"
    }
  ];
  const review3 = [
    {
      id: "3",
      title: "Virtual Interview Review",
      description:
        "HR has completed first phase of review and has begun reviewing your Virtual Interview.",
      time: "3 day(s)"
    }
  ];
  const review4 = [
    {
      id: "4",
      title: "Virtual Interview Review",
      description:
        "HR has completed the review of your virtual interview and  has begun the last phase of application review",
      time: "3 day(s)"
    }
  ];
  return (
    <div className="grid grid-cols-4 gap-2">
      <div className="relative ">
        <div
          className={` ${
            switchFeature
              ? "border-2 border-usafPrimary rounded-lg"
              : "border-2 border-grayTwo  rounded-lg"
          }`}
          onClick={() => setSwitchFeature(true)}
        >
          {review1.map((item, index) => {
            return (
              <div key={index} className="relative z-100 cursor-pointer">
                <p
                  className={` ${
                    switchFeature
                      ? "text-center border-2 text-usafPrimary border-usafPrimary absolute rounded-full justify-center w-10 h-10 flex mx-auto top-[-20px] bg-usafWhite left-[80px] items-center"
                      : "text-center border-2 text-grayTwo border-grayTwo absolute rounded-full justify-center w-10 h-10 flex mx-auto top-[-20px] bg-usafWhite left-[80px] items-center"
                  }`}
                >
                  {item.id}
                </p>

                <p
                  className={`${
                    switchFeature
                      ? "text-center font-SpectralRegular text-usafWhite text-[20px] py-6 bg-usafPrimary"
                      : "text-center font-SpectralRegular text-usafWhite text-[20px] py-6 bg-grayTwo"
                  }`}
                >
                  {item.title}
                </p>
                <div className="space-y-[70px]">
                  <p
                    className={`${
                      switchFeature
                        ? "text-usafBlack font-LatoRegular text-base"
                        : "text-grayTwo font-LatoRegular text-base"
                    }`}
                  >
                    {item.description}
                  </p>

                  <div className="mt-2"></div>
                  <p
                    className={`${
                      switchFeature
                        ? "text-base font-LatoBold text-usafBlack font-bold"
                        : "text-base font-LatoBold text-grayTwo font-bold"
                    }`}
                  >
                    Expected time: {item.time}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="relative">
        <div
          className={` ${
            switchFeature1
              ? "border-2 border-usafPrimary rounded-lg"
              : "border-2 border-grayTwo  rounded-lg"
          }`}
          onClick={() => setSwitchFeature1(true)}
        >
          <div>
            {review2.map((item, index) => {
              return (
                <div key={index} className="relative z-100 cursor-pointer">
                  <p
                    className={` ${
                      switchFeature1
                        ? "text-center border-2 text-usafPrimary border-usafPrimary absolute rounded-full justify-center w-10 h-10 flex mx-auto top-[-20px] bg-usafWhite left-[80px] items-center"
                        : "text-center border-2 text-grayTwo border-grayTwo absolute rounded-full justify-center w-10 h-10 flex mx-auto top-[-20px] bg-usafWhite left-[80px] items-center"
                    }`}
                  >
                    {item.id}
                  </p>

                  <p
                    className={`${
                      switchFeature1
                        ? "text-center font-SpectralRegular text-usafWhite text-[20px] py-6 bg-usafPrimary"
                        : "text-center font-SpectralRegular text-usafWhite text-[20px] py-6 bg-grayTwo"
                    }`}
                  >
                    First Phase HR
                    <br /> Review
                  </p>
                  <div className="space-y-16">
                    <p
                      className={`${
                        switchFeature1
                          ? "text-usafBlack font-LatoRegular text-base"
                          : "text-grayTwo font-LatoRegular text-base"
                      }`}
                    >
                      {item.description}
                    </p>

                    <div className=""></div>
                    <p
                      className={`${
                        switchFeature1
                          ? "text-base font-LatoBold text-usafBlack font-bold"
                          : "text-base font-LatoBold text-grayTwo font-bold"
                      }`}
                    >
                      Expected time: {item.time}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={`${
              switchFeature1
                ? "border-[16px] absolute rounded-b-lg border-t-0 w-40 border-usafPrimary flex justify-center mx-auto left-[-85px]  h-16"
                : "border-[16px] absolute rounded-b-lg border-t-0 w-40 flex left-[-85px] justify-center mx-auto  h-16"
            }`}
          ></div>
        </div>
      </div>
      <div className="relative">
        <div
          className={` ${
            switchFeature2
              ? "border-2 border-usafPrimary rounded-lg"
              : "border-2 border-grayTwo  rounded-lg"
          }`}
          onClick={() => setSwitchFeature2(true)}
        >
          <div>
            {review3.map((item, index) => {
              return (
                <div key={index} className="relative z-100 cursor-pointer">
                  <p
                    className={` ${
                      switchFeature2
                        ? "text-center border-2 text-usafPrimary border-usafPrimary absolute rounded-full justify-center w-10 h-10 flex mx-auto top-[-20px] bg-usafWhite left-[80px] items-center"
                        : "text-center border-2 text-grayTwo border-grayTwo absolute rounded-full justify-center w-10 h-10 flex mx-auto top-[-20px] bg-usafWhite left-[80px] items-center"
                    }`}
                  >
                    {item.id}
                  </p>

                  <p
                    className={`${
                      switchFeature2
                        ? "text-center font-SpectralRegular text-usafWhite text-[20px] py-6 bg-usafPrimary"
                        : "text-center font-SpectralRegular text-usafWhite text-[20px] py-6 bg-grayTwo"
                    }`}
                  >
                    First Phase HR
                    <br /> Review
                  </p>
                  <div className="space-y-16">
                    <p
                      className={`${
                        switchFeature2
                          ? "text-usafBlack font-LatoRegular text-base"
                          : "text-grayTwo font-LatoRegular text-base"
                      }`}
                    >
                      {item.description}
                    </p>

                    <p
                      className={`${
                        switchFeature2
                          ? "text-base font-LatoBold text-usafBlack font-bold"
                          : "text-base font-LatoBold text-grayTwo font-bold"
                      }`}
                    >
                      Expected time: {item.time}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={`${
              switchFeature2
                ? "border-[16px] absolute rounded-b-lg border-t-0 w-40 border-usafPrimary flex justify-center mx-auto left-[-85px]  h-16"
                : "border-[16px] absolute rounded-b-lg border-t-0 w-40 flex left-[-85px] justify-center mx-auto  h-16"
            }`}
          ></div>
        </div>
      </div>
      <div className="relative">
        <div
          className={` ${
            switchFeature3
              ? "border-2 border-usafPrimary rounded-lg"
              : "border-2 border-grayTwo  rounded-lg"
          }`}
          onClick={() => setSwitchFeature3(true)}
        >
          <div>
            {review4.map((item, index) => {
              return (
                <div key={index} className="relative z-100 cursor-pointer">
                  <p
                    className={` ${
                      switchFeature3
                        ? "text-center border-2 text-usafPrimary border-usafPrimary absolute rounded-full justify-center w-10 h-10 flex mx-auto top-[-20px] bg-usafWhite left-[80px] items-center"
                        : "text-center border-2 text-grayTwo border-grayTwo absolute rounded-full justify-center w-10 h-10 flex mx-auto top-[-20px] bg-usafWhite left-[80px] items-center"
                    }`}
                  >
                    {item.id}
                  </p>

                  <p
                    className={`${
                      switchFeature3
                        ? "text-center font-SpectralRegular text-usafWhite text-[20px] py-6 bg-usafPrimary"
                        : "text-center font-SpectralRegular text-usafWhite  text-[20px] py-6 bg-grayTwo"
                    }`}
                  >
                    First Phase HR
                    <br /> Review
                  </p>
                  <div className=" space-y-9">
                    <p
                      className={`${
                        switchFeature3
                          ? "text-usafBlack font-LatoRegular text-base"
                          : "text-grayTwo font-LatoRegular text-base"
                      }`}
                    >
                      {item.description}
                    </p>

                    <div className=""></div>
                    <p
                      className={` ${
                        switchFeature3
                          ? "text-base font-LatoBold text-usafBlack font-bold"
                          : "text-base font-LatoBold text-grayTwo font-bold"
                      }`}
                    >
                      Expected time: {item.time}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={`${
              switchFeature3
                ? "border-[16px] absolute rounded-b-lg border-t-0 w-40 border-usafPrimary flex justify-center mx-auto left-[-85px]  h-16"
                : "border-[16px] absolute rounded-b-lg border-t-0 w-40 flex left-[-85px] justify-center mx-auto  h-16"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Review;
