import { Link, useLocation } from "react-router-dom";

const NavLink = ({ to, children }) => {
  const { pathname } = useLocation();
  return (
    <Link
      to={to}
      className={`${
        pathname === to
          ? "text-secondary hover:border-b-2 border-b-usafPrimary6"
          : "text-white hover:text-secondary"
      } pb-px px-1 font-['Spectral'] text-[20px] font-[600]`}
    >
      {children}
    </Link>
  );
};

export default NavLink;
