import React from "react";
import { VMSimages } from "assets";
import { Button } from "components/widgets";
import { Link } from "react-router-dom";
import { JOB_ROLES } from "routes/CONSTANTS";

const Hero = () => {
  return (
    <div className="px-2 md:px-5 xl:px-9 py-7 grid grid-cols-1 gap-3 lg:grid-cols-2 bg-usafHeroBg mb-10">
      <div className="flex flex-col items-start xxs:items-center sm:items-center md:items-center lg:items-start lg:order-1 xxs:order-2">
        <h1
          className="font-['Spectral_Bold'] font-[800] max-w-[650px] text-left xxs:text-center md:text-center lg:text-left text-[36px] 
          md:text-[54px] lg:text-[74px] xl:text-[84px] text-[#004B9D] leading-[60px] lg:leading-[100px] xl:leading-[120px]"
        >
          The Ultimate Volunteer Experience.
        </h1>
        <p
          className="mb-9 mt-2 text-[16px] lg:text-[18px] xl:text-[18px] text-[#666666] leading-[30px] lg:leading-[35px] 
          max-w-[1000px] text-left xxs:text-center md:text-center lg:text-left"
        >
          Be a part of a community of changemakers who are working together to create real,
          meaningful impact. Sign up and track your impact in real time with Sai Kai
        </p>

        <Link to={JOB_ROLES}>
          <Button variant="full" size="lg" className="text-[14px] font-bold lg:mt-12 md:mt-0">
            Find My Next Job
          </Button>
        </Link>
      </div>
      <div
        className="flex flex-col xxs:-mt-5 md:mt-0 max-w-[100%] justify-between 
        items-center xxs:items-center xxs:justify-center lg:order-2 xxs:order-1"
      >
        <img
          src={VMSimages.usa_hands}
          className="object-contain xxs:max-w-[100%] sm:max-w-[90%] lg:max-w-full md:max-w-full w-full"
          alt="usa_hands"
        />
        <img
          src={VMSimages.usa_hands_2}
          className="object-contain xxs:max-w-[100%] sm:max-w-[90%] lg:max-w-full md:max-w-full w-full"
          alt="usa_hand_2"
        />
      </div>
    </div>
  );
};

export default Hero;
