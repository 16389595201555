import React from "react";
import { Dashboard } from "../../../components";
import dropVector from "assets/svg/dropVector.svg";
import flag from "assets/svg/flag_fr.svg";
import { Cards } from "./cards";
import { ApplicationProfile, MobileApplicationProfile } from "./AppicationProfile";
import { FilterDropdownTwo } from "components/widgets/FilterDropdowns";
import { optionOne } from "utils/jobFilterCate";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Home",
    direction: false
  },
  {
    id: 2,
    link: "Profile",
    direction: true
  },
  {
    id: 3,
    link: "Application",
    direction: true
  }
];

const optionSelect1 = ["Date Applied"];
const optionSelect2 = ["Job Type"];
const optionSelect3 = ["Location"];
const optionSelect4 = ["Job Status"];

const option1 = ["Past 1 hour", "Past 24 hours", "Past Week", "Past Month", "Anytime"];

const option2 = ["Contract/Freelance", "Full Time", "Internship", "Part Time"];

const option3 = [
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>,
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>,
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>,
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>,
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>
];

const option4 = ["Fresher", "Entry Level", "Executive", "Mid-Level", "Senior Level"];

export const Application = () => {
  return (
    <Dashboard breadCrumbsLinks={breadCrumbsLinks}>
      <Cards />
      <div>
        <hr className="w-full text-usafBlack2 my-4 bg-usafBlack4 " />
      </div>

      <div className=" hidden md:flex items-center justify-between">
        <FilterDropdownTwo selectedOption={optionSelect1} dropOptions={option1} icon={dropVector} />
        <FilterDropdownTwo selectedOption={optionSelect2} dropOptions={option2} icon={dropVector} />
        <FilterDropdownTwo selectedOption={optionSelect3} dropOptions={option3} icon={dropVector} />
        <FilterDropdownTwo selectedOption={optionSelect4} dropOptions={option4} icon={dropVector} />
        <div className="my-auto flex items-center justify-center cursor-pointer">
          <p className="text-usafPrimary text-base font-LatoRegular">Clear Filters</p>
        </div>
      </div>
      <div className="md:hidden mx-auto flex items-center justify-between">
        <div className="mb-[20px]">
          <FilterDropdownTwo
            selectedOption={optionSelect1.map((option) =>
              option.length > 14 ? option.slice(0, 14) + "..." : option
            )}
            // dropOptionsMV={optionOne}
            icon={dropVector}
          />
        </div>

        <p className="text-usafSecondary text-base  font-LatoRegular border border-usafSecondary px-8 py-1">
          Clear
        </p>
      </div>

      <ApplicationProfile />
      <div>
        <MobileApplicationProfile />
      </div>
    </Dashboard>
  );
};
