import env from "configs";
export const VMS_USER_DATA = "sakai_vms_user_data";
export const RESPONSE_SUCCESS = "SUCCESS";
export const RESPONSE_ERROR = "FAILURE";

export const LOGIN_SUCCESS_URL = "auth/login/success";
export const LOGIN = "auth/local/signin";
export const REGISTER = "auth/local/signup";
export const LOG_OUT = "auth/logout";
export const GOOGLE_END_POINT = `${env.API_BASE_URL}auth/google`;
export const LINKED_IN_END_POINT = `${env.API_BASE_URL}auth/linkedin`;

export const FORGOT_PASSWORD = "user/forgot-password";
export const RESET_PASSWORD = "user/reset-password";

/**
 * Get all jobs
 */

export const GET_JOBS = "/get-jobs";

//Request Status
