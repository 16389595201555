import React from "react";

const ToggleButton = ({ isChecked, handleToggle, id }) => {
  return (
    <div className="relative inline-block w-[30px] h-4">
      <input
        type="checkbox"
        id={id}
        className={`toggle-checkbox top-[1px] absolute appearance-none w-[16px] h-[16px] shadow-xl rounded-full bg-white ${
          isChecked ? "right-[1px]" : "left-[1px]"
        }`}
        checked={isChecked}
        onChange={handleToggle}
      />
      <label
        htmlFor={id}
        className={`toggle-label block overflow-hidden h-[18px] rounded-full cursor-pointer ${
          isChecked ? "bg-[#009D49]" : "bg-gray-300"
        }`}
      ></label>
    </div>
  );
};

export default ToggleButton;
