import HelpCenterView from "./HelpCenterView";
import { Landing } from "../../components";

export const HelpCenterContainer = () => {
  return (
    <Landing>
      <HelpCenterView />
    </Landing>
  );
};
