import PerfectVolunteerView from "./PerfectVolunteerView";
import { Landing } from "components";

export const PerfectVolunteer = () => {
  return (
    <Landing>
      <PerfectVolunteerView />
    </Landing>
  );
};
