import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import dot from "assets/svg/dot_ellipse.svg";
import ellipseBall from "assets/img/ellipse.png";
import style from "./style.module.css";
import { Button } from "components/widgets";
import JobContent from "pages/JobRoles/JobRolesCompScreen/Content";

const Content = () => {
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth"
  //   });
  // }, ['top'])

  return (
    <div id="top" className={style.hero_heading}>
      <div className={`xxs:mt-5 md:mt-0 ${style.content}`}>
        <h3 className={style.uiUx}>UI/UX Designer</h3>
        <h4 className={style.content_h4}>
          Remote{" "}
          <span>
            <img src={dot} alt="dot" className={style.dot} />
          </span>{" "}
          3 hours ago
        </h4>

        <div className={style.contactSperator}>
          <div className={` ${style.contactCarrier}`}>
            <div className={style.setOne}>
              <img src={ellipseBall} alt="ellipseBall" className={style.ellipseBall} />
              <div className={style.contactWrite}>
                <h2 className={style.contactWrite_h2}>United States Of Africa</h2>
                <h6 className={style.contactWrite_h6}>Social Services and Non Profit</h6>
                <h6 className={style.contactWrite_h6}>
                  Information technology and telecommunications
                </h6>
                <h6 className={style.contactWrite_h6}>50 - 100 Employees</h6>
              </div>
            </div>
          </div>

          <div className={`${style.contactCarrier}`}>
            <div className={style.setOne}>
              <img src={ellipseBall} alt="ellipseBall" className={style.ellipseBall} />
              <div className={style.contactWrite}>
                <h2 className={style.contactWrite_h2}>Daniel Kaluya</h2>
                <h6 className={style.contactWrite_h6}>Human Resource Manager</h6>
                <h6 className={style.contactWrite_h6}>HR Introtech Services</h6>
              </div>
            </div>
          </div>
        </div>

        <p className={style.usafRole}>
          This space contains description of the job and role. This space contains description of
          the job and role. This space contains description of the job and role. This space contains
          description of the job and role. This space contains description of the job and role. This
          space contains description of the job and role. This space contains description of the job
          and role.
        </p>

        <h5 className={style.content_h5}>Job Description</h5>
        <p className={style.paraOne_li}>
          <li>
            Assist in carrying out all aspects of the UX and Design process: visual design, concept
            development, user research & prototyping.{" "}
          </li>
          <li>
            Understanding user needs, business requirements, and technical capabilities and
            translating them into innovative product solutions.
          </li>
          <li>
            Define user experiences and interaction, creating site maps, wireframes, user flows, and
            high-fidelity prototypes.{" "}
          </li>
          <li>
            Work with Senior Product Designers to develop UI mockups and prototypes that clearly
            illustrate how sites functions and look.{" "}
          </li>
          <li>Identify and troubleshoot UX problems (i.e responsiveness). </li>
          <li>Illustrate design ideas using storyboards, process flows, and sitemaps. </li>
          <li>Design graphics user interfaces elements, like menus, tabs and widgets. </li>
          <li>Build page navigation buttons and search fields. </li>
          <li>Conduct layout adjustments based on user feedback. </li>
          <li>Adhere to style standards on fonts, colours, and images.</li>
        </p>

        <h5 className={style.content_h5}>Requirements</h5>
        <p className={style.paraTwo}>
          <li>First Degree in Design, Computer Science, or a relevant field. </li>
          <li>Excellent knowledge of wireframe tools (e.g wireframe.cc and In Vision,). </li>
          <li>Up-To-Date knowledge of design software like Adobe Illustrator and Photoshop. </li>
          <li>
            Team Spirit, strong communication skills to collaborate with various stakeholder.{" "}
          </li>
          <li>Good Time management skills. </li>
          <li>Having a good experience in industrial design, spatial design and 3D Design. </li>
          <li>Have a link to your portfolio in your CV.</li>
        </p>

        <h5 className={style.content_h5}>Benefits</h5>
        <p className={style.paraTwo}>
          <li>Medical Cover</li>
          <li>Flexible work schedule</li>
          <li>Profit Share</li>
          <li>Learning and development opportunity.</li>
        </p>

        <h5 className={style.content_h5}>Location & Schedule</h5>
        <p className={style.paraTwo}>
          The role is entirely Remote with a working hours range of 10-12 hours of Design Time from
          Mondays to Fridays.
        </p>

        <h5 className={style.content_h5}>About Company</h5>
        <p className={style.paraTwo}>
          USAF is ... This space contains details on the location and schedule of the job. This
          space contains details on the location and schedule of the job. This space contains
          details on the location and schedule of the job. This space contains details on the
          location and schedule of the job.
        </p>

        <div className={style.action_btn}>
          <Link to>
            <Button variant="full" size="lg" className="btn-actn text-[14px] font-bold mt-[7rem]">
              Apply Now
            </Button>
          </Link>
        </div>

        <div className={` ${style.similarJobs}`}>
          <h3 className={`xxs:text-left xxs:text-[30px] md:text-[40px] ${style.heading}`}>
            Jobs similar to this
          </h3>
          {/* <JobCardsComp /> */}
          <JobContent />
        </div>
      </div>
    </div>
  );
};

export default Content;
