export const FaqList = [
  {
    id: 1,
    question: "How does the volunteer management system work?",
    answer:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi, reprehenderit ipsam iusto ullam id quod modi rem labore ad, nobis cupiditate dolor. Illo amet earum corporis architecto enim, qui vero?"
  },
  {
    id: 2,
    question: "Do I need any prior experience to volunteer through this platform?",
    answer:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi, reprehenderit ipsam iusto ullam id quod modi rem labore ad, nobis cupiditate dolor. Illo amet earum corporis architecto enim, qui vero?"
  },
  {
    id: 3,
    question: " How do I apply for a volunteer role?",
    answer:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi, reprehenderit ipsam iusto ullam id quod modi rem labore ad, nobis cupiditate dolor. Illo amet earum corporis architecto enim, qui vero?"
  },
  {
    id: 4,
    question:
      "Is there any cost involved in signing up for this platform or applying for volunteer roles?",
    answer:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi, reprehenderit ipsam iusto ullam id quod modi rem labore ad, nobis cupiditate dolor. Illo amet earum corporis architecto enim, qui vero?"
  },
  {
    id: 5,
    question: "What kind of volunteer opportunities are available through this platform?",
    answer:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi, reprehenderit ipsam iusto ullam id quod modi rem labore ad, nobis cupiditate dolor. Illo amet earum corporis architecto enim, qui vero?"
  }
];
