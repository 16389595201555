import React from "react";
import jobsService from "services/job.service";
import { useEffect, useState } from "react";
import { JobCardsComp } from "components/modules/jobListingCardsComp/JobCardsComp";

function JobContent() {
  const [jobs, setJobs] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  useEffect(() => {
    jobsService.getAllJobs().then((data) => setJobs(data.response));
  }, []);

  useEffect(() => {
    jobsService.getAllJobs().then((data) => setDataLength(data.responseLength));
  }, []);

  let props = {
    jobs: jobs,
    jobsLength: dataLength
  };
  return (
    <div className="">
      <JobCardsComp {...props} />
    </div>
  );
}

export default JobContent;
