import React from "react";

import { VMSimages } from "../../../assets/index";

const NavigationComp = ({ description, textClass, iconClass, customStyles }) => {
  return (
    <div className={`${customStyles ? customStyles : "flex  items-center space-x-3"}`}>
      <span
        className={`${
          textClass ? textClass : "text-[14px] font-[700]"
        } text-secondary text-[14px] font-['MontserratSemiBold']`}
      >
        {description}
      </span>
      <span>
        <img
          src={VMSimages.vectorArrow}
          className={`${iconClass ? iconClass : "max-w-[14px]"} object-contain`}
          alt="direction"
        />
      </span>
    </div>
  );
};

export default NavigationComp;
