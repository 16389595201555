import React, { useState } from "react";
import { RiEyeOffFill, RiEyeFill } from "react-icons/ri";
import { Button } from "components/widgets";
import { Link } from "react-router-dom";
import { LOGIN } from "routes/CONSTANTS";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [repeatPasswordVisible, setRepeatPasswordVisible] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleRepeatPasswordVisibility = () => {
    setRepeatPasswordVisible(!repeatPasswordVisible);
  };
  const isPasswordValid = /^(?=.*[A-Z])(?=.*[.,;!-=+%%$^]).*$/.test(password);
  const hasUppercaseLetter = /[A-Z][a-z]/.test(password);
  const hasSpecialCharacter = /^(?=.*?[#?!@$%^&*-]).{8,}$/.test(password);
  const hasNumber = /(?=.*?[0-9])/.test(password);

  const getPasswordStrengthText = () => {
    if (password.length === 0) {
      return "";
    } else if (password.length < 6) {
      return "Weak";
    } else if (password.length < 8) {
      return "Fair";
    } else if (isPasswordValid && password.length >= 8) {
      return "Strong";
    }
  };
  const getPasswordStrengthWidth = () => {
    const strength = getPasswordStrengthText();
    if (strength === "Weak") {
      return "25%";
    } else if (strength === "Fair") {
      return "50%";
    } else if (strength === "Strong") {
      return "100%";
    } else {
      return "0%";
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordMatch(e.target.value === password);
  };

  const handleSecurityCodeChange = (e) => {
    setSecurityCode(e.target.value);
  };

  const isFormValid = password === confirmPassword && isPasswordValid && securityCode.length > 0;

  return (
    <div className="px-20">
      <div className="mt-8">
        <h1 className="text-textmd pb-2 "> Enter New Password</h1>
        <div className="w-full border flex rounded-[8px] focus-within:border-usafBlack">
          <input
            type={passwordVisible ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            className="w-full p-3 outline-none rounded-[8px] bg-white"
          />
          <button onClick={togglePasswordVisibility} className="ml-2">
            {passwordVisible ? (
              <RiEyeFill className="text-orange-500 mr-2" />
            ) : (
              <RiEyeOffFill className="text-orange-500 mr-2" />
            )}
          </button>
        </div>

        <div>
          <section className="progress_bar flex items-center gap-5">
            <p className="text-textxs pt-2 pb-2">password strength</p>

            <div class="border w-[150px] bg-white-200 rounded-full dark:bg-gray-700">
              <div
                className="h-2 rounded-full transition-all"
                style={{
                  width: getPasswordStrengthWidth(),
                  background:
                    getPasswordStrengthText() === "Weak"
                      ? "red"
                      : getPasswordStrengthText() === "Fair"
                      ? "yellow"
                      : "green"
                }}
              ></div>
            </div>
            <p className="text-sm font-medium">{getPasswordStrengthText()}</p>
          </section>
          <section className="flex items-start gap-12 mb-6">
            <label className="flex items-center gap-2 text-textxxs cursor-pointer">
              <input type="radio" checked={hasUppercaseLetter} disabled={!hasUppercaseLetter} />
              contains at least one uppercase letter.
            </label>
            <label className="flex items-center gap-2 text-textxxs cursor-pointer">
              <input type="radio" checked={hasNumber} disabled={!hasNumber} />
              contains at least one number.
            </label>
            <label className="flex items-center gap-2 text-textxxs cursor-pointer">
              <input type="radio" checked={hasSpecialCharacter} disabled={!hasSpecialCharacter} />
              contains at least one special character /.,;!-=+%%$^
            </label>
          </section>
        </div>
      </div>

      <div className="pb-5">
        <h1 className="text-textmd pb-2">Repeat New Password</h1>
        <div className="w-full flex rounded-[8px] border focus-within:border-usafBlack">
          <input
            type={repeatPasswordVisible ? "text" : "password"}
            className="w-full p-3 outline-none rounded-[8px] bg-white"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <button onClick={toggleRepeatPasswordVisibility} className="ml-2">
            {repeatPasswordVisible ? (
              <RiEyeFill className="text-orange-500 mr-2" />
            ) : (
              <RiEyeOffFill className="text-orange-500 mr-2" />
            )}
          </button>
        </div>

        {!passwordMatch && <p className="text-usafRed text-textsm">passwords do not match.</p>}
      </div>
      <div>
        <h1 className="text-textmd pb-2">Enter Security Code</h1>
        <div className="w-full border flex rounded-[8px] focus-within:border-usafBlack">
          <input
            type="text"
            placeholder="123 - 567"
            className="w-full p-3 outline-none rounded-[8px] bg-white"
            value={securityCode}
            onChange={handleSecurityCodeChange}
          />
        </div>
      </div>
      <div className="flex justify-center w-full">
        <Link to={LOGIN}>
          <Button
            type="submit"
            variant="full"
            size="md"
            className="flex flex-wrap text-[12px] m-10 font-bold items-center cursor-pointer"
            disabled={!isFormValid}
          >
            Save Password
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ChangePassword;
