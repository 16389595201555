import React from "react";

const Cards = () => {
  return (
    <div>
      <div className="grid md:grid-cols-4 grid-cols-2 gap-4 mt-4 mb-6 ">
        <div className="bg-usafPrimary3 rounded-2xl">
          <p className="text-usafPrimary8 md:block hidden font-LatoRegular text-textH6 px-2 pt-4 text-center">
            Successful Application
          </p>
          <p className="text-usafPrimary8 md:hidden  font-LatoRegular text-textH6 px-2 pt-4 text-center">
            Successful
          </p>
          <p className="text-usafPrimary8 font-extrabold font-SpectralRegular text-[80px] md:text-[96px] text-center">
            1
          </p>
        </div>
        <div className="bg-usafPrimary3  text-center rounded-2xl">
          <p className="text-usafPrimary8 font-LatoRegular  px-2 pt-4 text-textH6 md:p-2 text-center">
            In Review
          </p>
          <p className="text-usafPrimary8 font-extrabold font-SpectralRegular text-[80px] md:text-[96px] text-center">
            3
          </p>
        </div>
        <div className="bg-usafPrimary3  text-center rounded-2xl">
          <p className="text-usafPrimary8 md:block hidden font-LatoRegular text-textH6 px-2 pt-4 text-center">
            Rejected Application
          </p>
          <p className="text-usafPrimary8 md:hidden  font-LatoRegular text-textH6 px-2 pt-4 text-center">
            Rejected
          </p>
          <p className="text-usafPrimary8 font-extrabold font-SpectralRegular text-[80px] md:text-[96px] text-center">
            16
          </p>
        </div>
        <div className="bg-usafPrimary3  text-center rounded-2xl">
          <p className="text-usafPrimary8 md:block hidden font-LatoRegular text-textH6 px-2 pt-4 text-center">
            Total Application
          </p>
          <p className="text-usafPrimary8 md:hidden  font-LatoRegular text-textH6 px-2 pt-4 text-center">
            Total
          </p>
          <p className="text-usafPrimary8 font-extrabold font-SpectralRegular text-[80px] md:text-[96px] text-center">
            20
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cards;
