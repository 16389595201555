import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import flagFrance from "assets/svg/flag_france.svg";
import flagGerman from "assets/svg/flag_german.svg";
import flagUnitedKingdom from "assets/svg/flag_united_kingdom.svg";
import dropToggle from "assets/svg/toggleVector.svg";
import { AccoordionDp } from "components";
import { useAppDispatch } from "hooks";
import {
  LanguageFormItem,
  PrimaryDropdown,
  DropdownButton
} from "components/widgets/LanguageSelect";
import { logout } from "redux/slices/auth";
import {
  ABOUT_ME,
  // HOME,
  LOGIN,
  SIGNUP,
  // PROFILE,
  JOB_ROLES,
  LOGOUT
} from "../../../routes/CONSTANTS";
import { VMSimages } from "../../../assets";
import { Button } from "../../widgets";

export const OnJobRolesPage = () => {
  const Navigate = useNavigate();

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-8">
        <img
          src={VMSimages.profileIcon}
          className="object-contain max-w-[56px] cursor-pointer"
          alt="user_profile"
          onClick={() => Navigate(ABOUT_ME)}
        />
        <h4 className="font-['Spectral'] text-[15px] text-usafPrimary font-bold">Hi Jane</h4>
      </div>

      <div>
        <Link to={ABOUT_ME}>
          <Button type="text" className="bg-usafPrimary" size="fill">
            View profile
          </Button>
        </Link>
      </div>
    </div>
  );
};

export const OnJobRolesPageFilter = () => {
  return (
    <div className="relative flex flex-col z-50 w-full right-0 lg:flex-row xxs:block bg md:hidden">
      <p className="text-usafPrimary text-[26px] font-[400] font-SpectralBold p-[0.5rem] ">
        Filters
      </p>
      <div className="items-center">
        <AccoordionDp />
      </div>
    </div>
  );
};

export const LoginSignUp = () => {
  return (
    <div className="flex flex-col gap-2 py-2">
      <Link to={LOGIN}>
        <Button type="text" className="bg-usafWhite border-2 border-usafPrimary" size="fill">
          <span className="text-primary">Log In</span>
        </Button>
      </Link>
      <Link to={SIGNUP}>
        <Button type="text" className="" size="fill">
          Sign Up
        </Button>
      </Link>
    </div>
  );
};

export const LogOutViewJobs = () => {
  const dispatch = useAppDispatch();
  const handleLogout = dispatch(logout());
  return (
    <div className="flex flex-col gap-2 py-2">
      <Link to={LOGOUT}>
        <Button
          type="text"
          className="bg-usafWhite border-2 border-usafPrimary"
          size="fill"
          // onClick={handleLogout}
        >
          <span className="text-primary">Logout</span>
        </Button>
      </Link>
      <Link to={JOB_ROLES}>
        <Button type="text" className="bg-usafPrimary border-2 border-usafPrimary" size="fill">
          <span className="text-usafWhite">Visit job listing page</span>
        </Button>
      </Link>
    </div>
  );
};

const NavSideComp = () => {
  return (
    <div>
      <OnJobRolesPage />
      <OnJobRolesPageFilter />
      <LoginSignUp />
      <LogOutViewJobs />
    </div>
  );
};
export default NavSideComp;

export const MobileViewLanguageForm = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const handleChange = useCallback((e) => {
    setSelectedLanguage(e.target.value);
  }, []);
  const getImageForLanguage = useCallback((language) => {
    switch (language) {
      case "English":
        return flagUnitedKingdom;

      case "Francais":
        return flagFrance;

      case "Deutch":
        return flagGerman;

      default:
        return "";
    }
  }, []);

  return (
    <PrimaryDropdown
      button={
        <DropdownButton className="justify-center bg items-center gap-1 min-w-max">
          <img className="w-8 h-8" src={getImageForLanguage(selectedLanguage)} alt="" />
          <img src={dropToggle} alt="dropToggle" className="w-3 h-3 dropToggle" />
        </DropdownButton>
      }
      isLeft={false}
    >
      <LanguageFormItem
        flagImageUrl={flagUnitedKingdom}
        language="English"
        isDefaultChecked={true}
        handleChange={handleChange}
      />

      <LanguageFormItem flagImageUrl={flagFrance} language="Francais" handleChange={handleChange} />

      <LanguageFormItem flagImageUrl={flagGerman} language="Deutch" handleChange={handleChange} />
    </PrimaryDropdown>
  );
};
