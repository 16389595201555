import React, { useState } from "react";
import { VMSimages } from "../../../../../assets";
import { Modal } from "../../../../../components";
import AddEducation from "./AddEducation";

const Education = ({ ...data }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="bg-white rounded-[14px] p-4 mb-5 shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h4>Education</h4>
        <label className="cursor-pointer" onClick={() => setOpenModal(true)}>
          <img src={VMSimages.edit_pen} className="object-contain max-w-[20px]" alt="" />
        </label>
      </div>
      <div className="w-full bg-[#004B9D] rounded-[10px] p-5 min-h-[130px]">
        {data === null ? (
          <div className="">hi</div>
        ) : (
          <div className="flex items-center justify-center min-h-[110px]">
            <h6 className="font-['Lato'] text-white text-[16px]">
              You have not added any Educational history
            </h6>
          </div>
        )}
      </div>
      {openModal && (
        <Modal
          title="Education"
          content={<AddEducation title="education" />}
          setOpenModal={() => setOpenModal(null)}
        />
      )}
    </div>
  );
};

export default Education;
