import ConnectWithSaiKaiView from "./ConnectWithSaiKaiView";
import { Landing } from "components";

export const ConnectWithSaiKaiContainer = () => {
  return (
    <Landing>
      <ConnectWithSaiKaiView />
    </Landing>
  );
};
