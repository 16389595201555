import HomeView from "./HomeView";
import { Landing } from "components";

export const HomeContainer = () => {
  return (
    <Landing>
      <HomeView />
    </Landing>
  );
};
