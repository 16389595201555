import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "components";
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";

function AppDeletePrompt({ showCancelPop, deletePopUp, hideDeletePop }) {
  // delete prompt
  const [deletePop, setDeletePop] = useState("");
  const showDeletePop = (e) => {
    setDeletePop(openCancelPop);
    e.preventDefault();
  };

  function handleDelete(e) {
    if (!(validateYupSchema.length === 0)) {
      e.preventDefault();
      // props.close("hidden")
      setDeletePop(!closeCancelPop);
      window.location.reload();
    }
  }

  let openCancelPop = {
    visibility: "visible",
    position: "fixed",
    margin: "0 auto",
    bottom: "7rem",
    left: "2rem",
    right: "2rem"
  };

  let closeCancelPop = {
    visibility: "hidden",
    position: "fixed"
  };

  const cancelPopStyle = deletePop ? openCancelPop : closeCancelPop;

  const style = {
    ...cancelPopStyle,
    ...showCancelPop
  };

  return (
    <div>
      <div
        className="delete_pop_body max-md:h-[420px] w-fit xxs:p-5 md:p-20 m-auto mt-[5rem] items-center justify-center text-center 
        bg-[#F5F5F5] border-[1.5px] border-[#CCCCCC] rounded-[16px] drop-shadow-[0_5px_15px_rgba(0,0,0,0.45)] z-10"
        style={style}
      >
        <div
          className={`text-center justify-center ${deletePopUp} ${deletePop} xxs:mt-1 md:mt-0`}
          id="popHead"
        >
          <h1
            className="text-[#000] font-SpectralBold font-[600] xxs:text-[20px]
            xs:text-[24px] xs:leading-[30px] md:text-[28px] md:leading-[50px]"
          >
            Are You sure You Want To Delete This Application?
          </h1>
          <div className="deleteInfo text-center justify-center pt-5">
            <center>
              <p className="text-[#666666] text-center justify-center font-SpectralBold font-[600] text-[18px] leading-[30px]">
                Please Press the “Yes, Delete” button below to delete the application.
                <br />
                <center>or</center>
                Press “No, Cancel” button to cancel the deleting process.
              </p>
            </center>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-around gap-4 xxs:pt-2 xs:pt-14 md:pt-16">
            <Link className="md:order-1 xxs:order-2">
              <button
                className="border-[1.5px] border-secondary-100 bg-white text-secondary-100  
                rounded-[4px] py-2 font-['Montserrat'] font-[600] text-[12px] xxs:px-[75px] md:px-[50px] h-12"
                onClick={hideDeletePop}
              >
                No, Cancel
              </button>
            </Link>

            <Link className="md:order-2 xxs:order-1">
              <Button
                onClick={handleDelete}
                type="submit"
                variant="full"
                size="md"
                className="text-[12px] xxs:px-[75px] md:px-[50px]"
              >
                Yes, Cancel
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppDeletePrompt;
