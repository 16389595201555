import { VMSBugs, VMSCounterClock, VMSGear, VMSHeadset } from "../components";

export const customerCardDetails = [
  {
    id: 1,
    Icon: VMSCounterClock,
    title: "How to Reset Your Sa Kai Password",
    subTitle:
      "This article provides step-by-step instructions for resetting a password on the volunteer management website. You can follow the instructions provided to regain access to your account."
  },
  {
    id: 2,
    Icon: VMSGear,
    title: "Troubleshooting Common  Issues",
    subTitle:
      "This article provides guidance for when you are experiencing common issues with the website. By following the troubleshooting steps outlined in the article, you can resolve common issues and continue using the website."
  },
  {
    id: 3,
    Icon: VMSHeadset,
    title: "Contacting Sa Kai Support",
    subTitle:
      "This article provides you with information on how to contact customer support for the volunteer management website. You can reach out to customer support for assistance with any issues you may be experiencing while using the website."
  },
  {
    id: 4,
    Icon: VMSBugs,
    title: "Reporting Sai Kai Bugs or Issues",
    subTitle:
      "This article provides guidance on how to report bugs or issues with the website. By submitting a support ticket or contacting customer support, you can help the development team identify and resolve issues with the website."
  }
];
