import React from "react";
import { VMSimages } from "assets";

const ControlWork = () => {
  return (
    <div className="px-2 md:px-5 xl:px-9 py-10 grid grid-cols-1 gap-3 lg:grid-cols-2 bg-white mb-2">
      <div className="flex flex-col self-center items-start max-w-screen-md xxs:items-start lg:items-start lg:order-1 xxs:order-2">
        <h1
          className="font-['Spectral_Bold'] max-w-[650px] text-left xxs:text-left md:text-left lg:text-left text-[24px] lg:text-[35px] 
          xl:text-[40px] text-[#004B9D] font-bold leading-[40px] lg:leading-[65px] xl:leading-[65px]"
        >
          Control the Way you Work
        </h1>

        <p
          className="mb-12 mt-5 font-['Lato'] text-[16px] lg:text-[18px] xl:text-[18px] text-[#666666] leading-[30px] lg:leading-[35px]
          text-left md:text-center self-center lg:text-left max-w-[1000px]"
        >
          With our volunteer management system, you're in control of your dashboard. Customize your
          working hours, availability preferences and other important information that makes it
          seamless for you to contribute positively to your community. With everything in one place,
          you can manage the way you work and how best it suits you.
        </p>
      </div>

      <div
        className="flex flex-col xxs:-mt-6 md:mt-0 justify-center max-w-[100%] items-end md:items-center lg:items-end 
        xxs:items-center xxs:justify-center lg:order-2 xxs:order-1"
      >
        <img
          src={VMSimages.control_work}
          className="object-cover lg:max-h-[450px] md:max-h-[400px] lg:max-w-full md:max-w-full w-full"
          alt="usa_hands"
        />
      </div>
    </div>
  );
};

export default ControlWork;
