import { useState } from "react";
import { Button } from "components";

const Modals = ({ content, title, breadCrumbsLinks, ...otherProps }) => {
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
    window.location.reload(true);
  };

  return (
    <div>
      {!openModal && (
        <div
          className="w-full h-screen fixed inset-0 flex items-center justify-center p-5 z-50"
          {...otherProps}
        >
          <div className="relative xxs:w-[400px] md:w-[900px] bg-usafWhiteBg rounded-[15px] z-50">
            {title && (
              <div
                className="w-full h-[50px]   items-center justify-center px-5 py-2 border-b-2 
                                border-b-gray-200 bg-[#001E3F] rounded-t-[15px]"
              >
                <p className="text-white text-center font-['Spectral'] text-[24px]">{title}</p>
              </div>
            )}
            <div className="w-full min-h-0  max-h-[80vh] overflow-y-auto">{content}</div>
            <div className="flex justify-center mb-4">
              <Button size="lg" onClick={toggleModal}>
                close
              </Button>
            </div>
          </div>
          <div onClick={toggleModal} className="fixed inset-0 bg-black/70 z-10"></div>
        </div>
      )}
    </div>
  );
};

export default Modals;
