import React from "react";

const StaticStepper = () => {
  return (
    <div className="container horizontal md:w-[62rem] mt-5 mx-auto p-4 flex justify-between items-center">
      <div className="w-full flex items-center justify-center">
        {/* number */}
        <div className="relative flex flex-col items-center text-#007E3A mx-auto">
          <div
            className="rounded-full border-[3.5px] border-[#007E3A] h-11 w-11 flex items-center justify-center py-3 
            text-[#007E3A] font-bold text-xl"
          >
            1
          </div>
        </div>

        {/* line */}
        <div className={`flex-auto border-t-[3.5px] border-[#007E3A]`}></div>

        {/* number */}
        <div className="relative flex flex-col items-center text-[#007E3A] mx-auto">
          <div
            className="rounded-full border-[3.5px] border-[#007E3A] h-11 w-11 flex items-center justify-center py-3 
            text-[#007E3A] font-bold text-xl"
          >
            2
          </div>
        </div>

        {/* line */}
        <div className={`flex-auto border-t-[3.5px] border-gray-100`}></div>

        {/* number */}
        <div className="relative flex flex-col items-center mx-auto">
          <div
            className="rounded-full border-[3.5px] border-gray-100 h-11 w-11 flex items-center justify-center py-3 
            text-gray-100 font-bold text-xl"
          >
            3
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticStepper;
