import { VMSCase, VMSEmployeeSearch, VMSLaunch, VMSQuestionMark } from "../components";

export const helpCardDetails = [
  {
    id: 1,
    Icon: VMSLaunch,
    title: "Getting Started with Volunteer Management",
    subTitle:
      "This article provides guidance for you on how to get started with the volunteer management website. By following the steps outlined in the article, you can create an account and set up a volunteer profile."
  },
  {
    id: 2,
    Icon: VMSEmployeeSearch,
    title: "Finding Volunteer Opportunities Near You",
    subTitle:
      "This article provides you with tips and guidance on how to search for volunteer opportunities in your local area. By following the search tips provided, you can find relevant volunteer opportunities."
  },
  {
    id: 3,
    Icon: VMSCase,
    title: "Getting Started with Job Applications",
    subTitle:
      "This article provides guidance for you on how to get started with job application on the website. By following the steps outlined in the article, you can apply for any job of your choice without experience any difficulties."
  },
  {
    id: 4,
    Icon: VMSQuestionMark,
    title: "Frequently Asked Questions About Volunteer Management",
    subTitle:
      "This article provides you with answers to common questions about using our website. By reading through the FAQs provided, you can find answers to  questions and troubleshoot issues you may be experiencing with the website,"
  }
];
