import React from "react";
import { useState, useRef, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "semantic-ui-css/semantic.css";
import AppDeletePrompt from "../AppProcessComps/AppDeletePrompt";
import { BreadCrumbs, Button, FormInput, Loader, FormSelect } from "components";
import StaticStepper from "./StaticStepper";
import Rate from "components/widgets/Ratings/Rate";
import { tech_languages } from "utils/tech_languages";

export const WorkExperience = ({ loading, ...props }) => {
  const expYrsOptions = [
    {
      value: "0",
      label: "0"
    },
    {
      value: "1-2 years",
      label: "1-2 years"
    },
    {
      value: "2-3 years",
      label: "2-3 years"
    },
    {
      value: "3 & above years",
      label: "3 & above years"
    }
  ];

  const openModal2 = () => {
    props.setModal1();
    props.setModal2();
  };

  const AboutYourSelfLink = () => {
    props.setModal1();
    props.breadCrumbAYS();
  };

  const breadCrumbsLinks = [
    {
      id: 1,
      link: "Application Page I",
      onClick: AboutYourSelfLink,
      direction: false
    },
    {
      id: 2,
      link: "Application Page II",
      direction: true
    }
  ];

  //mobile view
  const breadCrumbsLinksMV = [
    {
      id: 1,
      link: "Application Page I",
      onClick: AboutYourSelfLink,
      back_direction: true
    }
  ];

  // formik validation
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      linkedin: "",
      phoneNumber: "",
      howyouheard: ""
    },

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      linkedin: Yup.string()
        .required("LinkedIn is required")
        .matches(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
          "LinkedIn must in a valid format"
        ),
      phoneNumber: Yup.string()
        .required("Phonenumber is required")
        .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Phone must be in a valid format")
    })
  });

  //rating
  const [ratng, setRatng] = useState(0); // initial rating value
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    // Some logic
  };

  const [rating, setRating] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [rating3, setRating3] = useState(0);
  const [rating4, setRating4] = useState(0);

  const Navigate = useNavigate();

  function handleFormSubmit(e) {
    e.preventDefault();
    openModal2(e);
  }

  // delete prompt
  const [deletePop, setDeletePop] = useState("");
  const showDeletePop = (e) => {
    setDeletePop(openCancelPop);
    e.preventDefault();
  };

  const hideDeletePop = (e) => {
    setDeletePop(!closeCancelPop);
    e.preventDefault();
  };

  let openCancelPop = {
    visibility: "visible",
    position: "fixed",
    margin: "0 auto",
    bottom: "7rem",
    left: "2rem",
    right: "2rem"
  };

  let closeCancelPop = {
    visibility: "hidden",
    position: "fixed"
  };

  const cancelPopStyle = deletePop ? openCancelPop : closeCancelPop;

  return (
    <div className="bg-usafWhite h-[44rem] overflow-hidden rounded-b-[15px]">
      <div className="hidden md:block">
        <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} />
      </div>

      <div className="block md:hidden">
        <BreadCrumbs breadCrumbsLinks={breadCrumbsLinksMV} />
      </div>

      <StaticStepper />

      <h2
        className="font-['Spectral_Semi_Bold'] text-[#000000] text-center xxs:text-[28px] 
        md:text-[32px] font-[600] my-2 mt-[2rem] leading-10"
      >
        Tell Us About Your Experience
      </h2>
      <h3 className="font-LatoBold text-[#666666] text-center xxs:text-[18px] md:text-[20px] font-[400] mb-1 leading-[180%]">
        Please provide all Information required in the form below
      </h3>

      <div
        className="xxs:w-[23rem] xs:w-[25rem] md:w-full px-8 py-10 bg-white rounded-lg shadow-sm m-auto max-w-[65rem] 
        space-y-6 h-[26rem] overflow-y-auto"
      >
        <form onSubmit={formik.handleSubmit} className="m-auto max-w-[59rem] space-y-6">
          <div className="mt-3">
            <label className="leading-[30px] justify-start flex" htmlFor="yrsOfExp">
              Years Of Experience In UI/UX Design
            </label>
            <div className="text-left justify-items-start">
              <FormSelect
                size="lg"
                name="yrsOfExp"
                id="yrsOfExp"
                placeholder="Select years"
                options={expYrsOptions}
              />
            </div>
          </div>

          <div className="hidden md:block" style={{ marginTop: "2rem" }}>
            <label className="leading-[40px] justify-start flex" htmlFor="list">
              Required Skills / Language
            </label>
            <div
              className="h-[4.5rem] text-base bg-[#F5F5F5] border-[1px] border-[#CCCCCC] 
              rounded-[6px] focus:border-[#004B9D] content-center outline-none"
            >
              <div className="flex list-none">
                <li className="bg-[#004B9D] text-white leading-[180%] text-[12px] rounded-[8px] p-3 m-[0.6rem]">
                  User Experience Design
                </li>
                <li className="bg-[#004B9D] text-white leading-[180%] text-[12px] rounded-[8px] p-3 m-[0.6rem]">
                  Figma
                </li>
                <li className="bg-[#004B9D] text-white leading-[180%] text-[12px] rounded-[8px] p-3 m-[0.6rem]">
                  User Interface Design
                </li>
              </div>
            </div>
          </div>

          <div className="hidden md:block" style={{ marginTop: "2rem" }}>
            <label className="leading-[40px] justify-start flex" htmlFor="rating">
              Rate Your Skill Proficiency
            </label>
            <div
              className="flex h-[4.5rem] items-center justify-between bg-white border-2 border-[#999999] 
              rounded-[6px] px-2 py-5 mb-4"
            >
              <li className="list-none">User Experience Design</li>
              <div className="flex items-center justify-between">
                <Rate rating={rating} onRating={(rate) => setRating(rate)} count={10} />
                <button className="w-[32px] ml-2 h-[32px] text-white bg-[#004B9D] rounded-[3px] justify-end">
                  {rating}
                </button>
              </div>
            </div>

            <div
              className="flex h-[4.5rem] items-center justify-between bg-white border-2 border-[#999999] 
              rounded-[6px] px-2 py-5 mb-4"
            >
              <li className="list-none">Figma</li>
              <div className="flex items-center justify-between">
                <Rate rating={rating2} onRating={(rate) => setRating2(rate)} count={10} />
                <button className="w-[32px] ml-2 h-[32px] text-white bg-[#004B9D] rounded-[3px] justify-end">
                  {rating2}
                </button>
              </div>
            </div>

            <div
              className="flex h-[4.5rem] items-center justify-between bg-white border-2 border-[#999999] 
              rounded-[6px] px-2 py-5 mb-4"
            >
              <li className="list-none">User Interface Design</li>
              <div className="flex items-center justify-between">
                <Rate rating={rating3} onRating={(rate) => setRating3(rate)} count={10} />
                <button className="w-[32px] ml-2 h-[32px] text-white bg-[#004B9D] rounded-[3px] justify-end">
                  {rating3}
                </button>
              </div>
            </div>
          </div>

          <div className="mt-3" style={{ marginTop: "2rem" }}>
            <label className="leading-[30px] flex justify-start" htmlFor="skills">
              Additional Skills / Language
            </label>
            <div className="text-left justify-items-start">
              <FormSelect
                size="lg"
                name="relatedSkills"
                id="relatedSkills"
                isMulti
                options={tech_languages}
                placeholder="Search by Skills, Language or Frameworks....."
              />
            </div>
          </div>

          <div className="hidden md:block" style={{ marginTop: "2rem" }}>
            <label className="leading-[40px] flex justify-start" htmlFor="rating">
              Rate Your Skill Proficiency
            </label>
            <div
              className="flex h-[4.5rem] items-center justify-between bg-white border-2 border-[#999999] 
              rounded-[6px] px-2 py-5 mb-4"
            >
              <li className="list-none">Graphic Designer</li>
              <div className="flex items-center justify-between">
                <Rate rating={rating4} onRating={(rate) => setRating4(rate)} count={10} />
                <button className="w-[32px] ml-2 h-[32px] text-white bg-[#004B9D] rounded-[3px] justify-end">
                  {rating4}
                </button>
              </div>
            </div>
          </div>

          <div className="mt-3" style={{ marginTop: "2rem" }}>
            <FormInput
              size="lg"
              type="text"
              name="portfolioLink"
              id="portfolioLink"
              placeholder="http://www.portfolio.com"
              className="w-full text-left justify-start flex"
              value={formik.values.portfolioLink}
              onChange={formik.handleChange}
              errors={formik.errors.portfolioLink}
              touched={formik.touched.portfolioLink}
              label="Personal Website / Portfolio Link (Behance,Dribbble e.t.c) / Github"
            />
          </div>

          <div className="mt-3" style={{ marginTop: "2rem" }}>
            <label className="leading-[30px] flex justify-start" htmlFor="name">
              Do You Have Experience as a Volunteer?
            </label>
            <div className="flex justify-between">
              <Link>
                <button
                  className="border-[1.5px] md:border-[#004B9D] md:bg-[#004B9D] md:text-white
                  border-[#004B9D] bg-white text-[#004B9D] rounded-[4px] py-2 font-['Montserrat'] 
                  font-[600] text-[14px] px-[55px] md:px-[80px] h-14 hover:bg-[#003C7E] hover:text-white"
                >
                  Yes
                </button>
              </Link>
              <Link>
                <button
                  className="border-[1.5px] md:border-[#004B9D] md:bg-[#004B9D] md:text-white
                  border-[#004B9D] bg-white text-[#004B9D] rounded-[4px] py-2 font-['Montserrat'] 
                  font-[600] text-[14px] px-[55px] md:px-[80px] h-14 hover:bg-[#003C7E] hover:text-white"
                >
                  No
                </button>
              </Link>
            </div>
          </div>

          <div className="mt-3 mb-2" style={{ marginTop: "2rem" }}>
            <label className="leading-[30px] justify-start text-left flex" htmlFor="yrsOfExp">
              How many years of experience do you have as a volunteer?
            </label>
            <div className="text-left justify-items-start">
              <FormSelect
                size="lg"
                name="yrsOfExp"
                id="yrsOfExp"
                placeholder="Select years"
                options={expYrsOptions}
              />
            </div>
          </div>

          <div className="w-full px-0 cursor-pointer mt-0" style={{ marginTop: "2rem" }}>
            <div className="flex justify-between w-full">
              <label
                className="leading-[25px] md:leading-[40px] flex text-left justify-start"
                htmlFor="exp"
              >
                Briefly describe your experience as a volunteer
              </label>
              <p
                className="w-[50%] xxs:mt-6 md:mt-0 text-right text-[#009D49] font-LatoBold font-[400] text-[12px] 
                leading-[25px] md:leading-[40px]"
              >
                0/500 words
              </p>
            </div>

            <textarea
              className="flex px-7 h-[4.5rem] relative text-base bg-white placeholder:text-[#8692A6] 
              border-[1.5px] border-[#333333] rounded-[6px] focus:border-[#004B9D] leading-[180%] pt-3 font-LatoBold font-[400]
              content-center justify-between items-center outline-none"
              placeholder="please tell us more..."
              style={{ width: "100%" }}
            ></textarea>
          </div>

          <AppDeletePrompt
            showCancelPop={cancelPopStyle}
            deletePopUp={deletePop}
            hideDeletePop={hideDeletePop}
          />

          <div className="flex flex-col md:flex-row items-center justify-around gap-4 py-6 xxs:pb-12 md:pb-2">
            <Link className="md:order-1 xxs:order-2">
              <button
                className="border-[1.5px] border-secondary-100 bg-white text-secondary-100  
                rounded-[4px] py-2 font-['Montserrat'] font-[600] text-[12px] xxs:px-[50px] xs:px-[70px] h-14"
                onClick={showDeletePop}
              >
                Cancel Application
              </button>
            </Link>

            <Link className="md:order-2 xxs:order-1">
              <Button
                onClick={handleFormSubmit}
                type="submit"
                variant="full"
                size="lg"
                className="text-[12px] xxs:px-[50px] xs:px-[70px]"
              >
                {loading ? <Loader /> : "Continue Application"}
              </Button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
