import SaiKaiTasksView from "./SaiKaiTasksView";
import { Landing } from "components";

export const SaiKaiTasksContainer = () => {
  return (
    <Landing>
      <SaiKaiTasksView />
    </Landing>
  );
};
