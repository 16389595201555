import React, { useEffect } from "react";
import { VMSimages } from "../../../assets";
import { VMSSearch } from "../../icons";
// import SearchIconBroken from "../../svgs/SearchIconBroken";
// import { debounce } from "../../utils/helpers";
// import Loading from "../Loading";
// import "./SearchBox.scss";

// convert url encoded string to string
const urlDecode = (str) => decodeURIComponent(str);

const SearchBox = ({
  name,
  value,
  placeholder,
  size,
  className,
  label,
  id,
  optional,
  required,
  verticalBar,
  iconSize,
  handleChange = () => {},
  loading
}) => {
  const [searchValue, setSearchValue] = React.useState(value);

  const getSize = (size) => {
    switch (size) {
      case "sm":
        return "px-2 h-10";
      case "md":
        return "px-4 h-12";
      case "lg":
        return "px-8 h-16";

      default:
        return "p-1 h-10";
    }
  };

  //   useEffect(() => {
  //     setSearchValue(value);
  //   }, [value]);

  const customHandleChange = (e) => {
    setSearchValue(e.target.value);
    // handleChange(e);
    // debounce handle change
    // const debouncedHandleChange = debounce(handleChange, 500);
    // debouncedHandleChange(e);
  };
  return (
    <div className="space-y-1 w-full">
      <div className="flex items-center justify-between">
        {label !== undefined && (
          <label
            htmlFor={id}
            className={`block ${
              size === "base" ? "text-[12px]" : "text-lg"
            } text-[#000000] font-['Spectral'] mb-[6px]`}
          >
            {label}
          </label>
        )}
        {optional && <small className="text-[#009D49] text-[10px]">Optional</small>}
        {required && <small className="text-red-700 text-[10px]">Required</small>}
      </div>
      <div className="relative w-full">
        {/* {verticalBar && (
          <img
            className={`${
              size === "sm" && "max-w-[3px] top-[8px] left-[15px]"
            } object-contain absolute top-3 left-6`}
            src={VMSimages.input_bar}
            alt=""
          />
        )} */}
        <input
          type="text"
          placeholder={placeholder || "Search..."}
          name={name}
          className={`${getSize(size)} ${className} ${
            size === "sm" && "pl-[20px]"
          } text-base bg-white 
          placeholder:text-[#8692A6] border-[1.5px] border-[#333333] rounded-[6px] focus:border-[#004B9D] outline-none`}
          value={urlDecode(searchValue || "")}
          onChange={customHandleChange}
        />
        <div className="absolute inset-y-0 right-3 pl-2 flex items-center pointer-events-none">
          <VMSSearch size={iconSize || 0} />
        </div>
        {/* {loading && <Loading />} */}
      </div>
    </div>
  );
};

export default SearchBox;
