import { VMS_USER_DATA } from "./constants";

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem(VMS_USER_DATA));

  if (user && user.accessToken) {
    return { Authorization: "Bearer " + user.accessToken };
  } else {
    return {};
  }
}
