import VMSArrowDownIcon from "components/icons/VMSArrowDownIcon";
import VMSArrowUpIcon from "components/icons/VMSArrowUpIcon";
import React, { useState } from "react";
import MobileReview from "./MobileReview";

const MobileApplicationStatus = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      <div className="border-2 shadow-sm rounded-md border-usafBlack4 m-4 p-2 font-LatoRegular">
        <p className="font-SpectralBold text-usafBlack font-semibold text-[20px]">UI/UX Design</p>
        <div className="flex justify-between items-center">
          <p className="text-usafBlack text-[15px]">Jane Doe</p>
          <p className="text-usafBlack text-[15px]">22B/01/AJ</p>
        </div>

        <div className="flex justify-between items-center">
          <p className="text-usafBlack text-[15px]">12:09 PM</p>
          <p className="text-usafBlack text-[15px]">20/01/2023</p>
        </div>
        <div>
          <div className="mx-auto flex justify-center" onClick={() => setOpen(!open)}>
            {open ? <VMSArrowUpIcon /> : <VMSArrowDownIcon className="text-usafPrimary" />}
          </div>
          {open && (
            <div>
              <MobileReview />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileApplicationStatus;
