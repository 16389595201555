import React from "react";

function Heading() {
  return (
    <div className="md:w-full px-10 items-center justify-center xxs:w-fit">
      <h2
        className="text-[32px] md:text-[50px] xl:text-[50px] w-full px-0 
        font-SpectralBold font-[800] mt-[5rem] text-usafPrimary justify-center text-center"
      >
        Sa Kai Volunteer System
      </h2>
      <p
        className="mt-4 mb-6 text-center grid justify-center text-[16px] lg:text-[18px] xl:text-[18px] text-[#666666] 
        leading-[30px] lg:leading-[35px] max-w-full xxs:text-center md:text-center lg:text-center"
      >
        <span className="text-justify">
          This area contains an introductory text into USAF. This area contains an introductory text
          into USAF. This area contains an introductory text into USAF. This area contains an
          introductory text into USAF. This area contains an introductory text into USAF. This area
          contains an introductory text into USAF. This area contains an introductory text into
          USAF. This area contains an introductory text into USAF.
        </span>
      </p>
    </div>
  );
}

export default Heading;
