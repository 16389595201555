import React, { useState } from "react";
import { BreadCrumbs, Button, FormInput, Loader } from "../../../../../components";
import { VMSAdd, VMSOrganise } from "../../../../../components/icons";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Basics",
    direction: false
  }
];

const breadCrumbsLinks2 = [
  {
    id: 1,
    link: "Basics",
    direction: false
  },
  {
    id: 2,
    link: "Add Basics",
    direction: true
  }
];

const AddBasics = ({ title, loading = false }) => {
  const [AddBasics, setAddBasics] = useState(false);
  return (
    <div>
      {AddBasics ? (
        <div>
          <BreadCrumbs
            breadCrumbsLinks={breadCrumbsLinks2}
            customStyles="px-[23px] min-h-[23px] mb-6"
          />
          <div className="flex flex-col space-y-10 mb-[100px] px-[23px]">
            <h4 className=" font-SpectralRegular text-usafBlack text-[20px]">Add Interests</h4>
            <div className="h-[18.5rem] overflow-y-auto">
              <form className="max-w-[40rem]  m-auto space-y-6">
                <div className="flex  items-center gap-3">
                  <FormInput
                    size="sm"
                    type="text"
                    name="jobTitle"
                    id="jobTitle"
                    className="w-full "
                    label="Job Title"
                  />
                  <FormInput
                    size="sm"
                    type="text"
                    name="companyName"
                    id="companyName"
                    className="w-full "
                    label="Company Name"
                  />
                </div>
                <div className="flex items-center gap-3">
                  <FormInput
                    size="sm"
                    type="text"
                    name="industry"
                    id="industry"
                    className="w-full "
                    label="Industry"
                  />
                  <FormInput
                    size="sm"
                    type="text"
                    name="employmentType"
                    id="employmentType"
                    className="w-full "
                    label="Employment Type"
                  />
                </div>
                <div className="flex items-center gap-3">
                  <FormInput
                    size="sm"
                    type="text"
                    name="location"
                    id="location"
                    className="w-full "
                    label="Location"
                  />
                  <FormInput
                    size="sm"
                    type="text"
                    name="workType"
                    id="workType"
                    className="w-full "
                    label="Work Type"
                  />
                </div>
                <div className="flex items-center gap-3">
                  <FormInput
                    size="sm"
                    type="text"
                    name="startDate"
                    id="startDate"
                    className="w-full "
                    label="Start Date"
                  />
                  <FormInput
                    size="sm"
                    type="text"
                    name="endDate"
                    id="endDate"
                    className="w-full "
                    label="End Date"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <Button type="submit" variant="full" size="sm" className="text-[12px]">
                    {loading ? <Loader /> : "Add Work Experience"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} customStyles="px-[23px] min-h-[23px]" />
          <div className="flex flex-col space-y-10 mb-[100px]">
            <div className="flex space-x-5 my-6 px-4 items-center justify-end">
              <VMSOrganise
                size={20}
                onClick={() => {
                  // setOpenModal(null);
                }}
                className="cursor-pointer"
              />
              <VMSAdd
                size={20}
                onClick={() => {
                  setAddBasics(true);
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-center justify-center w-full bg-[#99B7D8] m-auto max-w-[36rem] py-14">
              <h6 className=" font-LatoRegular text-[16px] text-[#001E3F]">
                You have not added any {title}
              </h6>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBasics;
