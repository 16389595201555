import { FAQ } from "components";
import Testimonial from "components/modules/Testimonial/TestimonialContainer";
import {
  Hero,
  VolunteerSchedule,
  VolunteerContributions,
  StreamlineVolunteer
} from "./SaiKaTasksScreen";
import { SignUpToday } from "pages/Home/HomeCompScreen";

const SaiKaiTasksView = () => {
  return (
    <div className="overflow-hidden">
      <Hero />
      <VolunteerSchedule />
      <VolunteerContributions />
      <StreamlineVolunteer />
      <Testimonial />
      <FAQ title="Frequently Asked Questions (FAQs)" />
      <SignUpToday />
    </div>
  );
};
export default SaiKaiTasksView;
