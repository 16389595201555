import React from "react";
import Input from "./Input";

const FormInput = ({ id, label, errors, touched, required, size, optional, end, ...rest }) => {
  const error = touched !== undefined && errors !== undefined;
  return (
    <div className="w-full ">
      <div className="space-y-1 ">
        <div className="flex items-start justify-between">
          {label !== undefined && (
            <label
              htmlFor={id}
              className={`block ${
                size === "md" ? "" : "text-lg text-left font-SpectralSemiBold"
              } text-[#000000] font-['Spectral'] mb-[6px]`}
            >
              {label}
            </label>
          )}
          {optional && <small className="text-[#009D49] text-[10px]">Optional</small>}
          {required && <small className="text-red-700 text-[10px]">Required</small>}
        </div>

        <Input
          end={undefined}
          size={size}
          {...rest}
          className={`${
            error ? "border-red-700 text-red-700 placeholder:text-red-700" : ""
          } w-full`}
        />
      </div>
      {error && <p className="text-red-700 text-base">{errors}</p>}
    </div>
  );
};

export default FormInput;
