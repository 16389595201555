import React from "react";
import { Link } from "react-router-dom";
import { VMSimages } from "assets";
import { NavigationComp } from "components";
import { CONNECT_WITH_SAI_KAI_ } from "routes/CONSTANTS";

const ConnectWithOthers = () => {
  return (
    <div className="px-2 md:px-5 xl:px-9 py-14 grid grid-cols-1 gap-3 lg:grid-cols-2 bg-white mb-10">
      <div className="flex flex-col xxs:-mt-10 md:mt-0 justify-between items-start xxs:text-center md:items-center lg:items-start">
        <img
          src={VMSimages.connect}
          className="object-cover lg:max-h-[500px] md:max-h-[400px] lg:max-w-full md:max-w-full w-full"
          alt="usa_hands"
        />
      </div>
      <div className="flex flex-col items-center xxs:items-start sm:items-start md:items-start self-center lg:items-end">
        <h1
          className="font-['Spectral_Bold'] max-w-[650px] text-right lg:text-right md:text-left xxs:text-left sm:text-left
          text-[24px] lg:text-[35px] xl:text-[40px] text-[#004B9D] font-bold leading-[40px] lg:leading-[65px] xl:leading-[65px]"
        >
          Team Collaboration Made Easy
        </h1>

        <p
          className="mb-12 mt-5 font-['Lato'] text-[16px] lg:text-[18px] xl:text-[18px] text-[#666666] leading-[30px] lg:leading-[35px]
          text-right md:text-left xxs:text-left sm:text-left lg:text-right max-w-[1000px]"
        >
          Supercharge your team’s productivity with communication and collaboration tools, making it
          easier for you to stay up to date with other team members, keeping them engaged and
          connected at all times. Volunteers can also gain control of their dashboard from one
          central location, keeping organized and informed.
        </p>

        <Link to={CONNECT_WITH_SAI_KAI_}>
          <NavigationComp description="Learn more about our process" />
        </Link>
      </div>
    </div>
  );
};

export default ConnectWithOthers;
