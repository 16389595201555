import { FAQ } from "../../components";
import Testimonial from "components/modules/Testimonial/TestimonialContainer";
import {
  Hero,
  DatabaseSearch,
  TrackOpportunities,
  ConnectWithRightOpportunities
} from "./PerfectVolunteerScreen";
import { SignUpToday } from "pages/Home/HomeCompScreen";

const PerfectVolunteerView = () => {
  return (
    <div className="overflow-hidden">
      <Hero />
      <DatabaseSearch />
      <TrackOpportunities />
      <ConnectWithRightOpportunities />
      <Testimonial />
      <FAQ title="Frequently Asked Questions (FAQs)" />
      <SignUpToday />
    </div>
  );
};
export default PerfectVolunteerView;
