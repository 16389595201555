import React, { useCallback, useState } from "react";
import flagFrance from "assets/svg/flag_france.svg";
import flagGerman from "assets/svg/flag_german.svg";
import flagUnitedKingdom from "assets/svg/flag_united_kingdom.svg";

const MobileViewLanguageForm = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const handleFlagClick = useCallback((language) => {
    setSelectedLanguage(language);
  }, []);

  // const getImageForLanguage = useCallback((language) => {
  //   switch (language) {
  //     case "English":
  //       return flagUnitedKingdom;

  //     case "Francais":
  //       return flagFrance;

  //     case "Deutch":
  //       return flagGerman;

  //     default:
  //       return "";
  //   }
  // }, []);

  return (
    <div className="flex gap-8 px-2">
      <div className="hover:border-2 border-usafPrimary rounded-md px-1">
        {/* <img src={flagUnitedKingdom} alt="" className="w-[40px]" /> */}
      </div>
      <div className="hover:border-2 border-usafPrimary rounded-md px-1">
        {/* <img src={flagFrance} alt="" className="w-[40px] object-contain" /> */}
      </div>
      <div className="hover:border-2 border-usafPrimary rounded-md px-1">
        {/* <img src={flagGerman} alt="" className="w-[40px]" /> */}
      </div>
    </div>
  );
};

export default MobileViewLanguageForm;
