import React from "react";
import SliderCarousel from "./SliderCarousel";
import SliderCarouselMobile from "./SliderCarouselMobile";

const Testimonial = () => {
  const testimonial = {
    name1: "RoseMary Mwangi",
    organization1: "USAF",
    role1: "Volunteer",
    description1:
      "As someone who has volunteered for numerous organizations over the years, I can confidently say that the volunteer management system provided by USAF is truly a game-changer. Gone are the days of sifting through endless spreadsheets and trying to coordinate schedules through a flurry of emails. This system streamlines the entire process, making it easy to manage volunteer applications, schedules, and communications all in one place. What I love most about this system is its user-friendly interface. It's incredibly intuitive and easy to navigate, making it accessible to even the least tech-savvy individuals. I also appreciate the flexibility it provides, allowing volunteers to easily sign up for shifts that work with their schedules.",

    name2: "Jane Tshabalala",
    organization2: "Churchill Consultinf",
    role2: "Volunteer",
    description2:
      "As someone who has volunteered for numerous organizations over the years, I can confidently say that the volunteer management system provided by Sa Kai is truly a game-changer. Gone are the days of sifting through endless spreadsheets and trying to coordinate schedules through a flurry of emails. This system streamlines the entire process, making it easy to manage volunteer applications, schedules, and communications all in one place. What I love most about this system is its user-friendly interface. It's incredibly intuitive and easy to navigate, making it accessible to even the least tech-savvy individuals. I also appreciate the flexibility it provides, allowing volunteers to easily sign up for shifts that work with their schedules.",

    name3: "Ayinke Hassan",
    organization3: "HillTop Volunteer",
    role3: "Volunteer",
    description3:
      "As someone who has volunteered for numerous organizations over the years, I can confidently say that the volunteer management system provided by Sa Kai is truly a game-changer. Gone are the days of sifting through endless spreadsheets and trying to coordinate schedules through a flurry of emails. This system streamlines the entire process, making it easy to manage volunteer applications, schedules, and communications all in one place. What I love most about this system is its user-friendly interface. It's incredibly intuitive and easy to navigate, making it accessible to even the least tech-savvy individuals. I also appreciate the flexibility it provides, allowing volunteers to easily sign up for shifts that work with their schedules.",

    name4: "RoseMary Kwefame",
    organization4: "USAF",
    role4: "Volunteer",
    description4:
      "As someone who has volunteered for numerous organizations over the years, I can confidently say that the volunteer management system provided by Sa Kai is truly a game-changer. Gone are the days of sifting through endless spreadsheets and trying to coordinate schedules through a flurry of emails. This system streamlines the entire process, making it easy to manage volunteer applications, schedules, and communications all in one place. What I love most about this system is its user-friendly interface. It's incredibly intuitive and easy to navigate, making it accessible to even the least tech-savvy individuals. I also appreciate the flexibility it provides, allowing volunteers to easily sign up for shifts that work with their schedules.",

    name5: "Chinasa Ikechukwu",
    organization5: "USAF",
    role5: "Volunteer",
    description5:
      "As someone who has volunteered for numerous organizations over the years, I can confidently say that the volunteer management system provided by Sa Kai is truly a game-changer. Gone are the days of sifting through endless spreadsheets and trying to coordinate schedules through a flurry of emails. This system streamlines the entire process, making it easy to manage volunteer applications, schedules, and communications all in one place. What I love most about this system is its user-friendly interface. It's incredibly intuitive and easy to navigate, making it accessible to even the least tech-savvy individuals. I also appreciate the flexibility it provides, allowing volunteers to easily sign up for shifts that work with their schedules.",

    name6: "Ayinke Hassan",
    organization6: "HillTop Volunteer",
    role6: "Volunteer",
    description6:
      "As someone who has volunteered for numerous organizations over the years, I can confidently say that the volunteer management system provided by Sa Kai is truly a game-changer. Gone are the days of sifting through endless spreadsheets and trying to coordinate schedules through a flurry of emails. This system streamlines the entire process, making it easy to manage volunteer applications, schedules, and communications all in one place. What I love most about this system is its user-friendly interface. It's incredibly intuitive and easy to navigate, making it accessible to even the least tech-savvy individuals. I also appreciate the flexibility it provides, allowing volunteers to easily sign up for shifts that work with their schedules.",

    name7: "RoseMary Kwuruma",
    organization7: "USAF",
    role7: "Volunteer",
    description7:
      "As someone who has volunteered for numerous organizations over the years, I can confidently say that the volunteer management system provided by Sa Kai is truly a game-changer. Gone are the days of sifting through endless spreadsheets and trying to coordinate schedules through a flurry of emails. This system streamlines the entire process, making it easy to manage volunteer applications, schedules, and communications all in one place. What I love most about this system is its user-friendly interface. It's incredibly intuitive and easy to navigate, making it accessible to even the least tech-savvy individuals. I also appreciate the flexibility it provides, allowing volunteers to easily sign up for shifts that work with their schedules."
  };

  return (
    <div className="px-2 md:px-5 xl:px-9 py-9 bg-white mb-14 w-full">
      <div className="flex items-start">
        <h4 className="font-['Spectral_Semi_Bold'] text-[#004B9D] text-start text-[40px] font-[500]">
          Testimonials
        </h4>
      </div>

      <div className="hidden lg:flex w-full mx-auto p-4 justify-center items-center">
        <SliderCarousel {...testimonial} />
      </div>

      <div className="block lg:hidden w-full mx-auto p-4 justify-center items-center">
        <SliderCarouselMobile {...testimonial} />
      </div>
    </div>
  );
};

export default Testimonial;
