import * as Yup from "yup";
import { useFormik } from "formik";
import { Auth } from "../../../components";
import LoginView from "./LoginView";
import { ABOUT_ME } from "../../../routes/CONSTANTS";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, useQuery } from "hooks";
import { login, loginSuccess } from "redux/slices/auth";
import { setMessage } from "redux/slices/message";

export const LoginContainer = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const handleFormLogin = (formData) => {
    const { email, password } = formData;
    dispatch(login({ username: email, password }))
      .unwrap()
      .then((response) => {
        console.log(response);
        getAuthenticatedUser();
      })
      .catch((err) => {
        console.log("Error during Login", err);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          "Weak Password. Password must have at least: 1 upper case, 1 digit, 1 special character, Minimum eight in length"
        )
    }),
    onSubmit: (details) => {
      handleFormLogin(details);
    }
  });

  const getAuthenticatedUser = async () => {
    dispatch(loginSuccess())
      .unwrap()
      .then(() => {
        navigate(ABOUT_ME);
        window.location.reload();
      })
      .catch((err) => {
        console.log("Error during Login", err);
      });
  };

  useEffect(() => {
    const queryString = query.get("redirect");
    if (queryString) {
      setLoading(false);
      if (queryString === "success") {
        getAuthenticatedUser();
      } else {
        const message = query.get("error");
        if (message?.length > 0 && message !== "undefined") {
          dispatch(setMessage(decodeURIComponent(message)));
        }
      }
    }
  }, []);

  return (
    <Auth
      message="Welcome Back"
      google_message="Continue With Google"
      linkedIn_message="Continue With Linkedin"
    >
      <LoginView formik={formik} />
    </Auth>
  );
};
