const dev = {
  REDIRECT_URL: "http://localhost:3000/",
  API_BASE_URL: "http://localhost:8000/api/",
};

const prod = {
  REDIRECT_URL: "http://localhost:3000/",
  API_BASE_URL: "https://backend.vms.usafrikagov.com/api/",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    default:
      return dev;
  }
};

export const env = getEnv();
