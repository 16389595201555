import React from "react";
import ToggleButton from "./ToggleButton";

const ToggleComp = ({ title, isChecked, handleToggle, id, icon }) => {
  return (
    <div className="flex justify-between w-full bg-usafGray3 p-3 mb-2 border-2 border-usafGray7 rounded-[4px]">
      {title && <h6>{title}</h6>}
      {icon && <img src={icon} alt="linkedIn_logo" className="w-[120px] cursor-pointer" />}
      <section className="toggle-icons">
        <ToggleButton isChecked={isChecked} handleToggle={handleToggle} id={id} />
      </section>
    </div>
  );
};

export default ToggleComp;
