export const industriesList = [
  {
    value: "accounting",
    label: "Accounting"
  },
  {
    value: "banking and finance",
    label: "Banking and Finance"
  },
  {
    value: "entertainment",
    label: "Entertainment"
  },
  {
    value: "fitness and sport",
    label: "Fitness and Sport"
  },
  {
    value: "education",
    label: "Education"
  }
];
