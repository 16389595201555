import React, { useState } from "react";
import {
  BreadCrumbs,
  Button,
  FormSelect,
  FormInput,
  Loader,
  SearchBox,
  AppDatePicker
} from "../../../../../components";
import { VMSAdd, VMSOrganise } from "../../../../../components/icons";
import { industriesList } from "../../../../../utils/customOptions";

const breadCrumbsLinks = [
  {
    id: 1,
    link: "Work Experience",
    direction: false
  }
];

const breadCrumbsLinks2 = [
  {
    id: 1,
    link: "Work Experience",
    direction: false
  },
  {
    id: 2,
    link: "Add Work Experience",
    direction: true
  }
];

const AddWorkExperience = ({ title, loading = false }) => {
  const [addWorkExperience, setAddWorkExperience] = useState(false);
  return (
    <div>
      {addWorkExperience ? (
        <div>
          <BreadCrumbs
            breadCrumbsLinks={breadCrumbsLinks2}
            customStyles="px-[23px] min-h-[23px] mb-6"
          />
          <div className="flex flex-col space-y-10 mb-[100px] px-[23px]">
            <h4 className="font-['Spectral'] text-[#000] text-[20px]">Add Work Experience</h4>
            <div className="h-[18.5rem] overflow-y-auto">
              <form className="max-w-[40rem] m-auto space-y-6">
                <div className="flex items-center gap-3">
                  <FormInput
                    size="sm"
                    type="text"
                    name="jobTitle"
                    id="jobTitle"
                    className="w-full "
                    label="Job Title"
                  />
                  <FormSelect
                    size="md"
                    id="employmentType"
                    name="employmentType"
                    options={industriesList}
                    label="Employment Type"
                    // placeholder="Enter Business Industry"
                  />
                </div>
                <div className="flex items-center gap-3">
                  <FormInput
                    size="sm"
                    type="text"
                    name="companyName"
                    id="companyName"
                    className="w-full "
                    label="Company Name"
                  />
                  <FormSelect
                    size="md"
                    id="industry"
                    name="industry"
                    options={industriesList}
                    label="Industry"
                    // placeholder="Enter Business Industry"
                  />
                </div>
                <div className="flex items-center gap-3">
                  <SearchBox size="sm" className="w-full" label="Location" verticalBar />
                  <FormSelect
                    size="md"
                    name="workType"
                    id="workType"
                    options={industriesList}
                    label="Work Type"
                  />
                </div>
                <div className="flex items-center gap-3">
                  <AppDatePicker
                    // value={startDate}
                    placeholder="Date Of Employment"
                    size="sm"
                    name="startDate"
                    id="startDate"
                    label="Start Date "
                    className="w-full"
                    // onChange={handleDateChange}
                  />
                  <AppDatePicker
                    // value={startDate}
                    placeholder="Date Of Employment"
                    size="sm"
                    name="endDate"
                    id="endDate"
                    label="End Date"
                    className="w-full"
                    // onChange={handleDateChange}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <Button type="submit" variant="full" size="sm" className="text-[12px]">
                    {loading ? <Loader /> : "Add Work Experience"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} customStyles="px-[23px] min-h-[23px]" />
          <div className="flex flex-col space-y-10 mb-[100px]">
            <div className="flex space-x-5 my-6 px-4 items-center justify-end">
              <VMSOrganise
                size={20}
                onClick={() => {
                  // setOpenModal(null);
                }}
                className="cursor-pointer"
              />
              <VMSAdd
                size={20}
                onClick={() => {
                  setAddWorkExperience(true);
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-center justify-center w-full bg-[#99B7D8] m-auto max-w-[36rem] py-14">
              <h6 className=" font-LatoRegular text-[16px] text-[#001E3F]">
                You have not added any {title}
              </h6>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddWorkExperience;
