import React from "react";
import { Link } from "react-router-dom";
import { VMSimages } from "assets";

const DatabaseSearch = () => {
  return (
    <div className="px-2 md:px-5 xl:px-9 py-14 max-w-[100%] grid grid-cols-1 gap-3 lg:grid-cols-2 bg-white mb-2">
      <div className="flex flex-col xxs:-mt-10 md:mt-0 justify-between items-center">
        <img
          src={VMSimages.db_personalized}
          className="object-cover lg:max-h-[450px] md:max-h-[400px] lg:max-w-full md:max-w-full w-full"
          alt="usa_hands"
        />
      </div>
      <div className="flex flex-col items-center xxs:items-start self-center lg:items-end">
        <h1
          className="font-['Spectral_Bold'] max-w-[650px] text-right xxs:text-left lg:text-right 
          text-[24px] lg:text-[35px] xl:text-[40px] text-[#004B9D] font-bold leading-[40px] lg:leading-[65px] xl:leading-[65px]"
        >
          Personalized Database Search
        </h1>

        <p
          className="mb-12 mt-5 font-['Lato'] text-[16px] lg:text-[18px] xl:text-[18px] text-[#666666] leading-[30px] lg:leading-[35px]
          text-right xxs:text-left lg:text-right max-w-[1000px]"
        >
          Spending hours running through volunteer opportunities that do not fit your skillset or
          into your available schedules?? Utilize our “Filter” and “Sort” features to navigate
          through volunteer opportunities tailored to your needs. From “Availability Preferences”,
          to “Volunteering Sectors”, and other personalized preferences, you will definitely find a
          suitable search personalized just for you.
        </p>
      </div>
    </div>
  );
};

export default DatabaseSearch;
