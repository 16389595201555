import React from "react";
import {
  BreadCrumbs,
  Button,
  FormInput,
  FormSelect,
  FormTextArea,
  FormAttachment,
  HelpAndSupportComp,
  Loader
} from "../../components";
import { customerCardDetails } from "../../utils/customerCardDetails";
import InputField from "components/widgets/input/InputField";
const breadCrumbsLinks = [
  {
    id: 1,
    link: "Home",
    direction: false
  },
  {
    id: 2,
    link: "Support",
    direction: true
  }
];

const CustomerSupportView = ({ loading = false }) => {
  return (
    <div>
      <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} />
      <HelpAndSupportComp title="Customer Support" cardDetails={customerCardDetails} />
      <h4
        className={`font-['Spectral_Semi_Bold'] text-[#000000] text-center text-[30px] font-[500] my-10`}
      >
        Reach Out To Us
      </h4>
      <div className="w-full px-3 md:px-8">
        <div className="bg-white rounded-lg px-4 md:px-2 shadow-md py-10">
          <form className="m-auto max-w-[59rem] space-y-6">
            <div className="flex items-center gap-3">
              <FormInput
                size="lg"
                type="text"
                name="firstName"
                id="firstName"
                className="w-full "
                label="First Name"
              />
              <FormInput
                size="lg"
                type="text"
                name="lastName"
                id="lastName"
                className="w-full "
                label="Last Name"
              />
            </div>
            <div>
              <FormInput
                size="lg"
                type="text"
                name="email"
                id="email"
                placeholder="ABC University of Technology"
                className="w-full "
                label="Email"
              />
            </div>
            <div>
              <FormSelect
                size="lg"
                type="text"
                // options goes here
                // options={[]}
                name="degree"
                id="degree"
                className="w-full "
                label="Subject"
              />
            </div>
            <div>
              <FormTextArea
                size="lg"
                type="text"
                name="degree"
                id="degree"
                className="w-full "
                label="Message"
              />
            </div>
            <div>
              <InputField
                size="lg"
                type="file"
                name="degree"
                id="degree"
                className="w-full"
                label="Message"
              />
            </div>
            <div className="flex items-center justify-center">
              <Button type="submit" variant="full" size="lg" className="text-base">
                {loading ? <Loader /> : "Submit Form"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupportView;
