const SvgVMSLaunch = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M9.67325 31.0596C9.67325 31.0596 6.154 33.8473 5.93054 41.507C5.92533 41.7147 5.96203 41.9212 6.03844 42.1144C6.11486 42.3076 6.22942 42.4834 6.37528 42.6313C6.52115 42.7792 6.69532 42.8962 6.88741 42.9753C7.0795 43.0543 7.28555 43.0939 7.49327 43.0916C14.904 42.9535 17.9406 39.3273 17.9406 39.3273C18.9996 38.2115 19.59 36.7318 19.59 35.1934C19.59 33.6551 18.9996 32.1754 17.9406 31.0596C15.6578 28.7766 12.2737 28.8875 9.67325 31.0596Z"
        fill="#004B9D"
      />
      <path
        d="M26.0333 33.7879C27.0809 35.1682 27.7211 36.8142 27.8813 38.5397C28.0385 41.2382 27.8001 43.9452 27.1738 46.5748C27.124 46.7926 27.1379 47.0201 27.2138 47.2303C27.2897 47.4404 27.4244 47.6244 27.6018 47.7602C27.7792 47.896 27.9919 47.9779 28.2146 47.9963C28.4373 48.0147 28.6606 47.9687 28.8578 47.8637C35.1073 44.5709 40.2677 38.2073 36.9752 29.9062C33.6654 32.0048 29.9257 33.3314 26.0333 33.7879Z"
        fill="#004B9D"
      />
      <path
        d="M10.4581 21.1152C12.1855 21.2759 13.8332 21.9177 15.2141 22.9678C15.6643 19.0729 16.9911 15.3306 19.0951 12.0221C10.7919 8.72629 4.42819 13.8896 1.13191 20.1395C1.02775 20.3352 0.982151 20.5567 1.00058 20.7775C1.019 20.9984 1.10066 21.2093 1.2358 21.3849C1.37041 21.5642 1.55389 21.7009 1.76424 21.7786C1.97459 21.8562 2.20287 21.8716 2.42172 21.8227C5.05179 21.197 7.75915 20.9586 10.4581 21.1152Z"
        fill="#004B9D"
      />
      <path
        d="M48.7066 2.04609C48.6385 1.60651 48.4323 1.2 48.1178 0.885491C47.8032 0.570981 47.3967 0.364811 46.9571 0.296862C44.4004 -0.0880103 41.8012 -0.0987092 39.2415 0.265102C39.5449 2.67755 40.6433 4.9202 42.3631 6.63892C44.083 8.35765 46.3264 9.45452 48.739 9.75635C49.102 7.19828 49.0911 4.60102 48.7066 2.04609Z"
        fill="#004B9D"
      />
      <path
        d="M37.0872 0.664062C33.2444 1.54766 29.7012 3.42502 26.8119 6.10824C17.329 14.8885 17.3632 23.7905 17.3632 23.7905L25.2121 31.6394C25.2121 31.6394 34.1146 31.6736 42.8943 22.1911C45.5787 19.3006 47.4564 15.7555 48.3395 11.9109C45.4917 11.5255 42.849 10.2168 40.8165 8.18524C38.784 6.15372 37.4739 3.51162 37.0872 0.664062ZM33.4714 20.1057C32.5654 20.1057 31.6796 19.8371 30.9262 19.3337C30.1729 18.8303 29.5857 18.1148 29.2389 17.2777C28.8922 16.4406 28.8015 15.5194 28.9782 14.6308C29.155 13.7421 29.5913 12.9258 30.232 12.2851C30.8727 11.6444 31.689 11.2081 32.5777 11.0313C33.4664 10.8546 34.3875 10.9453 35.2246 11.292C36.0617 11.6388 36.7772 12.226 37.2806 12.9793C37.784 13.7327 38.0527 14.6184 38.0527 15.5245C38.0527 16.1261 37.9342 16.7219 37.7039 17.2777C37.4737 17.8335 37.1363 18.3385 36.7108 18.7639C36.2854 19.1893 35.7804 19.5268 35.2246 19.757C34.6688 19.9872 34.0731 20.1057 33.4714 20.1057Z"
        fill="#004B9D"
      />
    </svg>
  );
};
SvgVMSLaunch.defaultProps = {
  size: 50,
  color: "currentColor"
};
export default SvgVMSLaunch;
