import React from "react";
import { useState, useRef, useEffect } from "react";
import AppDeletePrompt from "../AppProcessComps/AppDeletePrompt";
import { BreadCrumbs, Button, FormInput, Loader, FormSelect } from "components";
import StaticStepper from "./StaticStepper";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";

export const Qualification = ({ loading, ...props }) => {
  const hrsOptions = [
    {
      label: "3-5 hours",
      value: "3-5 hours"
    },
    {
      label: "3-7 hours",
      value: "3-7 hours"
    },
    {
      label: "3-10 hours",
      value: "3-10 hours"
    },
    {
      label: "12 hours & above ",
      value: "12 hours & above"
    }
  ];

  // formik validation
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      linkedin: "",
      phoneNumber: "",
      howyouheard: ""
    },

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      linkedin: Yup.string()
        .required("LinkedIn is required")
        .matches(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
          "LinkedIn must in a valid format"
        ),
      phoneNumber: Yup.string()
        .required("Phonenumber is required")
        .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Phone must be in a valid format")
    })
  });

  const Navigate = useNavigate();

  const openModal3 = () => {
    props.setModal2();
    props.setModal3();
  };

  const AboutYourSelfLink = () => {
    props.setModal2();
    props.breadCrumbAYS();
  };

  const WorkExperience = () => {
    props.setModal2();
    props.breadCrumbWExp();
  };

  const breadCrumbsLinks = [
    {
      id: 1,
      link: "Application Page I",
      onClick: AboutYourSelfLink,
      direction: false
    },
    {
      id: 2,
      link: "Application Page II",
      onClick: WorkExperience,
      direction: true
    },
    {
      id: 2,
      link: "Application Page III",
      to: {},
      direction: true
    }
  ];

  //mobile view
  const breadCrumbsLinksMV = [
    {
      id: 2,
      link: "Application Page II",
      onClick: WorkExperience,
      back_direction: true
    }
  ];

  function handleFormSubmit(e) {
    e.preventDefault();
    openModal3();
  }

  // delete prompt
  const [deletePop, setDeletePop] = useState("");
  const showDeletePop = (e) => {
    setDeletePop(openCancelPop);
    e.preventDefault();
  };

  const hideDeletePop = (e) => {
    setDeletePop(!closeCancelPop);
    e.preventDefault();
  };

  let openCancelPop = {
    visibility: "visible",
    position: "fixed",
    margin: "0 auto",
    bottom: "7rem",
    left: "2rem",
    right: "2rem"
  };

  let closeCancelPop = {
    visibility: "hidden",
    position: "fixed"
  };

  const cancelPopStyle = deletePop ? openCancelPop : closeCancelPop;

  return (
    <div className="bg-usafWhite h-[44rem] overflow-hidden rounded-b-[15px]">
      <div className="hidden md:block">
        <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} />
      </div>

      <div className="block md:hidden">
        <BreadCrumbs breadCrumbsLinks={breadCrumbsLinksMV} />
      </div>

      <StaticStepper />

      <h2 className="font-['Spectral_Semi_Bold'] text-[#000000] text-center text-[32px] font-[600] my-2 mt-[2rem] leading-10">
        Tell Us Why you are Qualified
      </h2>
      <h3 className="font-LatoBold text-[#666666] text-center text-[20px] font-[400] mb-1 leading-[180%]">
        Please provide all Information required in the form below
      </h3>

      <div className="w-full px-8 py-10 bg-white rounded-lg shadow-sm m-auto max-w-[65rem] space-y-6 h-[26rem] overflow-y-auto">
        <form onSubmit={formik.handleSubmit} className="m-auto max-w-[59rem] space-y-6">
          <div className="mt-3">
            <label className="leading-[30px] flex justify-start" htmlFor="yrsOfExp">
              Years Of Experience In UI/UX Design
            </label>
            <div className="text-left justify-items-start">
              <FormSelect
                size="lg"
                name="yrsOfExp"
                id="yrsOfExp"
                placeholder="Select years"
                options={hrsOptions}
              />
            </div>
          </div>

          <div className="w-full px-0 cursor-pointer mt-0" style={{ marginTop: "2rem" }}>
            <p className="text-right justify-end -mb-[3rem] px-[2rem] text-[#009D49] font-LatoBold font-[400] text-[12px] leading-[40px]">
              0/500 words
            </p>
            <label className="leading-[40px] flex justify-start" htmlFor="exp">
              Why do you want to join USAF?
            </label>
            <textarea
              className="flex px-7 h-[4.5rem] relative text-base bg-white placeholder:text-[#8692A6] 
              border-[1.5px] border-[#333333] rounded-[6px] focus:border-[#004B9D] leading-[180%] pt-3 font-LatoBold font-[400]
              content-center justify-between items-center outline-none"
              placeholder="please tell us more..."
              style={{ width: "100%" }}
            ></textarea>
          </div>

          <div className="w-full px-0 cursor-pointer mt-0" style={{ marginTop: "2rem" }}>
            <p className="text-right justify-end -mb-[3rem] px-[2rem] text-[#009D49] font-LatoBold font-[400] text-[12px] leading-[40px]">
              0/500 words
            </p>
            <label className="leading-[40px] flex justify-start" htmlFor="exp">
              Additional Information
            </label>
            <textarea
              className="flex px-7 h-[4.5rem] relative text-base bg-white placeholder:text-[#8692A6] 
              border-[1.5px] border-[#333333] rounded-[6px] focus:border-[#004B9D] leading-[180%] pt-3 font-LatoBold font-[400]
              content-center justify-between items-center outline-none"
              placeholder="please tell us more..."
              style={{ width: "100%" }}
            ></textarea>
          </div>

          <AppDeletePrompt
            showCancelPop={cancelPopStyle}
            deletePopUp={deletePop}
            hideDeletePop={hideDeletePop}
          />

          <div className="flex flex-col md:flex-row items-center justify-around gap-4 py-6 xxs:pb-12 md:pb-2">
            <Link className="md:order-1 xxs:order-2">
              <button
                className="border-[1.5px] border-secondary-100 bg-white text-secondary-100  
                rounded-[4px] py-2 font-['Montserrat'] font-[600] text-[12px] xxs:px-[50px] xs:px-[70px] h-14"
                onClick={showDeletePop}
              >
                Cancel Application
              </button>
            </Link>

            <Link className="md:order-2 xxs:order-1">
              <Button
                onClick={handleFormSubmit}
                type="submit"
                variant="full"
                size="lg"
                className="text-[12px] xxs:px-[50px] xs:px-[70px]"
              >
                {loading ? <Loader /> : "Review Application"}
              </Button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
