import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "animate.css";
// import "animate.css/animate.css";
import {
  ABOUT_ME,
  CUSTOMER_SUPPORT,
  HELP_CENTER,
  HOME,
  LOGIN,
  SIGNUP,
  JOB_ROLES,
  LOGOUT
} from "../../../routes/CONSTANTS";
import { VMSimages } from "../../../assets";

import { Button } from "../../widgets";
import NavLink from "./NavLink";
import { LanguageForm } from "components/widgets/LanguageSelect";
import LoginView from "pages/Authentication/Login/LoginView";
import { useAppDispatch, useAppSelector } from "hooks";
import { logout } from "redux/slices/auth";

import { OnJobRolesPage, LoginSignUp, LogOutViewJobs, OnJobRolesPageFilter } from "./NavSideComp";
import MobileViewLanguageForm from "components/widgets/LanguageSelect/MobileViewLanguageForm/MobileViewLanguageForm";

const Navbar = ({ auth }) => {
  const Navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentPage = location.pathname;
  console.log(isLoggedIn);
  useEffect(() => {
    if (isDropdownOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isDropdownOpen]);
  const handleMenuClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeMenu = () => {
    setIsDropdownOpen(false);
  };

  const handleLoginClick = () => {
    Navigate(LOGIN);
  };

  const handleSignupClick = () => {
    Navigate(SIGNUP);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  let authComponent;

  if (auth || currentPage === JOB_ROLES) {
    authComponent = (
      <img
        src={VMSimages.profileIcon}
        className="object-contain max-w-[56px] cursor-pointer"
        alt="user_profile"
        onClick={() => Navigate(ABOUT_ME)}
      />
    );
  } else {
    const handleClick = currentPage === LOGIN ? handleSignupClick : handleLoginClick;
    const buttonText = currentPage === LOGIN ? "Sign up" : "Log in";

    authComponent = (
      <Button onClick={handleClick} size="xs" className="text-[14px]" variant="outline">
        {buttonText}
      </Button>
    );
  }

  let NavComponentToRender;
  let NavComponentToRenderFilter;

  if (currentPage === JOB_ROLES) {
    NavComponentToRender = <OnJobRolesPage />;
    NavComponentToRenderFilter = <OnJobRolesPageFilter />;
  } else if (currentPage === ABOUT_ME) {
    NavComponentToRender = <LogOutViewJobs />;
  } else {
    NavComponentToRender = <LoginSignUp />;
  }

  return (
    <div className="bg-primary sticky top-0 left-0 right-0 z-50 w-full">
      <div className="relative w-full h-[90px] px-2 md:px-5 xl:px-9 py-4 flex items-center justify-between z-50">
        <Link to={HOME}>
          <img src={VMSimages.vms_logo} className="object-contain max-w-[52.63px]" alt="logo" />
        </Link>

        <div className="hidden md:flex lg:flex items-center justify-between gap-10 text-white">
          <NavLink to={CUSTOMER_SUPPORT}>Support</NavLink>
          <NavLink to={HELP_CENTER}>Help</NavLink>
          <LanguageForm />
          {authComponent}
        </div>

        <div className="flex relative items-center md:hidden lg:hidden">
          <img
            src={VMSimages.menuIcon}
            alt="menu icon"
            className="text-2xl cursor-pointer md:hidden lg:hidden"
            onClick={handleMenuClick}
          />
          {isDropdownOpen && (
            <div
              className="fixed min-h-screen inset-0 bg-black z-40 opacity-70 md:hidden lg:hidden"
              onClick={closeMenu}
            ></div>
          )}
        </div>
        {isDropdownOpen && (
          <div className="absolute z-50 top-0 right-0 bg-usafWhite w-7/12 min-h-screen md:hidden lg:hidden">
            <div
              className="relative z-50 top-0 right-0 bg-usafGray4 text-primary flex flex-col p-2 w-full min-h-fit gap-7 
              md:hidden lg:hidden border-b-2 border-gray-200"
            >
              <div className="self-end">
                <img
                  src={VMSimages.close}
                  alt="close menu"
                  className="block z-50 text-2xl text-end cursor-pointer md:hidden lg:hidden"
                  onClick={closeMenu}
                />
              </div>
              <div className="w-full">
                <MobileViewLanguageForm />
              </div>
              <div className="flex justify-between gap-6 hover:underline-offset-8 text-usafPrimary6 w-fit">
                <NavLink to={CUSTOMER_SUPPORT}>
                  <span className="text-usafPrimary6 ">Support</span>
                </NavLink>
                <NavLink to={HELP_CENTER} className="">
                  <span className="text-usafPrimary6">Help</span>
                </NavLink>
              </div>
              <div className="w-fit m-auto">
                <LanguageForm />
              </div>
              <div className="flex flex-col gap-2 py-2">
                {isLoggedIn ? (
                  <>
                    <Link to={LOGOUT}>
                      <Button
                        type="text"
                        className="bg-usafWhite border-2 border-usafPrimary"
                        size="fill"
                        onClick={handleLogout}
                      >
                        <span className="text-primary">Logout</span>
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to={LOGIN}>
                      <Button
                        type="text"
                        className="bg-usafWhite border-2 border-usafPrimary"
                        size="fill"
                      >
                        <span className="text-primary">Log In</span>
                      </Button>
                    </Link>
                    <Link to={SIGNUP}>
                      <Button type="text" className="" size="fill">
                        Sign Up
                      </Button>
                    </Link>
                  </>
                )}
              </div>
            </div>

            <div className="relative flex flex-col z-50 w-full right-0 lg:flex-row xxs:block bg md:hidden">
              {NavComponentToRenderFilter}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
