export const tech_languages = [
  { label: "Angular", value: "angular" },
  { label: "CSS", value: "css" },
  { label: "Graphic Design", value: "design" },
  { label: "Ember", value: "ember" },
  { label: "HTML", value: "html" },
  { label: "Information Architecture", value: "ia" },
  { label: "Javascript", value: "javascript" },
  { label: "Mechanical Engineering", value: "mech" },
  { label: "Meteor", value: "meteor" },
  { label: "NodeJS", value: "node" },
  { label: "Plumbing", value: "plumbing" },
  { label: "Python", value: "python" },
  { label: "Rails", value: "rails" },
  { label: "React", value: "react" },
  { label: "Kitchen Repair", value: "repair" },
  { label: "Ruby", value: "ruby" },
  { label: "UI Design", value: "ui" },
  { label: "User Experience", value: "ux" }
];
