import React from "react";

const SecondaryButton = ({ className, onClick, type, children }) => {
  return (
    <div>
      <button className={className} onClick={onClick} type={type}>
        {children}
      </button>
    </div>
  );
};

export default SecondaryButton;
