import JobDescriptionView from "./JobDescriptionView";
import { Landing } from "components";

export const JobDescriptionContainer = () => {
  return (
    <Landing>
      <JobDescriptionView />
    </Landing>
  );
};
