import { combineReducers } from "@reduxjs/toolkit";

import auth from "./auth";
import user from "./user";
import message from "./message";
// import organization from "./organization.slice";
// import setting from "./setting.slice";

const rootReducer = combineReducers({
  auth,
  user,
  message
  //   organization,
  //   setting
});

export default rootReducer;
