import React from "react";
import { Link } from "react-router-dom";
import { VMSimages } from "assets";

const BreadCrumbs = ({ breadCrumbsLinks, customStyles }) => {
  return (
    <div
      className={`${
        customStyles ? customStyles : "xxs:px-2 md:px-[30px] min-h-[36px]"
      } xxs:flex md:flex items-center space-x-3 w-full text-[18px] font-bold bg-white border-b-[.8px] border-[hsl(240, 2%, 52%)]`}
    >
      {breadCrumbsLinks?.map((data) => (
        <div key={data.id} className="flex items-center space-x-3">
          {data.direction && (
            <div className="">
              <img
                src={VMSimages.arrow_right}
                className="object-contain max-w-[10px]"
                alt="arrow-right"
              />
            </div>
          )}

          {data.back_direction && (
            <div className="block md:hidden">
              <img
                src={VMSimages.arrow_right}
                className="object-contain max-w-[10px] transform scale-x-[-1]"
                alt="arrow-right"
              />
            </div>
          )}

          <Link
            to={data.to}
            onClick={() => {
              data.onClick && data.onClick(data);
            }}
            className="text-[14px] text-[#004B9D] hover:underline font-[600] font-MontserratBold"
          >
            {data.link}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default BreadCrumbs;
