const SvgVMSFlagUnitedKingdom = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path d="M0 0.00100708H35.9987V24.0004H0V0.00100708Z" fill="#F0F0F0" />
      <path
        d="M20.2493 0H15.7494V9.7496H0V14.2494H15.7494V23.999H20.2493V14.2494H35.9987V9.7496H20.2493V0Z"
        fill="#D80027"
      />
      <path
        d="M27.687 16.1732L35.9987 20.7909V16.1732H27.687ZM21.9122 16.1732L35.9987 23.999V21.7861L25.8956 16.1732H21.9122ZM32.2465 23.999L21.9122 18.2572V23.999H32.2465Z"
        fill="#0052B4"
      />
      <path d="M21.9122 16.1732L35.9987 23.999V21.7861L25.8956 16.1732H21.9122Z" fill="#F0F0F0" />
      <path d="M21.9122 16.1732L35.9987 23.999V21.7861L25.8956 16.1732H21.9122Z" fill="#D80027" />
      <path
        d="M6.35187 16.1732L0 19.7021V16.1732H6.35187ZM14.0865 17.1684V23.9991H1.79227L14.0865 17.1684Z"
        fill="#0052B4"
      />
      <path d="M10.103 16.1732L0 21.7861V23.999L14.0865 16.1732H10.103Z" fill="#D80027" />
      <path
        d="M8.31169 7.82578L0 3.2081V7.82578H8.31169ZM14.0865 7.82578L0 0V2.21293L10.103 7.82578H14.0865ZM3.75216 0L14.0865 5.74179V0H3.75216Z"
        fill="#0052B4"
      />
      <path d="M14.0865 7.82578L0 0V2.21293L10.103 7.82578H14.0865Z" fill="#F0F0F0" />
      <path d="M14.0865 7.82578L0 0V2.21293L10.103 7.82578H14.0865Z" fill="#D80027" />
      <path
        d="M29.6468 7.82588L35.9987 4.29702V7.82588H29.6468ZM21.9122 6.83071V3.05176e-05H34.2064L21.9122 6.83071Z"
        fill="#0052B4"
      />
      <path d="M25.8956 7.82578L35.9987 2.21293V0L21.9122 7.82578H25.8956Z" fill="#D80027" />
    </svg>
  );
};
SvgVMSFlagUnitedKingdom.defaultProps = {
  size: 16,
  color: "currentColor"
};
export default SvgVMSFlagUnitedKingdom;
