import React from "react";
import { SearchBox } from "../../widgets";
import { CardComp } from "../cardComp";

const HelpAndSupportComp = ({ title, cardDetails }) => {
  return (
    <div className="pt-12">
      <h4 className=" font-SpectralBold text-textH2  text-center text-usafBlack mb-6">{title}</h4>
      <div className="m-auto max-w-[60rem] mb-6 px-4">
        <SearchBox
          placeholder="Search for a topic or keyword"
          size="md"
          iconSize={18}
          className="w-full border-usafPrimary4"
        />
      </div>
      <CardComp cardDetails={cardDetails} />
    </div>
  );
};

export default HelpAndSupportComp;
