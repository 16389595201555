import React from "react";

const SettingsCompWrapper = ({ title, subTitle, children }) => {
  return (
    <div className="flex flex-col flex-wrap w-full bg-usafWhite mt-4 mb-11 p-6 rounded-[8px] border-2px border-usafBlack4">
      <section className="mb-2">
        <h1 className="text-textH6">{title}</h1>
        <h6 className="font-['Lato'] text-textxxs pb-4">{subTitle}</h6>
      </section>
      <div className="rounded-[4px]">{children}</div>
    </div>
  );
};

export default SettingsCompWrapper;
