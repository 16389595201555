import React from "react";
import { BreadCrumbs } from "components";
import { Content } from "./JobDescriptionCompScreen";
import { HOME, JOB_DESCRIPTION } from "routes/CONSTANTS";

const JobDescriptionView = () => {
  const breadCrumbsLinks = [
    {
      id: 1,
      link: "Home",
      to: HOME,
      direction: false
    },
    {
      id: 2,
      link: "JobDescription",
      to: JOB_DESCRIPTION,
      direction: true
    }
  ];

  return (
    <div>
      <div className="">
        <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} />
      </div>
      <Content />
    </div>
  );
};

export default JobDescriptionView;
