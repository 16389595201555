import { FAQ } from "../../components";
import Testimonial from "components/modules/Testimonial/TestimonialContainer";
import {
  Hero,
  RightOpportunities,
  ManageSchedule,
  ConnectWithOthers,
  SignUpToday
} from "./HomeCompScreen";

const HomeView = () => {
  return (
    <div className="overflow-hidden">
      <Hero />
      <RightOpportunities />
      <ManageSchedule />
      <ConnectWithOthers />
      <Testimonial />
      <FAQ title="Frequently Asked Questions (FAQs)" />
      <SignUpToday />
    </div>
  );
};
export default HomeView;
