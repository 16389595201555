import React, { useState } from "react";
import { VMSimages } from "assets";
import "./slider.css";

function SliderCarousel(props) {
  const {
    name1,
    organization1,
    role1,
    description1,
    name2,
    organization2,
    role2,
    description2,
    name3,
    organization3,
    role3,
    description3,
    name4,
    organization4,
    role4,
    description4,
    name5,
    organization5,
    role5,
    description5
  } = props;

  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => Math.max(prevSlide - 1, 0));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => Math.min(prevSlide + 1, 4)); // Assuming you have 5 slides in total
  };

  return (
    <div className="cardContainer">
      <div className="py-3 px-1 my-3 mx-auto w-full h-full">
        <div className="cards justify-center min-w-fit">
          <input
            type="radio"
            name="sliderMv"
            className="hidden"
            id="s1"
            checked={currentSlide === 0}
          />
          <label
            htmlFor="s1"
            id="slide1"
            className={`w-full ${currentSlide === 0 ? "visible" : "hidden"}`}
          >
            <div className="mx-auto pt-[1rem]">
              <div className="">
                <img className="absolute top-[1rem] w-9" src={VMSimages.card_vector_front} alt="" />
                <p className="leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description1}
                </p>
                <img
                  className="absolute right-[0rem] -mt-[1.5rem] w-9"
                  src={VMSimages.card_vector_back}
                  alt=""
                />

                <div className="justify-center flex pt-[3rem]">
                  <img src={VMSimages.card_img_1} alt="" className="h-[150px] w-[100px]" />
                </div>
              </div>

              <div className="mt-2 grid justify-center">
                <h4 className="text-center text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name1}
                </h4>
                <h6 className="text-center text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization1}
                </h6>
                <small className="text-center text-[#666666] font-[Lato] text-[10px]">
                  {role1}
                </small>
              </div>
            </div>
          </label>

          <input
            type="radio"
            name="sliderMv"
            className="hidden"
            id="s2"
            checked={currentSlide === 1}
          />
          <label
            htmlFor="s1"
            id="slide1"
            className={`w-full ${currentSlide === 1 ? "visible" : "hidden"}`}
          >
            <div className="mx-auto pt-[1rem]">
              <div className="">
                <img className="absolute top-[1rem] w-9" src={VMSimages.card_vector_front} alt="" />
                <p className="leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description2}
                </p>
                <img
                  className="absolute right-[0rem] -mt-[1.5rem] w-9"
                  src={VMSimages.card_vector_back}
                  alt=""
                />

                <div className="justify-center flex pt-[3rem]">
                  <img src={VMSimages.card_img_3} alt="" className="h-[150px] w-[100px]" />
                </div>
              </div>

              <div className="mt-2 grid justify-center">
                <h4 className="text-center text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name2}
                </h4>
                <h6 className="text-center text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization2}
                </h6>
                <small className="text-center text-[#666666] font-[Lato] text-[10px]">
                  {role2}
                </small>
              </div>
            </div>
          </label>

          <input
            type="radio"
            name="sliderMv"
            className="hidden"
            id="s3"
            checked={currentSlide === 2}
          />
          <label
            htmlFor="s1"
            id="slide1"
            className={`w-full ${currentSlide === 2 ? "visible" : "hidden"}`}
          >
            <div className="mx-auto pt-[1rem]">
              <div className="">
                <img className="absolute top-[1rem] w-9" src={VMSimages.card_vector_front} alt="" />
                <p className="leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description3}
                </p>
                <img
                  className="absolute right-[0rem] -mt-[1.5rem] w-9"
                  src={VMSimages.card_vector_back}
                  alt=""
                />

                <div className="justify-center flex pt-[3rem]">
                  <img src={VMSimages.card_img_2} alt="" className="h-[150px] w-[100px]" />
                </div>
              </div>

              <div className="mt-2 grid justify-center">
                <h4 className="text-center text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name3}
                </h4>
                <h6 className="text-center text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization3}
                </h6>
                <small className="text-center text-[#666666] font-[Lato] text-[10px]">
                  {role3}
                </small>
              </div>
            </div>
          </label>

          <input
            type="radio"
            name="sliderMv"
            className="hidden"
            id="s4"
            checked={currentSlide === 3}
          />
          <label
            htmlFor="s1"
            id="slide1"
            className={`w-full ${currentSlide === 3 ? "visible" : "hidden"}`}
          >
            <div className="mx-auto pt-[1rem]">
              <div className="">
                <img className="absolute top-[1rem] w-9" src={VMSimages.card_vector_front} alt="" />
                <p className="leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description4}
                </p>
                <img
                  className="absolute right-[0rem] -mt-[1.5rem] w-9"
                  src={VMSimages.card_vector_back}
                  alt=""
                />

                <div className="justify-center flex pt-[3rem]">
                  <img src={VMSimages.card_img_1} alt="" className="h-[150px] w-[100px]" />
                </div>
              </div>

              <div className="mt-2 grid justify-center">
                <h4 className="text-center text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name4}
                </h4>
                <h6 className="text-center text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization4}
                </h6>
                <small className="text-center text-[#666666] font-[Lato] text-[10px]">
                  {role4}
                </small>
              </div>
            </div>
          </label>

          <input
            type="radio"
            name="sliderMv"
            className="hidden"
            id="s5"
            checked={currentSlide === 4}
          />
          <label
            htmlFor="s1"
            id="slide1"
            className={`w-full ${currentSlide === 4 ? "visible" : "hidden"}`}
          >
            <div className="mx-auto pt-[1rem]">
              <div className="">
                <img className="absolute top-[1rem] w-9" src={VMSimages.card_vector_front} alt="" />
                <p className="leading-[30px] text-[17px] text-grayText font-[lato] text-justify">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {description5}
                </p>
                <img
                  className="absolute right-[0rem] -mt-[1.5rem] w-9"
                  src={VMSimages.card_vector_back}
                  alt=""
                />

                <div className="justify-center flex pt-[3rem]">
                  <img src={VMSimages.card_img_3} alt="" className="h-[150px] w-[100px]" />
                </div>
              </div>

              <div className="mt-2 grid justify-center">
                <h4 className="text-center text-[#336FB1] font-[Spectral] text-[20px] font-semibold mb-3">
                  {name5}
                </h4>
                <h6 className="text-center text-[#666666] font-[Lato] text-[13px] leading-1">
                  {organization5}
                </h6>
                <small className="text-center text-[#666666] font-[Lato] text-[10px]">
                  {role5}
                </small>
              </div>
            </div>
          </label>
        </div>
      </div>

      <div className="justify-center gap-5 flex">
        <img
          className="border rounded-full p-4 bg-[#004B9D]"
          src={VMSimages.arrowLeft}
          alt="arrowLeft"
          onClick={handlePrevSlide}
        />

        <img
          className="border rounded-full p-4 bg-[#004B9D]"
          src={VMSimages.arrowRight}
          alt="arrowRight"
          onClick={handleNextSlide}
        />
      </div>
    </div>
  );
}

export default SliderCarousel;
