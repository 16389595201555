import React, { useState } from "react";
import { VMSimages } from "../../../assets/index";

const QuestionsComp = ({ question, answer }) => {
  const [isShowQuestion, setIsShowQuestion] = useState(false);
  return (
    <div
      onClick={() => setIsShowQuestion(!isShowQuestion)}
      className="flex flex-col justify-between border-[1.6px] p-[15px] rounded-lg border-[#336FB1] mb-3"
    >
      <div className={`flex justify-between ${isShowQuestion && "mb-2"}`}>
        <h4
          className={`font-['Lato'] text-[#333333] text-[16px] ${
            isShowQuestion ? "text-[#004DB3]" : "text-[#333333]"
          }`}
        >
          {question}
        </h4>
        {isShowQuestion ? (
          <img
            src={VMSimages.minus}
            className="cursor-pointer object-contain max-w-[16px]"
            alt=""
          />
        ) : (
          <img src={VMSimages.plus} className="cursor-pointer object-contain max-w-[16px]" alt="" />
        )}
      </div>
      {isShowQuestion && (
        <p
          className="text-[14px] pt-1 leading-4 md:leading-[24px] text-[#333333] max-w-full border-t 
          border-usafPrimary border-dotted"
        >
          {answer}
        </p>
      )}
    </div>
  );
};

export default QuestionsComp;
