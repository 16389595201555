import React from "react";
import { NavigationComp } from "../navigationComp";

const CardComp = ({ cardDetails }) => {
  return (
    <div className="px-[30px] grid place-items-center md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mb-2">
      {cardDetails?.map(({ Icon, title, subTitle }, index) => (
        <div key={index} className="relative bg-white max-w-[300px] px-4  py-8 rounded-lg">
          <label className="flex items-center justify-center mb-2">
            <Icon size={35} />
          </label>
          <h6 className="text-center font-SpectralSemiBold text-[20px] mb-2 max-w-[250px] m-auto">
            {title}
          </h6>
          <p className="text-left text-[14px] text-[#666666] leading-6 font-LatoRegular mb-3">
            {subTitle}
          </p>
          <div className="absolute bottom-6 right-4">
            <NavigationComp
              description="Read More"
              customStyles="flex items-center justify-end space-x-1"
              textClass="text-[12px] font-MontserratSemiBold cursor-pointer"
              iconClass="max-w-[8px]"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardComp;
