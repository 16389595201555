import React, { useState, useRef, useCallback } from "react";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { debounce } from "lodash";
import { FaTimes } from "react-icons/fa";
import { useFormik, validateYupSchema } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "semantic-ui-css/semantic.css";
import { Dropdown } from "semantic-ui-react";
import statesjson from "utils/statesjson.json";
import { countryOptions } from "utils/countryNameFlags";
import dropVector from "assets/svg/dropVector.svg";
import ellipseUpld from "assets/img/ellipseUpload.png";
import { BreadCrumbs, Button, FormInput, Loader } from "components";
import StaticStepper from "./StaticStepper";
import AppDeletePrompt from "../AppProcessComps/AppDeletePrompt";

export const AboutYourSelf = ({ loading, ...props }) => {
  // State Options for the Semantic UI Dropdown
  const stateOptions = statesjson.allStates.map(({ Id, state }) => ({
    value: Id,
    text: state
  }));

  const Navigate = useNavigate();

  const openModal1 = () => {
    props.setModal();
    props.setModal1();
  };

  const closeModal = () => {
    props.setModal();
  };

  const breadCrumbsLinks = [
    {
      id: 1,
      link: "Application Page I",
      to: {},
      direction: false
    }
  ];

  const breadCrumbsLinksMV = [
    {
      id: 1,
      link: "Application Page I",
      direction: false
    }
  ];

  // formik validation
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      linkedin: "",
      phoneNumber: "",
      howyouheard: ""
    },

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      linkedin: Yup.string()
        .required("LinkedIn is required")
        .matches(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
          "LinkedIn must in a valid format"
        ),
      phoneNumber: Yup.string()
        .required("Phonenumber is required")
        .matches(
          /^\+(?:[0-9] ?){6,14}[0-9]$/,
          // /^(?:\+?(?:(?:\d{1,3})|0{1,2})[-.\s]?)?(?:\((?:\d{1,4})\)|\d{1,4})?\s?\d{1,4}\s?\d{1,9}$/,
          "Phone must be in a valid format"
        )
    })
  });

  // Define a debounced version of the handleChange function
  const debouncedHandleChange = debounce(formik.handleChange, 300);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleDropdownChange();
    handleFileUpload();
    handleOptionSubmit();
    formik.handleSubmit();

    if (Object.keys(formik.errors).length === 0 && files && chosen !== "Select One") {
      event.preventDefault();
      openModal1(event);
    } else {
    }
  };
  // end of validation..

  //semantic ui dropdown validation
  const [selectedCountry, setSelectedCountry] = useState("af");
  const [semerror, setSemerror] = useState("");

  const validateCountry = () => {
    if (selectedCountry === "") {
      setSemerror("Please select a country");
      return false;
    }
    setSemerror("");
    return true;
  };

  const handleDropdownChange = (event, data) => {
    setSelectedCountry(data);
    validateCountry();
  };

  // others textarea
  const [dropshow, setDropShow] = useState("");
  const showDrop = () => {
    setDropShow(open_out);
  };
  const hideDrop = () => {
    setDropShow(!close_out);
  };

  let open_out = {
    visibility: "visible",
    marginBottom: "5rem"
  };

  let close_out = {
    visibility: "hidden",
    marginBottom: "-5rem"
  };
  const dropStyle = dropshow ? open_out : close_out;

  //dropdown function howyouheard
  const options = ["LinkedIn", "Facebook", "Instagram"];
  const option2 = ["Others"];

  const [isActive, setIsActive] = useState(false);
  const [chosen, setChosen] = useState("Select One");

  //dropdown validation
  const [dropError, setDropError] = useState("");
  const handleOptionSubmit = (event) => {
    //event.preventDefault();
    if (chosen == "Select One") {
      setDropError({ howyouheard: "Please select an option" });
      showErrorBd();
      return false;
    } else {
      setDropError({ howyouheard: "" });
      hideErrorBd();
      return true;
    }
  };

  const handleOptionChange = () => {
    setDropError({ howyouheard: "" });
    hideErrorBd();
    return true;
  };

  const [errorBd, setErrorBd] = useState("");
  const showErrorBd = () => {
    setErrorBd(errorBorder);
  };
  const hideErrorBd = () => {
    setErrorBd(!goodBorder);
  };

  let errorBorder = {
    border: "1px solid #BF0000",
    color: "#BF0000",
    placeholder: "#BF0000"
  };

  let goodBorder = {
    border: "1px solid #000000",
    color: "#333333",
    placeholder: "#333333"
  };

  const borderErrorStyle = errorBd ? errorBorder : goodBorder;

  const comStyle = {
    ...borderErrorStyle,
    ...(isActive ? { border: "1.5px solid #004B9D" } : {})
  };

  const inputRef = useRef(null);
  const [files, setFiles] = useState(null);
  const [filesError, setFilesError] = useState("");

  const handleFileClick = useCallback((event) => {
    // 👇️ open file input box on click of other element
    event.preventDefault();
    inputRef.current.click();
  }, []);

  const handleFileChange = useCallback((event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      setFilesError("");
      setFiles(null);
    } else {
      setFiles(fileObj);
    }
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
    const fileObj = event.dataTransfer.files && event.dataTransfer.files[0];
    if (!fileObj) {
      setFilesError("");
      setFiles(null);
    } else {
      setFiles(fileObj);
    }
  }, []);

  const handleFileUpload = useCallback(() => {
    if (files) {
      setFilesError("");
      return true;
    } else {
      setFilesError("Please upload a file before proceeding");
      return false;
    }
  }, [files]);

  const handleFileClear = useCallback(() => {
    setFiles(null);
  }, []);

  // delete prompt
  const [deletePop, setDeletePop] = useState("");
  const showDeletePop = (e) => {
    setDeletePop(openCancelPop);
    e.preventDefault();
  };

  const hideDeletePop = (e) => {
    setDeletePop(!closeCancelPop);
    e.preventDefault();
  };

  let openCancelPop = {
    visibility: "visible",
    position: "fixed",
    margin: "0 auto",
    bottom: "7rem",
    left: "2rem",
    right: "2rem"
  };

  let closeCancelPop = {
    visibility: "hidden",
    position: "fixed"
  };

  const cancelPopStyle = deletePop ? openCancelPop : closeCancelPop;

  return (
    <div className="bg-usafWhite h-[44rem] overflow-hidden rounded-b-[15px]">
      <div className="hidden md:block">
        <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} />
      </div>

      <div className="block md:hidden">
        <BreadCrumbs breadCrumbsLinks={breadCrumbsLinksMV} />
      </div>

      <StaticStepper />

      <h2
        className="font-['Spectral_Semi_Bold'] text-[#000000] text-center xxs:text-[28px] 
        md:text-[32px] font-[600] my-2 mt-[2rem] leading-10"
      >
        Tell Us About Yourself
      </h2>
      <h3 className="font-LatoBold text-[#666666] text-center xxs:text-[18px] md:text-[20px] font-[400] mb-1 leading-[180%]">
        Please note that all fields are mandatory
      </h3>

      <div
        className="xxs:w-[23rem] xs:w-[25rem] md:w-full px-8 py-10 bg-white rounded-lg shadow-sm m-auto 
        max-w-[65rem] space-y-6 h-[26rem] overflow-y-auto"
      >
        <form onSubmit={formik.handleSubmit} className="m-auto max-w-[59rem] space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-3 mt-3">
            <FormInput
              size="lg"
              type="text"
              name="firstName"
              id="firstName"
              placeholder="firstname"
              className="w-full"
              defaultValue={formik.values.firstName}
              onChange={debouncedHandleChange}
              errors={formik.errors.firstName}
              touched={formik.touched.firstName}
              label="First Name"
            />

            <FormInput
              size="lg"
              type="text"
              name="lastName"
              id="lastName"
              placeholder="lastname"
              className="w-full "
              defaultValue={formik.values.lastName}
              onChange={debouncedHandleChange}
              errors={formik.errors.lastName}
              touched={formik.touched.lastName}
              label="Last Name"
            />
          </div>

          <div className="mt-3">
            <FormInput
              size="lg"
              type="text"
              name="email"
              id="email"
              placeholder="fill in your email address"
              className="w-full"
              defaultValue={formik.values.email}
              onChange={debouncedHandleChange}
              errors={formik.errors.email}
              touched={formik.touched.email}
              label="Email"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-3 w-full">
            <div className="w-full">
              <label className="leading-[40px] justify-start flex" htmlFor="country">
                Country
              </label>
              <div
                className="countryDropdown h-16 text-base bg-white placeholder:text-[#8692A6] border-[1.5px] border-[#333333] 
                rounded-[6px] focus:border-[#004B9D] content-center outline-none"
              >
                <Dropdown
                  style={{ border: "none", backgroundColor: "none", marginTop: "0.5rem" }}
                  className={`${
                    semerror ? "border-red-700 text-red-700 placeholder:text-red-700" : ""
                  } w-full`}
                  fluid
                  search
                  clearable
                  required
                  selection
                  defaultValue="af"
                  options={countryOptions}
                  onChange={handleDropdownChange}
                />
              </div>
            </div>

            <div className="w-full">
              <label className="leading-[40px] justify-start flex" htmlFor="city">
                City
              </label>
              <div
                className="h-16 text-base bg-white placeholder:text-[#8692A6] border-[1.5px] border-[#333333] 
                rounded-[6px] focus:border-[#004B9D] content-center outline-none"
              >
                <Dropdown
                  style={{ border: "none", backgroundColor: "none", marginTop: "0.5rem" }}
                  className={`${
                    semerror ? "border-red-700 text-red-700 placeholder:text-red-700" : ""
                  } w-full`}
                  fluid
                  search
                  rules={{ required: true }}
                  required
                  selection
                  clearable
                  defaultValue="3690"
                  options={stateOptions}
                  name="city"
                />
              </div>
            </div>
          </div>

          <div className="pt-5">
            <FormInput
              size="lg"
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              placeholder="fill in your phone number"
              className="w-full rounded-[6px] focus:border-[#004B9D] content-center outline-none"
              defaultValue={formik.values.phoneNumber}
              onChange={debouncedHandleChange}
              errors={formik.errors.phoneNumber}
              touched={formik.touched.phoneNumber}
              label="Country Code"
            />
          </div>

          <div className="cardDivFull">
            <div className="w-full justify-center">
              <label className="leading-[40px] justify-start flex" htmlFor="name">
                Upload CV
              </label>
              <div
                className={` ${
                  filesError ? "border-red-700 text-red-700 placeholder:text-red-700" : ""
                } 
                h-[420px] w-full justify-center items-center rounded-lg border-[1.5px] border-[#cccccc] text-center
                m-auto drop-shadow-[0_10px_15px_rgba(0,0,0,0.2)] bg-[#F5F5F5]`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <div
                  className="justify-center items-center"
                  type="file"
                  multiple
                  onChange={(event) => {
                    setFiles(event.target.files);
                    handleFileChange();
                  }}
                  hidden
                  accept="image/png, image/jpeg"
                  name="cvfile"
                  ref={inputRef}
                ></div>

                <img
                  src={ellipseUpld}
                  className="justify-center mx-auto h-[120px] w-[120px] mt-[2rem]"
                  alt="ellipse"
                />

                {files ? (
                  <div>
                    <p className="mx-auto text-center justify-center font-SpectralBold font-[400] leading-[150%] text-[#000} text-[18px]">
                      {files.name}
                    </p>
                    <FaTimes
                      className="mx-auto cursor-pointer justify-center flex text-[#004B9D] w-[20px] h-[20px]"
                      onClick={handleFileClear}
                    />
                  </div>
                ) : (
                  <div className="mt-[1rem] mx-auto relative text-center justify-center">
                    <h3 className="font-SpectralBold font-[600] leading-[150%] text-[#000} text-[24px]">
                      Drag and drop a file
                    </h3>
                    <h6 className="font-LatoBold font-[16px] leading-[20px] text-[#666666] text-[16px] xxs:p-3 xs:p-1">
                      Allowed File Type: Docx, Pdf &nbsp;
                      <span className="xxs:hidden xs:inline-flex"> | </span>
                      &nbsp; Maximum Size: 2MB
                    </h6>
                  </div>
                )}

                <div className="container horizontal md:w-[50rem] xxs:mt-0 xs:mt-5 mx-auto p-4 flex justify-between items-center">
                  <div className="w-full flex items-center justify-center">
                    {/* line */}
                    <div className={`flex-auto border-t-[2px] border-gray-200`}></div>
                    {/* number */}
                    <div className="relative flex flex-col items-center mx-auto">
                      <div
                        className="h-11 w-11 flex items-center justify-center py-3 
                        text-[#007E3A] font-bold text-xl"
                      >
                        OR
                      </div>
                    </div>
                    {/* line */}
                    <div className={`flex-auto border-t-[2px] border-gray-200`}></div>
                  </div>
                </div>

                <input
                  type="file"
                  accept=".pdf,.doc,.docx" // Add allowed file types here
                  style={{ display: "none", background: "#003C7E", color: "#fff" }}
                  ref={inputRef}
                  onChange={handleFileChange}
                  name="cvfile"
                />

                <div className="flex items-center justify-center xxs:pt-0 md:pt-4 mt-3 pb-10">
                  <Link>
                    <Button
                      onClick={handleFileClick}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      type="submit"
                      variant="full"
                      size="lg"
                      className="text-[12px]"
                    >
                      {loading ? <Loader /> : "Browse Files"}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="" style={{ marginTop: "5px" }}>
            {filesError && <p className=" text-red-700 text-[16px]">{filesError}</p>}
          </div>

          <div className="pt-5">
            <FormInput
              size="lg"
              type="text"
              name="linkedin"
              id="linkedin"
              placeholder="fill in your linkedin address"
              className="w-full"
              defaultValue={formik.values.linkedin}
              onChange={debouncedHandleChange}
              errors={formik.errors.linkedin}
              touched={formik.touched.linkedin}
              label="LinkedIn"
            />
          </div>

          <div className="cardDivFull">
            <div className="w-full justify-center">
              <label className="leading-[40px] justify-start flex" htmlFor="notice">
                How did you hear about us?{" "}
              </label>
              <div className="w-full" chosen={chosen} setChosen={setChosen}>
                <div
                  className="flex px-7 h-16 text-base bg-white placeholder:text-[#8692A6] border-[1.5px] border-[#333333] 
                  rounded-[6px] focus:border-[#004B9D] leading-[180%] font-LatoBold font-[400]
                  content-center justify-between items-center outline-none cursor-pointer"
                  type="dropdown"
                  name="howyouheard"
                  style={comStyle}
                  onClick={(e) => {
                    setIsActive(!isActive);
                  }}
                >
                  {chosen}
                  <img
                    src={dropVector}
                    alt="dropvector"
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>

                {isActive && (
                  <div
                    className="h-fit w-full relative border-[1.5px] border-[#333333] rounded-[6px] mt-2
                    leading-[180%] text-base font-LatoBold font-[400] justify-items-start text-left"
                  >
                    {options.map((option) => (
                      <div
                        className="cursor-pointer transition-all px-7 py-4 
                        border-b border-[1.5px] border-[#D9D9D9] hover:bg-[#D9D9DD]"
                        onChange={handleOptionChange}
                        onClick={(e) => {
                          setChosen(option);
                          setIsActive(false);
                          hideDrop();
                          handleOptionChange();
                        }}
                      >
                        {option}
                      </div>
                    ))}

                    {option2.map((option) => (
                      <div
                        className="cursor-pointer transition-all px-7 py-4 
                        border-b border-[1.5px] border-[#D9D9D9] hover:bg-[#D9D9DD]"
                        onClick={(e) => {
                          setChosen(option);
                          setIsActive(false);
                          showDrop();
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="" style={{ marginTop: "1px" }}>
            {dropError.howyouheard && (
              <p className="text-red-700 text-[16px]">{dropError.howyouheard}</p>
            )}
          </div>

          <div className="w-full px-0 cursor-pointer mt-0" style={dropStyle}>
            <p className="text-right px-[2rem] text-[#009D49] font-LatoBold font-[400] text-[12px] leading-[180%]">
              1/500
            </p>
            <textarea
              className="flex px-7 h-16 relative text-base bg-white placeholder:text-[#8692A6] 
              border-[1.5px] border-[#333333] rounded-[6px] focus:border-[#004B9D] leading-[180%] pt-3 font-LatoBold font-[400]
              content-center justify-between items-center outline-none"
              placeholder="please tell us more..."
              style={{ width: "100%" }}
            ></textarea>
          </div>

          <AppDeletePrompt
            showCancelPop={cancelPopStyle}
            deletePopUp={deletePop}
            hideDeletePop={hideDeletePop}
          />

          <div className="flex flex-col md:flex-row items-center justify-around gap-4">
            <Link className="md:order-1 xxs:order-2">
              <button
                className="border-[1.5px] border-secondary-100 bg-white text-secondary-100  
                rounded-[4px] py-2 font-['Montserrat'] font-[600] text-[12px] xxs:px-[50px] xs:px-[70px] h-14"
                onClick={showDeletePop}
              >
                Cancel Application
              </button>
            </Link>

            <Link className="md:order-2 xxs:order-1">
              <Button
                onClick={handleFormSubmit}
                type="submit"
                variant="full"
                size="lg"
                className="text-[12px] xxs:px-[50px] xs:px-[70px]"
              >
                {loading ? <Loader /> : "Continue Application"}
              </Button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AboutYourSelf;
