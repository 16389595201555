import React, { useState } from "react";
import flag from "assets/svg/flag_fr.svg";

export const option1 = [
  "Administration and office support",
  "Accounting and  finance",
  "Advertising and marketing",
  "Arts and design",
  "Customer service and call center",
  "Education and training",
  "Engineering and architecture",
  "Healthcare and medical",
  "Human resources",
  "Information technology and computer science",
  "Legal and law enforcement",
  "Maintenance and repair",
  "Manufacturing and production",
  "Project management",
  "Sales and business development",
  "Science and research",
  "Social services and non-profit",
  "Transportation and logistics."
];

export const option2 = ["Past 1 hour", "Past 24 hours", "Past Week", "Past Month", "Anytime"];

export const option3 = ["Fresher", "Entry Level", "Executive", "Mid-Level", "Senior Level"];

export const option4 = ["Contract/Freelance", "Full Time", "Internship", "Part Time"];

export const option5 = ["Hybrid", "On-site", "Remote"];

export const option6 = [
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>,
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>,
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>,
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>,
  <div className="location flex">
    <img src={flag} style={{ height: "20px", width: "20px" }} />
    &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afaganistan</h5>
  </div>
];

// export const option7 = [
//   "Agriculture and farming",
//   "Mining and extraction",
//   "Manufacturing",
//   "Construction",
//   "Energy and utilities",
//   "Transportation and logistics",
//   "Information technology and telecommunications",
//   "Healthcare and pharmaceuticals",
//   "Financial services and banking",
//   "Retail and wholesale",
//   "Hospitality and tourism",
//   "Education and research",
//   "Government and public administration",
//   "Entertainment and media",
//   "Real estate and property management."
// ];

export const option1MV = [
  { id: 1, label: "Administration and office support" },
  { id: 2, label: "Accounting and finance" },
  { id: 3, label: "Advertising and marketing" },
  { id: 4, label: "Arts and design" },
  { id: 5, label: "Customer service and call center" },
  { id: 6, label: "Education and training" },
  { id: 7, label: "Engineering and architecture" },
  { id: 8, label: "Healthcare and medical" },
  { id: 9, label: "Human resources" },
  { id: 10, label: "Information technology and computer science" },
  { id: 11, label: "Legal and law enforcement" },
  { id: 12, label: "Maintenance and repair" },
  { id: 13, label: "Manufacturing and production" },
  { id: 14, label: "Project management" },
  { id: 15, label: "Sales and business development" },
  { id: 16, label: "Science and research" },
  { id: 17, label: "Social services and non-profit" },
  { id: 18, label: "Transportation and logistics" }
];

export const option2MV = [
  { id: 0, label: "Past 1 hour" },
  { id: 1, label: "Past 24 hours" },
  { id: 2, label: "Past Week" },
  { id: 3, label: "Past Month" },
  { id: 4, label: "Anytime" }
];

export const option3MV = [
  { id: 0, label: "Fresher" },
  { id: 1, label: "Entry Level" },
  { id: 2, label: "Executive" },
  { id: 3, label: "Mid-Level" },
  { id: 4, label: "Senior Level" }
];

export const option4MV = [
  { id: 0, label: "Contract/Freelance" },
  { id: 1, label: "Full Time" },
  { id: 2, label: "Internship" },
  { id: 3, label: "Part Time" }
];

export const option5MV = [
  { id: 0, label: "Hybrid" },
  { id: 1, label: "On-site" },
  { id: 2, label: "Remote" }
];

export const option6MV = [
  {
    id: 0,
    label: (
      <div className="location flex">
        <img src={flag} style={{ height: "20px", width: "20px" }} />
        &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afghanistan</h5>
      </div>
    )
  },
  {
    id: 1,
    label: (
      <div className="location flex">
        <img src={flag} style={{ height: "20px", width: "20px" }} />
        &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afghanistan</h5>
      </div>
    )
  },
  {
    id: 2,
    label: (
      <div className="location flex">
        <img src={flag} style={{ height: "20px", width: "20px" }} />
        &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afghanistan</h5>
      </div>
    )
  },
  {
    id: 3,
    label: (
      <div className="location flex">
        <img src={flag} style={{ height: "20px", width: "20px" }} />
        &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afghanistan</h5>
      </div>
    )
  },
  {
    id: 4,
    label: (
      <div className="location flex">
        <img src={flag} style={{ height: "20px", width: "20px" }} />
        &nbsp;&nbsp;&nbsp;&nbsp;<h5>Afghanistan</h5>
      </div>
    )
  }
];

// export const option7MV = [
//   { id: 0, label: "Agriculture and farming" },
//   { id: 1, label: "Mining and extraction" },
//   { id: 2, label: "Manufacturing" },
//   { id: 3, label: "Construction" },
//   { id: 4, label: "Energy and utilities" },
//   { id: 5, label: "Transportation and logistics" },
//   { id: 6, label: "Information technology and telecommunications" },
//   { id: 7, label: "Healthcare and pharmaceuticals" },
//   { id: 8, label: "Financial services and banking" },
//   { id: 9, label: "Retail and wholesale" },
//   { id: 10, label: "Hospitality and tourism" },
//   { id: 11, label: "Education and research" },
//   { id: 12, label: "Government and public administration" },
//   { id: 13, label: "Entertainment and media" },
//   { id: 14, label: "Real estate and property management." }
// ];
