import React, { useState } from "react";
import ellipse from "../../../../assets/img/ellipse.png";
import smallCircle from "../../../../assets/img/smallCircle.png";
import smallDots from "../../../../assets/img/smallDots.png";
import Modals from "components/common/Modal/Modals";
import { Button } from "components";
import MobileApplicationStatus from "../ApplicationStatus/MobileApplicationStatus/MobileApplicationStatus";

const MobileApplicationProfile = () => {
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  return (
    <div className="space-y-4 relative md:hidden">
      {openModal && (
        <Modals
          title="Application Status Tracking "
          content={<MobileApplicationStatus />}
          setOpenModal={() => setOpenModal(null)}
        />
      )}
      <div className=" p-2  bg-usafWhite  mt-6 rounded-2xl border border-usafBlack4 space-y-4">
        <div className="flex justify-between items-center w-full">
          <img src={ellipse} className="object-contain w-[50px] " alt="hello" />
          <p className="text-[12px] flex justify-end  text-usafBlack2 font-LatoRegular">
            Applied 2 hours ago
          </p>
        </div>
        <div>
          <p className=" font-SpectralRegular text-[19px]  text-usafBlack font-extrabold">
            Rotherham Institute of Design
          </p>
          <p className="font-LatoRegular text-[18px] text-usafBlack  pb-1">Product Designer</p>
          <div className="flex justify-between items-center">
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Product Designer</p>
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Remote</p>
          </div>
          <div className="flex justify-end">
            <Button onClick={toggleModal}>
              <p className="text-textsm font-MontserratSemiBold text-usafWhite">View Status</p>
            </Button>
          </div>
        </div>
      </div>
      <div className=" p-2  bg-usafWhite  mt-6 rounded-2xl border border-usafBlack4 space-y-4">
        <div className="flex justify-between items-center w-full">
          <img src={ellipse} className="object-contain w-[50px] " alt="hello" />
          <p className="text-[12px] flex justify-end  text-usafBlack2 font-LatoRegular">
            Applied 2 hours ago
          </p>
        </div>
        <div>
          <p className=" font-SpectralRegular text-[19px]  text-usafBlack font-extrabold">
            Rotherham Institute of Design
          </p>
          <p className="font-LatoRegular text-[18px] text-usafBlack  pb-1">Product Designer</p>
          <div className="flex justify-between items-center">
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Product Designer</p>
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Remote</p>
          </div>
          <div className="flex justify-end">
            <Button onClick={toggleModal}>
              <p className="text-textsm font-MontserratSemiBold text-usafWhite">View Status</p>
            </Button>
          </div>
        </div>
      </div>
      <div className=" p-2  bg-usafWhite  mt-6 rounded-2xl border border-usafBlack4 space-y-4">
        <div className="flex justify-between items-center w-full">
          <img src={ellipse} className="object-contain w-[50px] " alt="hello" />
          <p className="text-[12px] flex justify-end  text-usafBlack2 font-LatoRegular">
            Applied 2 hours ago
          </p>
        </div>
        <div>
          <p className=" font-SpectralRegular text-[19px]  text-usafBlack font-extrabold">
            Rotherham Institute of Design
          </p>
          <p className="font-LatoRegular text-[18px] text-usafBlack  pb-1">Product Designer</p>
          <div className="flex justify-between items-center">
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Product Designer</p>
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Remote</p>
          </div>
          <div className="flex justify-end">
            <Button onClick={toggleModal}>
              <p className="text-textsm font-MontserratSemiBold text-usafWhite">View Status</p>
            </Button>
          </div>
        </div>
      </div>
      <div className=" p-2  bg-usafWhite  mt-6 rounded-2xl border border-usafBlack4 space-y-4">
        <div className="flex justify-between items-center w-full">
          <img src={ellipse} className="object-contain w-[50px] " alt="hello" />
          <p className="text-[12px] flex justify-end  text-usafBlack2 font-LatoRegular">
            Applied 2 hours ago
          </p>
        </div>
        <div>
          <p className=" font-SpectralRegular text-[19px]  text-usafBlack font-extrabold">
            Rotherham Institute of Design
          </p>
          <p className="font-LatoRegular text-[18px] text-usafBlack  pb-1">Product Designer</p>
          <div className="flex justify-between items-center">
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Product Designer</p>
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Remote</p>
          </div>
          <div className="flex justify-end">
            <Button onClick={toggleModal}>
              <p className="text-textsm font-MontserratSemiBold text-usafWhite">View Status</p>
            </Button>
          </div>
        </div>
      </div>
      <div className=" p-2  bg-usafWhite  mt-6 rounded-2xl border border-usafBlack4 space-y-4">
        <div className="flex justify-between items-center w-full">
          <img src={ellipse} className="object-contain w-[50px] " alt="hello" />
          <p className="text-[12px] flex justify-end  text-usafBlack2 font-LatoRegular">
            Applied 2 hours ago
          </p>
        </div>
        <div>
          <p className=" font-SpectralRegular text-[19px]  text-usafBlack font-extrabold">
            Rotherham Institute of Design
          </p>
          <p className="font-LatoRegular text-[18px] text-usafBlack  pb-1">Product Designer</p>
          <div className="flex justify-between items-center">
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Product Designer</p>
            <p className="font-LatoRegular text-[14px] text-usafBlack2 pb-1">Remote</p>
          </div>
          <div className="flex justify-end">
            <Button onClick={toggleModal}>
              <p className="text-textsm font-MontserratSemiBold text-usafWhite">View Status</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileApplicationProfile;
