const SvgVMSEmployeeSearch = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M31.5689 18.5734V12.5L17.486 18.925C17.3173 18.9949 17.1437 19.0525 16.9667 19.0973V21.2976C16.9599 21.5748 16.8451 21.8384 16.6466 22.032C16.4482 22.2257 16.1819 22.3341 15.9047 22.3341C15.6274 22.3341 15.3611 22.2257 15.1627 22.032C14.9643 21.8384 14.8494 21.5748 14.8427 21.2976V19.0976C14.6653 19.0527 14.4915 18.995 14.3224 18.925L0.23938 12.5V28.4724C0.240005 29.2131 0.534523 29.9233 1.05827 30.447C1.58203 30.9708 2.29221 31.2653 3.0329 31.2659H18.7978C18.9628 27.9396 20.3636 24.7946 22.7258 22.4469C25.0881 20.0992 28.2416 18.7179 31.5689 18.5734Z"
        fill="#004B9D"
      />
      <path
        d="M16.6037 16.9881L31.5688 10.1607V8.48661C31.5682 7.74591 31.2737 7.03573 30.75 6.51198C30.2262 5.98823 29.516 5.69371 28.7753 5.69309H23.2992C23.2927 5.66161 23.2918 5.63053 23.2831 5.59929C22.8331 3.98172 21.8634 2.55714 20.5236 1.54526C19.1838 0.533386 17.5483 -0.00957368 15.8693 0.000127782C14.1903 0.00982925 12.5612 0.571653 11.2332 1.59895C9.90514 2.62624 8.95198 4.06193 8.52071 5.68459L8.51937 5.69309H3.03266C2.29196 5.69371 1.58178 5.98823 1.05803 6.51198C0.534278 7.03573 0.239761 7.74591 0.239136 8.48661V10.1609L15.2044 16.9881C15.424 17.0884 15.6626 17.1403 15.9041 17.1403C16.1455 17.1403 16.3841 17.0884 16.6037 16.9881ZM15.9136 2.75461C16.8646 2.75734 17.7943 3.03659 18.5894 3.55836C19.3844 4.08013 20.0106 4.82188 20.3915 5.69324H11.4318C11.8122 4.82067 12.4387 4.07795 13.2348 3.55601C14.0308 3.03407 14.9617 2.75557 15.9136 2.75461Z"
        fill="#004B9D"
      />
      <path
        d="M47.0564 43.8478L41.5874 38.2855C43.15 35.9698 43.8023 33.1586 43.4192 30.3914C43.036 27.6242 41.6445 25.0959 39.5116 23.2919C37.3787 21.4878 34.6546 20.5349 31.8622 20.6161C29.0698 20.6974 26.4058 21.807 24.3813 23.732C22.3569 25.657 21.1147 28.2619 20.893 31.0466C20.6714 33.8314 21.486 36.6 23.1805 38.8209C24.875 41.0419 27.3301 42.5589 30.0745 43.0807C32.8189 43.6026 35.6594 43.0926 38.0508 41.6485L43.6092 47.295C44.0672 47.7479 44.6859 48.0011 45.33 47.9993C45.9741 47.9975 46.5913 47.7408 47.0468 47.2854C47.5023 46.8299 47.7589 46.2127 47.7607 45.5686C47.7625 44.9245 47.5094 44.3058 47.0564 43.8478ZM32.1966 37.8159C31.042 37.816 29.9133 37.4736 28.9533 36.8322C27.9932 36.1907 27.2449 35.279 26.8031 34.2122C26.3612 33.1455 26.2456 31.9717 26.4708 30.8393C26.696 29.7068 27.252 28.6666 28.0685 27.8502C28.8849 27.0337 29.9251 26.4777 31.0576 26.2525C32.19 26.0272 33.3638 26.1428 34.4305 26.5847C35.4973 27.0265 36.409 27.7748 37.0505 28.7348C37.692 29.6949 38.0343 30.8236 38.0343 31.9782C38.0343 33.5264 37.4192 35.0113 36.3245 36.1061C35.2297 37.2008 33.7449 37.8159 32.1966 37.8159Z"
        fill="#004B9D"
      />
    </svg>
  );
};
SvgVMSEmployeeSearch.defaultProps = {
  size: 48,
  color: "currentColor"
};
export default SvgVMSEmployeeSearch;
