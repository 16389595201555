import { useLocation } from "react-router-dom";
import { Navbar } from "../../modules";
import AuthComp from "./AuthComp";

const Auth = ({ children, message, google_message, linkedIn_message }) => {
  const { pathname } = useLocation();
  return (
    <div className="relative w-full min-h-screen">
      <Navbar />
      {message && (
        <div className={`${pathname === "/" ? "pt-0" : "pt-0"} pt-14 pb-28 px-9`}>
          <AuthComp
            message={message}
            google_message={google_message}
            linkedIn_message={linkedIn_message}
          />
          <div className="flex items-center bg-white py-14 px-6 rounded-[14px] shadow-md">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Auth;
