const SvgVMSCounterClock = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M23.9884 48C37.1426 48 48 37.1426 48 23.9884C48 10.8574 37.1194 0 23.9652 0C10.8342 0 0 10.8574 0 23.9884C0 37.1426 10.8574 48 23.9884 48ZM34.6602 25.102C34.6602 31.0643 29.8811 35.8898 23.9652 35.8898C18.0493 35.8898 13.2702 31.0643 13.2702 25.1716C13.2702 24.1508 14.1286 23.3388 15.1029 23.3388C16.1237 23.3388 16.9589 24.1508 16.9589 25.1716C16.9589 29.0691 20.0677 32.2011 23.9652 32.2011C27.8627 32.2011 30.9715 29.0691 30.9715 25.1716C30.9715 21.4596 28.1179 18.3973 24.522 18.3045C24.2436 18.2813 23.942 18.2813 23.71 18.3045L25.6124 20.2069C25.8908 20.4853 26.03 20.8797 26.03 21.2972C26.03 22.2948 25.2876 22.9908 24.3364 22.9908C23.8956 22.9908 23.4316 22.7588 23.1532 22.4804L18.6293 17.9333C17.9565 17.2837 17.9797 16.1237 18.6061 15.4741L23.1068 10.927C23.4084 10.579 23.8724 10.347 24.3596 10.347C25.3572 10.347 26.0532 11.1126 26.0532 11.9942C26.0532 12.5046 25.8444 12.899 25.5892 13.1774L24.1972 14.639C24.3596 14.6158 24.6148 14.5926 24.8004 14.6158C30.0667 14.7782 34.6602 19.3717 34.6602 25.102Z"
        fill="#004B9D"
      />
    </svg>
  );
};
SvgVMSCounterClock.defaultProps = {
  size: 48,
  color: "currentColor"
};
export default SvgVMSCounterClock;
