const SvgVMSAdd = ({ size, color, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M3.09751 18.4657H14.7443V30.1125C14.7443 31.2962 15.718 32.2891 16.9209 32.2891C18.1238 32.2891 19.0975 31.2962 19.0975 30.1125V18.4657H30.7443C31.9281 18.4657 32.9209 17.4919 32.9209 16.2891C32.9209 15.0862 31.9281 14.1125 30.7443 14.1125H19.0975V2.46567C19.0975 1.2819 18.1238 0.289062 16.9209 0.289062C15.718 0.289062 14.7443 1.2819 14.7443 2.46567V14.1125H3.09751C1.91374 14.1125 0.920898 15.0862 0.920898 16.2891C0.920898 17.4919 1.91374 18.4657 3.09751 18.4657Z"
        fill="#EB6223"
      />
    </svg>
  );
};
SvgVMSAdd.defaultProps = {
  size: 32,
  color: "currentColor"
};
export default SvgVMSAdd;
