import { Navbar, BreadCrumbs, Sidebar, Footer } from "../../modules";

const Landing = ({ children, breadCrumbsLinks }) => {
  return (
    <div className="flex flex-col h-screen">
      <Navbar auth />
      <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} />

      <div className="flex flex-1 overflow-y-auto lg:overflow-y-auto no-scrollbar">
        <div className="fixed md:max-w-64 md:flex-shrink-0">
          <Sidebar />
        </div>

        <div className="flex-1 px-4 py-6 ml-[50px] md:ml-[275px] overflow-y-auto no-scrollbar">
          {children}
        </div>
      </div>
      <div className="hidden">
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
