import React, { useState } from "react";
import { VMSimages } from "../../../../../assets";
import { Modal } from "../../../../../components";
import AddBasics from "./AddBasics";
import { useAppSelector } from "hooks";

const Basics = ({ setBasics }) => {
  const { user } = useAppSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);

  const data = {
    id: 1,
    title: "Basics",
    email: user.user.email,
    nationality: user.user.country,
    city: "",
    phoneNumber: user.user.phone,
    resume: ""
  };
  return (
    <div className="bg-white rounded-[14px] p-4 mb-5 shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h4>Basics</h4>
        <label className="cursor-pointer" onClick={() => setOpenModal(true)}>
          <img src={VMSimages.edit_pen} className="object-contain max-w-[20px]" alt="" />
        </label>
      </div>
      <div className="w-full bg-[#004B9D] rounded-[10px] p-5 min-h-[130px]">
        {data ? (
          <div className="text-white">
            <section className="flex items-center justify-between mb-2">
              <h6 className="text-white font-[Spectral] font-semibold text-[16px]">Email</h6>
              <span className="font-[Lato] text-[14px]">{data?.email}</span>
            </section>
            <section className="flex items-center justify-between mb-2">
              <h6 className="text-white font-[Spectral] font-semibold text-[16px]">Nationality</h6>
              <span className="font-[Lato] text-[14px]">{data?.nationality}</span>
            </section>
            <section className="flex items-center justify-between mb-2">
              <h6 className="text-white font-[Spectral] font-semibold text-[16px]">City</h6>
              <span className="font-[Lato] text-[14px]">{data?.city}</span>
            </section>
            <section className="flex items-center justify-between mb-2">
              <h6 className="text-white font-[Spectral] font-semibold text-[16px]">Phone Number</h6>
              <span className="font-[Lato] text-[14px]">{data?.phoneNumber}</span>
            </section>
            <section className="flex items-center justify-between mb-2">
              <h6 className="text-white font-[Spectral] font-semibold text-[16px]">Resume</h6>
              <span className="font-[Lato] text-[14px]">{data?.resume}</span>
            </section>
          </div>
        ) : (
          <div className="flex items-center justify-center min-h-[110px]">
            <h6 className="font-['Lato'] text-white text-[16px]">
              You have not added any basic data
            </h6>
          </div>
        )}
      </div>
      {openModal && (
        <Modal
          title="Basics"
          content={<AddBasics title="basics" />}
          setOpenModal={() => setOpenModal(null)}
        />
      )}
    </div>
  );
};

export default Basics;
