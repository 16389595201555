import React from "react";
import { VMSimages } from "assets";

const VolunteerSchedule = () => {
  return (
    <div className="px-2 md:px-5 xl:px-9 py-14 max-w-[100%] grid grid-cols-1 gap-3 lg:grid-cols-2 bg-white mb-2">
      <div className="flex flex-col xxs:-mt-10 md:mt-0 justify-between items-center">
        <img
          src={VMSimages.volunteer_schedule}
          className="object-cover lg:max-h-[450px] md:max-h-[400px] lg:max-w-full md:max-w-full w-full"
          alt="usa_hands"
        />
      </div>
      <div className="flex flex-col items-center xxs:items-start self-center lg:items-end">
        <h1
          className="font-['Spectral_Bold'] max-w-[650px] text-right xxs:text-left lg:text-right 
          text-[24px] lg:text-[35px] xl:text-[40px] text-[#004B9D] font-bold leading-[40px] lg:leading-[65px] xl:leading-[65px]"
        >
          Never Miss a Beat: Manage Your Volunteer Schedule with Ease
        </h1>

        <p
          className="mb-12 mt-5 font-['Lato'] text-[16px] lg:text-[18px] xl:text-[18px] text-[#666666] leading-[30px] lg:leading-[35px]
          text-right xxs:text-left lg:text-right max-w-[1000px]"
        >
          Still juggling daily activities and volunteer schedules all together? Can’t seem to keep
          an organized mind with so many places to be and so little time? Our Volunteer Management
          System helps you sync schedules with your daily activities, so you never miss a schedule
          “beat”, always get reminders on upcoming schedules and keep an organized lifestyle.
        </p>
      </div>
    </div>
  );
};

export default VolunteerSchedule;
