import React from "react";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppDeletePrompt from "../AppProcessComps/AppDeletePrompt";
import { BreadCrumbs, Button, Loader } from "components";

export const ReviewApplication = ({ loading, ...props }) => {
  const Navigate = useNavigate();

  const openModal4 = () => {
    props.setModal3();
    props.setModal4();
  };

  const AboutYourSelfLink = () => {
    props.setModal3();
    props.breadCrumbAYS();
  };

  const WorkExperience = () => {
    props.setModal3();
    props.breadCrumbWExp();
  };

  const Qualification = () => {
    props.setModal3();
    props.breadCrumbQua();
  };

  const breadCrumbsLinks = [
    {
      id: 1,
      link: "Application Page I",
      onClick: AboutYourSelfLink,
      direction: false
    },
    {
      id: 2,
      link: "Application Page II",
      onClick: WorkExperience,
      direction: true
    },
    {
      id: 2,
      link: "Application Page III",
      onClick: Qualification,
      direction: true
    },
    {
      id: 2,
      link: "Review",
      to: {},
      direction: true
    }
  ];

  //mobile view
  const breadCrumbsLinksMV = [
    {
      id: 2,
      link: "Application Page III",
      onClick: Qualification,
      back_direction: true
    }
  ];

  function handleFormSubmit(e) {
    e.preventDefault();
    openModal4();
  }

  // delete prompt
  const [deletePop, setDeletePop] = useState("");
  const showDeletePop = (e) => {
    setDeletePop(openCancelPop);
    e.preventDefault();
  };

  const hideDeletePop = (e) => {
    setDeletePop(!closeCancelPop);
    e.preventDefault();
  };

  let openCancelPop = {
    visibility: "visible",
    position: "fixed",
    margin: "0 auto",
    bottom: "7rem",
    left: "2rem",
    right: "2rem"
  };

  let closeCancelPop = {
    visibility: "hidden",
    position: "fixed"
  };

  const cancelPopStyle = deletePop ? openCancelPop : closeCancelPop;

  return (
    <div className="bg-usafWhite h-[44rem] rounded-b-[15px]">
      <div className="hidden md:block">
        <BreadCrumbs breadCrumbsLinks={breadCrumbsLinks} />
      </div>

      <div className="block md:hidden">
        <BreadCrumbs breadCrumbsLinks={breadCrumbsLinksMV} />
      </div>

      <div
        className="w-full px-8 py-10 bg-white rounded-lg shadow-sm m-auto max-w-[65rem] 
        space-y-6 mt-[3rem] h-[35rem] overflow-y-auto"
      >
        <div className="flex justify-between text-[20px] font-[400] text-[#333333}">
          <h2 className="font-[Spectral] font-[600] text-left text-[22px] pt-3 md:pt-0 md:text-[28px] text-[#004B9D]">
            Application Page I
          </h2>
          <Link>
            <button
              className="border-[1.5px] border-secondary-100 bg-white text-secondary-100  
              rounded-[4px] py-2 font-['Montserrat'] font-[600] text-[14px] px-[35px] md:px-[80px] h-14"
              onClick={AboutYourSelfLink}
            >
              Edit
            </button>
          </Link>
        </div>

        <div className="flex justify-evenly">
          <div className="w-full mt-5 py-5 text-left">
            <div className="mb-12 grid">
              <label className="text-[#000000] font-[600] font-[Spectral] text-[22px] leading-[200%]">
                First Name
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">Jane</text>
            </div>
            <div className="mb-12 grid">
              <label className="text-[#000000] font-[600] font-[Spectral] text-[22px] leading-[200%]">
                Country Code
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">+234</text>
            </div>
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-[Spectral] text-[22px] leading-[200%]">
                City
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">Lagos</text>
            </div>
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-[Spectral] text-[22px] leading-[200%]">
                Email
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">
                Jane@gmail.com
              </text>
            </div>
          </div>

          <div className="w-full mt-5 py-5 text-left">
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-[Spectral] text-[22px] leading-[200%]">
                Last Name
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">Doe</text>
            </div>
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-[Spectral] text-[22px] leading-[200%]">
                Phone Number
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">8131907599</text>
            </div>
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-[Spectral] text-[22px] leading-[200%]">
                Country
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">Nigeria</text>
            </div>
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-[Spectral] text-[22px] leading-[200%]">
                CV
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">
                Jane_Doe_Resume.pdf
              </text>
            </div>
          </div>
        </div>

        <div className="mx-auto">
          <hr className="border-2 border-[#333333]" />
        </div>

        <div className="flex justify-between text-[20px] font-[400] text-[#333333}">
          <h2 className="font-[Spectral] font-[600] text-left text-[22px] pt-3 md:pt-0 md:text-[28px] text-[#004B9D]">
            Application Page II
          </h2>
          <Link>
            <button
              className="border-[1.5px] border-secondary-100 bg-white text-secondary-100  
              rounded-[4px] py-2 font-['Montserrat'] font-[600] text-[14px] px-[35px] md:px-[80px] h-14"
              onClick={WorkExperience}
            >
              Edit
            </button>
          </Link>
        </div>

        <div className="flex justify-evenly">
          <div className="w-full mt-5 py-5 text-left">
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-Spectral text-[22px] leading-[200%]">
                Years of Experience
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">2-3</text>
            </div>

            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-Spectral text-[22px] leading-[200%]">
                Skills
              </label>
              <div className="flex justify-evenly">
                <div className="w-full py-5 text-left">
                  <div className="grid">
                    <text className="text-[18px] font-[Lato] text-[#666666] font-[400] leading-[200%]">
                      Figma{" "}
                    </text>
                    <text className="text-[18px] font-[Lato] text-[#666666] font-[400] leading-[200%]">
                      User Experience Design{" "}
                    </text>
                    <text className="text-[18px] font-[Lato] text-[#666666] font-[400] leading-[200%]">
                      User Interface Design{" "}
                    </text>
                  </div>
                </div>

                <div className="w-full py-5 text-left">
                  <div className="grid">
                    <span className="text-[18px] font-[Lato] font-[400] self-end text-[#666666] leading-[200%]">
                      8/10
                    </span>
                    <span className="text-[18px] font-[Lato] font-[400] self-end text-[#666666] leading-[200%]">
                      5/10
                    </span>
                    <span className="text-[18px] font-[Lato] font-[400] self-end text-[#666666] leading-[200%]">
                      9/10
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-Spectral text-[22px] leading-[200%]">
                Has experience as a Volunteer
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">Yes</text>
            </div>
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-Spectral text-[22px] leading-[200%]">
                Years of experience as a Volunteer
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">0-1</text>
            </div>
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-Spectral text-[22px] leading-[200%]">
                Describe experience as a volunteer
              </label>
              <p className="userExp text-[18px] font-[Lato] text-[#666666] font-[400] text-justify">
                This area contains the applicants description of their experience as a volunteer.
                This area contains the applicants description of their experience as a volunteer.
                This area contains the applicants description of their experience as a volunteer.
                This area contains the applicants description of their experience as a volunteer.
                This area contains the applicants description of their experience as a volunteer.
              </p>
            </div>
          </div>
        </div>

        <div className="mx-auto">
          <hr className="border-2 border-[#333333]" />
        </div>

        <div className="flex justify-between text-[20px] font-[400] text-[#333333}">
          <h2 className="font-[Spectral] font-[600] text-left text-[22px] pt-3 md:pt-0 md:text-[28px] text-[#004B9D]">
            Application Page III
          </h2>
          <Link>
            <button
              className="border-[1.5px] border-secondary-100 bg-white text-secondary-100  
              rounded-[4px] py-2 font-['Montserrat'] font-[600] text-[14px] px-[35px] md:px-[80px] h-14"
              onClick={Qualification}
            >
              Edit
            </button>
          </Link>
        </div>

        <div className="flex justify-evenly">
          <div className="w-full mt-5 py-5 text-left">
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-Spectral text-[22px] leading-[200%]">
                Hours in a week for volunteering
              </label>
              <text className="text-[18px] font-[Lato] text-[#666666] font-[400]">
                20 hours and above
              </text>
            </div>

            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-Spectral text-[22px] leading-[200%]">
                Why do you want to join USAF
              </label>
              <p className="text-[18px] font-[Lato] text-[#666666] font-[400] text-justify">
                This area contains the reasons why the applicant wants to join USAF. This area
                contains the reasons why the applicant wants to join USAF. This area contains the
                reasons why the applicant wants to join USAF. This area contains the reasons why the
                applicant wants to join USAF. This area contains the reasons why the applicant wants
                to join USAF.
              </p>
            </div>
            <div className="grid mb-12">
              <label className="text-[#000000] font-[600] font-Spectral text-[22px] leading-[200%]">
                Additional Information
              </label>
              <p className="text-[18px] font-[Lato] text-[#666666] font-[400] text-justify">
                This area contains the any additional information the applicant might have. This
                area contains the any additional information the applicant might have. This area
                contains the any additional information the applicant might have. This area contains
                the any additional information the applicant might have. This area contains the any
                additional information the applicant might have.
              </p>
            </div>
          </div>
        </div>

        <AppDeletePrompt
          showCancelPop={cancelPopStyle}
          deletePopUp={deletePop}
          hideDeletePop={hideDeletePop}
        />

        <div className="flex flex-col md:flex-row items-center justify-around gap-4 py-6 xxs:pb-12 md:pb-2">
          <Link className="md:order-1 xxs:order-2">
            <button
              className="border-[1.5px] border-secondary-100 bg-white text-secondary-100  
                rounded-[4px] py-2 font-['Montserrat'] font-[600] text-[12px] xxs:px-[50px] xs:px-[70px] h-14"
              onClick={showDeletePop}
            >
              Cancel Application
            </button>
          </Link>

          <Link className="md:order-2 xxs:order-1">
            <Button
              onClick={handleFormSubmit}
              type="submit"
              variant="full"
              size="lg"
              className="text-[12px] xxs:px-[50px] xs:px-[70px]"
            >
              {loading ? <Loader /> : "Sumbit Application"}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
